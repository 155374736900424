import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import axiosClient from 'api';
import { ensureSubscriptionData } from 'utils';

import { ENDPOINT } from 'features/users/constants';

export const useGetSubscription = (tourId = null, listLength = null) => {
  const [isLoading, setIsLoading] = useState(false);
  // store
  const { id } = useSelector(({ tour }) => tour);
  const { id: projectId } = useSelector(({ project }) => project);

  const tourIdToCheck = tourId ? tourId : id;

  // state
  const [usage, setUsage] = useState(null);
  const [scopes, setScopes] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const data = await axiosClient.patch(
          `${ENDPOINT.SUBSCRIPTION_DETAIL}`,
          {
            tourId: tourIdToCheck,
            projectId,
          }
        );
        if (data) {
          const { currentUsage, scopes: accountScopes } = data;
          setUsage(ensureSubscriptionData(currentUsage));
          setScopes(ensureSubscriptionData(accountScopes));
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    loadData();
  }, [tourIdToCheck, listLength, projectId]);

  return { usage, scopes, isLoading };
};
