import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import { icons } from './assets/icons';
import * as serviceWorker from './serviceWorker';
import { initGtag } from './gaTracking';
import CombinedProviders from './CombinedProviders';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

initGtag();

React.icons = icons;

ReactDOM.render(
  <CombinedProviders>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </CombinedProviders>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
