import { MEDIA_TYPES } from 'consts';
import { ensureBodyFormData } from 'utils';
import { useCallback } from 'react';
import api from 'helpers/api';
import urls from 'consts/urls';

export const useUploadFile = () => {
  const uploadFile = useCallback(async ({ fileType, file }) => {
    try {
      const bodyFormData = ensureBodyFormData(file, fileType);
      const uploadUrl =
        fileType === MEDIA_TYPES.Audio || fileType === MEDIA_TYPES.Video
          ? urls.UPLOAD_FILE
          : urls.UPLOAD_IMAGE;
      return await api.post(uploadUrl, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return { onUploadFile: uploadFile };
};
