import { lazy } from 'react';

const Dashboard = lazy(() => import('../views/dashboard/Dashboard'));
const EventDashboard = lazy(() => import('../views/dashboard/EventDashboard'));

//Projects
const Project = lazy(() => import('features/projects/pages/ProjectList'));
const ProjectAdd = lazy(() => import('features/projects/pages/ProjectAdd'));
const ProjectEdit = lazy(() => import('features/projects/pages/ProjectEdit'));

// Tours
const Tours = lazy(() => import('features/tours/pages/TourList'));
const TourEdit = lazy(() => import('features/tours/pages/TourEdit'));
const TourAdd = lazy(() => import('features/tours/pages/TourAdd'));

// Public Tours
const PublicTourList = lazy(() =>
  import('features/publicTour/pages/PublicTourList')
);
const PublicToursAdd = lazy(() =>
  import('features/publicTour/pages/PublicTourAdd')
);
const PublicToursEdit = lazy(() =>
  import('features/publicTour/pages/PublicTourEdit')
);
const PublicTourScenesList = lazy(() =>
  import('features/publicTourScenes/pages/PublicTourScenesList')
);
const PublicTourMediaList = lazy(() => import('views/Media/Media'));
const PublicTourMenu = lazy(() => import('../views/Menu'));

// Real estate contents
const RealEstateContentList = lazy(() =>
  import('features/realEstateProjects/pages/RealEstateContentList')
);
const CreateRealEstateContent = lazy(() =>
  import('features/realEstateProjects/pages/RealEstateContentAdd')
);
const EditRealEstateContent = lazy(() =>
  import('features/realEstateProjects/pages/RealEstateContentEdit')
);

const PropertyList = lazy(() => import('features/property/pages/PropertyList'));
const CreateProperty = lazy(() =>
  import('features/property/pages/PropertyAdd')
);
const EditProperty = lazy(() => import('features/property/pages/PropertyEdit'));

const ListingList = lazy(() => import('features/listing/pages/ListingList'));
const CreateListing = lazy(() => import('features/listing/pages/ListingAdd'));
const EditListing = lazy(() => import('features/listing/pages/ListingEdit'));

const AgentList = lazy(() => import('features/agentContact/pages/AgentList'));
const AgentAdd = lazy(() => import('features/agentContact/pages/AgentAdd'));
const AgentEdit = lazy(() => import('features/agentContact/pages/AgentEdit'));

// Deleted Items
const DeletedItemsList = lazy(() =>
  import('features/deletedItems/pages/DeletedItemsList')
);

const ScenesList = lazy(() => import('features/scenes/pages/SceneList'));
const Menu = lazy(() => import('../views/Menu'));

const Website = lazy(() => import('features/website/pages/WebsiteList'));
const WebsiteAdd = lazy(() => import('features/website/pages/WebsiteAdd'));
const WebsiteEdit = lazy(() => import('features/website/pages/WebsiteEdit'));

const UserList = lazy(() => import('features/users/pages/UserList'));
const UserEdit = lazy(() => import('features/users/pages/UserEdit'));
const UserAdd = lazy(() => import('features/users/pages/UserAdd'));
const UserProfile = lazy(() => import('features/users/pages/UserProfile'));
const ResetPassword = lazy(() => import('features/users/pages/ChangePassword'));

const Customers = lazy(() => import('features/customers/pages/CustomersList'));
const CustomerAdd = lazy(() => import('features/customers/pages/CustomerAdd'));
const CustomerEdit = lazy(() =>
  import('features/customers/pages/CustomerEdit')
);

const SubscriptionsPlans = lazy(() => import('../views/Subscriptions/Plans'));
const SubscriptionsCustomers = lazy(() =>
  import('../views/Subscriptions/Customer')
);

const LandingPageList = lazy(() =>
  import('features/langdingPage/pages/LandingPageList')
);

const BackjobStatistics = lazy(() =>
  import('../views/System/BackjobStatistics')
);
const HomePageReport = lazy(() => import('../views/Report/HomePage'));

const LandingPageReport = lazy(() => import('../views/Report/LandingPage'));

const TourReport = lazy(() => import('../views/Report/TourPage'));
const WebsiteReport = lazy(() => import('../views/Report/WebsitePage'));
const ProjectReport = lazy(() => import('../views/Report/ProjectPage'));
// TODO Refactor later
const MediaList = lazy(() => import('views/Media/Media'));
// const MediaList = lazy(() => import('features/media/pages/MediaList'));

export const appRoutes = [
  { path: '/', exact: true, name: 'home' },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },

  {
    path: '/public-tours',
    exact: true,
    name: 'public_tours',
    component: PublicTourList,
  },
  {
    path: '/public-tours/new',
    exact: true,
    name: 'create_public_tour',
    component: PublicToursAdd,
  },
  {
    path: '/public-tours/:id',
    exact: true,
    name: 'edit_public_tour',
    component: PublicToursEdit,
  },
  {
    path: '/public-tours/:id/scenes',
    exact: true,
    name: 'scenes',
    component: PublicTourScenesList,
  },
  {
    path: '/public-tours/:id/media',
    exact: true,
    name: 'media',
    component: PublicTourMediaList,
  },
  {
    path: '/public-tours/:id/menu',
    exact: true,
    name: 'menu',
    component: PublicTourMenu,
  },
  {
    path: '/projects',
    exact: true,
    name: 'projects',
    component: Project,
  },
  {
    path: '/projects/new',
    exact: true,
    name: 'create_project',
    component: ProjectAdd,
  },
  {
    path: '/projects/:id',
    exact: true,
    name: 'edit_project',
    component: ProjectEdit,
  },
  {
    path: '/tours',
    exact: true,
    name: 'tours',
    component: Tours,
  },
  {
    path: '/tours/new',
    exact: true,
    name: 'create_tour',
    component: TourAdd,
  },
  {
    path: '/tours/:id',
    exact: true,
    name: 'tour_edit',
    component: TourEdit,
  },
  {
    path: '/scenes',
    exact: true,
    name: 'scenes',
    component: ScenesList,
  },
  {
    path: '/content-library',
    exact: true,
    name: 'content_library',
    component: MediaList,
  },
  {
    path: '/real-estate-projects',
    exact: true,
    name: 'real_estate_projects',
    component: RealEstateContentList,
  },
  {
    path: '/real-estate-projects/new',
    exact: true,
    name: 'create_real_estate_project',
    component: CreateRealEstateContent,
  },
  {
    path: '/real-estate-projects/:id',
    exact: true,
    name: 'edit_real_estate_project',
    component: EditRealEstateContent,
  },
  {
    path: '/properties',
    exact: true,
    name: 'property',
    component: PropertyList,
  },
  {
    path: '/properties/new',
    exact: true,
    name: 'create_property',
    component: CreateProperty,
  },
  {
    path: '/properties/:id',
    exact: true,
    name: 'edit_property',
    component: EditProperty,
  },
  {
    path: '/listings',
    exact: true,
    name: 'listings',
    component: ListingList,
  },
  {
    path: '/listings/new',
    exact: true,
    name: 'create_listing',
    component: CreateListing,
  },
  {
    path: '/listings/:id',
    exact: true,
    name: 'edit_listing',
    component: EditListing,
  },
  {
    path: '/agent-contacts',
    exact: true,
    name: 'agent_contacts',
    component: AgentList,
  },
  {
    path: '/agent-contacts/new',
    exact: true,
    name: 'create_agent',
    component: AgentAdd,
  },
  {
    path: '/agent-contacts/:id',
    exact: true,
    name: 'edit_agent',
    component: AgentEdit,
  },
  {
    path: '/menu',
    exact: true,
    name: 'menu',
    component: Menu,
  },
  {
    path: '/website',
    exact: true,
    name: 'website',
    component: Website,
  },
  {
    path: '/website/new',
    exact: true,
    name: 'create_website',
    component: WebsiteAdd,
  },
  {
    path: '/website/:id',
    exact: true,
    name: 'edit_website',
    component: WebsiteEdit,
  },
  {
    path: '/users',
    exact: true,
    name: 'users',
    component: UserList,
  },
  {
    path: '/users/create',
    exact: true,
    name: 'add_new_user',
    component: UserAdd,
  },
  {
    path: '/users/profile',
    exact: true,
    name: 'user_profile',
    component: UserProfile,
  },
  {
    path: '/users/:userId',
    exact: true,
    name: 'edit_user',
    component: UserEdit,
  },
  {
    path: '/users/:id/resetPassword',
    exact: true,
    name: 'reset_password',
    component: ResetPassword,
  },
  {
    path: '/customers',
    exact: true,
    name: 'customers',
    component: Customers,
  },
  {
    path: '/customers/new',
    exact: true,
    name: 'add_customer',
    component: CustomerAdd,
  },
  {
    path: '/customers/:id',
    exact: true,
    name: 'edit_customer',
    component: CustomerEdit,
  },
  {
    path: '/landing-pages',
    exact: true,
    name: 'landing_pages',
    component: LandingPageList,
  },
  {
    path: '/subscription/plans',
    exact: true,
    name: 'subscription_plans',
    component: SubscriptionsPlans,
  },
  {
    path: '/subscription/customers',
    exact: true,
    name: 'customers_subscription',
    component: SubscriptionsCustomers,
  },
  {
    path: '/report/home-page',
    exact: true,
    name: 'homepage_statistics',
    component: HomePageReport,
  },
  {
    path: '/report/landing-page',
    exact: true,
    name: 'landingpage_statistics',
    component: LandingPageReport,
  },
  {
    path: '/report/tours',
    exact: true,
    name: 'tour_scene_report',
    component: TourReport,
  },
  {
    path: '/report/website',
    exact: true,
    name: 'website_report',
    component: WebsiteReport,
  },
  {
    path: '/report/project',
    exact: true,
    name: 'project_report',
    component: ProjectReport,
  },
  {
    path: '/backjob',
    exact: true,
    name: 'backjob_statistics',
    component: BackjobStatistics,
  },
  {
    path: '/deleted-items',
    exact: true,
    name: 'deleted_items',
    component: DeletedItemsList,
  },
];
