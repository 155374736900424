import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import axiosClient from 'api';
import { MENU_ENDPOINT } from 'consts';
import { showToast } from 'utils';

export const useUpdateMenuConfig = () => {
  const { t } = useTranslation(['messages']);

  return useMutation(
    ({ tourId, groupsData }) =>
      axiosClient.post(MENU_ENDPOINT.MENU + '/' + tourId, groupsData),
    {
      onError: () =>
        showToast({
          type: 'error',
          message: t('error.scene.save_to_menu'),
        }),
    }
  );
};
