import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonEn from '../../assets/locales/en/common.json';
import headerSidebarEn from '../../assets/locales/en/header_siderbar.json';
import messagesEn from '../../assets/locales/en/messages.json';
import setupTourEn from '../../assets/locales/en/setup_tour.json';
import contentLibEn from '../../assets/locales/en/content_library.json';
import realEstateEn from '../../assets/locales/en/real_estate_contents.json';
import projectsEn from '../../assets/locales/en/projects.json';
import websitesEn from '../../assets/locales/en/websites.json';
import publicToursEn from '../../assets/locales/en/public_tours.json';
import usersEn from '../../assets/locales/en/users.json';
import customersEn from '../../assets/locales/en/customers.json';
import subscriptionsEn from '../../assets/locales/en/subscriptions.json';
import reportEn from '../../assets/locales/en/report.json';
import systemEn from '../../assets/locales/en/system.json';
import deletedItemsEn from '../../assets/locales/en/deleted_items.json';
import toastEn from '../../assets/locales/en/toast.json';

import commonVi from '../../assets/locales/vi/common.json';
import headerSidebarVi from '../../assets/locales/vi/header_siderbar.json';
import messagesVi from '../../assets/locales/vi/messages.json';
import setupTourVi from '../../assets/locales/vi/setup_tour.json';
import contentLibVi from '../../assets/locales/vi/content_library.json';
import realEstateVi from '../../assets/locales/vi/real_estate_contents.json';
import projectsVi from '../../assets/locales/vi/projects.json';
import websitesVi from '../../assets/locales/vi/websites.json';
import publicToursVi from '../../assets/locales/vi/public_tours.json';
import usersVi from '../../assets/locales/vi/users.json';
import customersVi from '../../assets/locales/vi/customers.json';
import subscriptionsVi from '../../assets/locales/vi/subscriptions.json';
import reportVi from '../../assets/locales/vi/report.json';
import systemVi from '../../assets/locales/vi/system.json';
import deletedItemsVi from '../../assets/locales/vi/deleted_items.json';
import toastVi from '../../assets/locales/vi/toast.json';

import commonJp from '../../assets/locales/jp/common.json';
import headerSidebarJp from '../../assets/locales/jp/header_siderbar.json';
import messagesJp from '../../assets/locales/jp/messages.json';
import setupTourJp from '../../assets/locales/jp/setup_tour.json';
import contentLibJp from '../../assets/locales/jp/content_library.json';
import realEstateJp from '../../assets/locales/jp/real_estate_contents.json';
import projectsJp from '../../assets/locales/jp/projects.json';
import websitesJp from '../../assets/locales/jp/websites.json';
import publicToursJp from '../../assets/locales/jp/public_tours.json';
import usersJp from '../../assets/locales/jp/users.json';
import customersJp from '../../assets/locales/jp/customers.json';
import subscriptionsJp from '../../assets/locales/jp/subscriptions.json';
import reportJp from '../../assets/locales/jp/report.json';
import systemJp from '../../assets/locales/jp/system.json';
import deletedItemsJp from '../../assets/locales/jp/deleted_items.json';
import toastJp from '../../assets/locales/jp/toast.json';

const resources = {
  en: {
    common: commonEn,
    headerSidebar: headerSidebarEn,
    messages: messagesEn,
    setupTour: setupTourEn,
    contentLib: contentLibEn,
    realEstate: realEstateEn,
    projects: projectsEn,
    websites: websitesEn,
    publicTours: publicToursEn,
    users: usersEn,
    customers: customersEn,
    subscriptions: subscriptionsEn,
    report: reportEn,
    system: systemEn,
    deletedItems: deletedItemsEn,
    toast: toastEn,
  },
  vi: {
    common: commonVi,
    headerSidebar: headerSidebarVi,
    messages: messagesVi,
    setupTour: setupTourVi,
    contentLib: contentLibVi,
    realEstate: realEstateVi,
    projects: projectsVi,
    websites: websitesVi,
    publicTours: publicToursVi,
    users: usersVi,
    customers: customersVi,
    subscriptions: subscriptionsVi,
    report: reportVi,
    system: systemVi,
    deletedItems: deletedItemsVi,
    toast: toastVi,
  },
  jp: {
    common: commonJp,
    headerSidebar: headerSidebarJp,
    messages: messagesJp,
    setupTour: setupTourJp,
    contentLib: contentLibJp,
    realEstate: realEstateJp,
    projects: projectsJp,
    websites: websitesJp,
    publicTours: publicToursJp,
    users: usersJp,
    customers: customersJp,
    subscriptions: subscriptionsJp,
    report: reportJp,
    system: systemJp,
    deletedItems: deletedItemsJp,
    toast: toastJp,
  },
};

const options = {
  // Order and from where user language should be detected
  order: ['querystring', 'localStorage', 'navigator', 'path'],

  // Keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',
  lookupQuerystring: 'lang',

  // Cache user language on
  caches: ['localStorage'],
};

i18n.use(initReactI18next).init({
  detection: options,
  resources,
  fallbackLng: 'en', // Default language
  supportedLngs: ['en', 'vi', 'jp'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
