export const QUERY_KEYS = {
  SCENE_LIST: 'SceneList',
  SCENE_JSON: 'SceneJson',
  SCENE: 'Scene',
  SHOW_ON_MAP: 'ShowOnMap',
  SOURCE_IMAGES: 'SourceImages',
};

export const ENDPOINT = {
  SCENE: 'Scenes',
  SOFT_DELETE: 'Scenes/SoftDelete',
  SHOW_ON_MAP: 'Scenes/ShowOnMap',
  IMAGES: 'Images',
  CHANGE_GROUP: 'Scenes/ChangeGroup',
  STATUS: 'SceneTracking',
  BACKJOB_STATUS: 'SceneTracking/Get-Statistic',
  REPORT_HOME_PAGE: 'GoogleAnalytics/HomePage',
  REPORT_LANDING_PAGE: 'GoogleAnalytics/LandingPage',
  REPORT_TOUR_PAGE: 'GoogleAnalytics/TourPage',
  REPORT_SCENE_PAGE: 'GoogleAnalytics/ScenePage',
  REPORT_TOUR: 'Dashboard',
  RETRY: 'Retry',
};

export const SCENE_STEPS = {
  GENERAL: 0,
  LENSFLARE: 1,
  STEPS: 2,
  JUMP_SPOTS: 3,
  MEDIA: 4,
};

export const ICON_COLOR = 'rgba(0,0,0,0.87)';

export const GROUP_DETAIL = {
  id: null,
  tourId: null,
  title: '',
  friendlyName: '',
  location: '',
  isDefault: false,
};

export const DEFAULT_DATA_FILTER = {
  id: '',
  friendlyName: 'all',
  title: 'All',
};

export const DEFAULT_OPTIONS = {
  value: '',
  label: 'all',
};

export const DEFAULT_GROUPS_DATA = {
  title: '',
  location: '',
};

export const DEFAULT_ORIENTATION = [12.844283927303282, 68.3353162507708, -500];

export const DEFAULT_COORDINATES = [0, 0, 0];

export const BATCH_UPLOAD_COORDINATES = [0, 0, 300];

export const DEFAULT_SCENE_WIDTH = 1500;

export const DEFAULT_SCENE_DATA = {
  id: null,
  tourId: null,
  tourGroupId: '',
  districName: '',
  title: '',
  location: '',
  friendlyName: '',
  isDefault: false,
  sceneType: '',
  viewingAngle: '',
  defaultOrientation: [...DEFAULT_ORIENTATION],
  newOrientation: [...DEFAULT_ORIENTATION],
  coordinates: DEFAULT_COORDINATES,
  lensFlareLocation: [],
  showOnMap: false,
  noLensFlare: false,
  sourceImgId: null,
  sourceImgName: '',
  images: [],
  imageType: '',
  previewImgUrl: '',
  modified: false,
  hotspotModified: false,
};

export const SCENE_UPLOAD_INFORMATION = {
  dimension: 'dimension',
  width: 'width',
  height: 'height',
  customerId: 'customerId',
};

export const STATUSES = {
  SUCCESS: 0,
  ERROR: 1,
  PENDING: 2,
  PROCESSING: 3,
  NaN: 4,
};

export const STATUS_KEY_NAME = {
  OVERALL: 'overall',
  CONVERT360: 'conver360',
  RESIZE4K: 'resize4k',
  RESIZE2K: 'resize2k',
};

export const REFETCH_SCENE_TIME = 30 * 1000;

export const TEXT_COLOR = {
  SELECTED: '#f73d3c',
  NORMAL: '#2f363a',
};
