import urls from 'consts/urls';
import api from 'helpers/api';

export const getGroupDetail = (id) => {
  return api.get(urls.TOUR_GROUPS + '/' + id);
};

export const getTourGroupBySearchQuery = (data) => {
  return api.patch(urls.TOUR_GROUPS, data);
};

export const saveTourGroup = (data) => {
  const { id: dataId, ...reqData } = data;
  if (dataId) {
    return api.put(urls.TOUR_GROUPS + '/' + dataId, reqData);
  }
  return api.post(urls.TOUR_GROUPS, data);
};

export const deleteTourGroup = (id) => {
  return api.delete(urls.TOUR_GROUPS + '/' + id);
};

export default {
  getTourGroupBySearchQuery,
  getGroupDetail,
  saveTourGroup,
  deleteTourGroup,
};
