export const initGtag = () => {
  if (!window.gtag) {
    window.gtag = function () {
      // console.log(arguments);
    }
  }
};

export const sendGtagEvent = (eventName, eventData) => {
  window.gtag('event', eventName, {
    ...eventData,
  });
};
