import { CCardHeader } from '@coreui/react';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function HorizontalBarChart({
  labels,
  data,
  title,
  color,
  name,
}) {
  const options = {
    series: [
      {
        data: data,
        name: name,
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels,
    },
    colors: [color],
  };

  return (
    <>
      <>
        <CCardHeader className="w-100">
          <h5 className="text-center">{title}</h5>
        </CCardHeader>
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          height={285}
          className="bar_line_chart"
        />
      </>
    </>
  );
}
