export const SCENE_TYPES = {
  AERIAL: 'Aerial',
  LANDING: 'Landing',
  GROUND: 'Ground',
};

export const NEW_SCENE_TYPE = {
  IMG_360: 'Image360',
  PANORAMA: 'Pano',
  IMG_2D: 'Image2D',
  VIDEO_360: 'Video360',
};

export const NEW_SCENE_TYPE_OPTION = [
  {
    value: NEW_SCENE_TYPE.IMG_360,
    name: 'Image 360',
  },
  {
    value: NEW_SCENE_TYPE.PANORAMA,
    name: 'Panorama',
  },
  {
    value: NEW_SCENE_TYPE.IMG_2D,
    name: 'Photo',
  },
  // {
  //   value: NEW_SCENE_TYPE.VIDEO_360,
  //   name: 'Video 360',
  // },
];
