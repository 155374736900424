import { FILE, FORM_DATA_TYPE, MEDIA_TYPES, URL_TYPE } from 'consts';

export const ensureBodyFormData = (file, fileType, fileUrl = '') => {
  const bodyFormData = new FormData();
  bodyFormData.append(FILE, file);
  if (fileType === MEDIA_TYPES.Audio || fileType === MEDIA_TYPES.Video) {
    bodyFormData.append(FORM_DATA_TYPE.FILE_TYPE, fileType);
    bodyFormData.append(URL_TYPE.FILE_URL, fileUrl);
  } else {
    bodyFormData.append(FORM_DATA_TYPE.IMAGE_TYPE, fileType);
    if (fileUrl) {
      bodyFormData.append(URL_TYPE.IMAGE_URL, fileUrl);
    }
  }
  return bodyFormData;
};
