export const MIME_IMAGE_JPG = ['image/jpeg', 'image/jpg'];

export const MIME_IMAGE_PNG = ['image/png'];

export const MIME_IMAGE = [...MIME_IMAGE_JPG, ...MIME_IMAGE_PNG];

export const MIME_AUDIO_MP3 = ['audio/mpeg', 'audio/mp3'];

export const MIME_MODEL_3D = ['.fbx', '.glb', '.gltf', '.obj'];

export const MIME_PDF = ['application/pdf'];

export const MIME_MEDIA_ALLOW = [...MIME_IMAGE, ...MIME_AUDIO_MP3, ...MIME_PDF];
