import React from 'react';
import { useTranslation } from 'react-i18next';
import { CCardHeader } from '@coreui/react';
import ReactApexChart from 'react-apexcharts';
import { formatDatesToCustomFormat } from 'views/charts/utils';

export default function GaLineChart({ title, currentData }) {
  const { t } = useTranslation(['report']);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#FF1654', '#247BA0'],
    series: [
      {
        name: t('returning_user'),
        data: currentData.map((item) => item.totalUsers),
      },
      {
        name: t('new_user'),
        data: currentData.map((item) => item.newUsers),
      },
    ],
    stroke: {
      width: [4, 4],
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
      },
    },
    xaxis: {
      categories: formatDatesToCustomFormat(
        currentData?.map((item) => item.date)
      ),
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF1654',
        },
        labels: {
          style: {
            colors: '#FF1654',
          },
        },
        title: {
          text: 'Series A',
          style: {
            color: '#FF1654',
          },
        },
      },
      {
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#247BA0',
        },
        labels: {
          style: {
            colors: '#247BA0',
          },
        },
        title: {
          text: 'Series B',
          style: {
            color: '#247BA0',
          },
        },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
    markers: {
      size: 7,
    },
  };

  return (
    <>
      <>
        <CCardHeader className="w-100">
          <h5 className="text-center">{title}</h5>
        </CCardHeader>
        <ReactApexChart
          options={options}
          series={options.series}
          height={479}
          className="bar_line_chart"
        />
      </>
    </>
  );
}
