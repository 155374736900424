import { useQuery } from 'react-query';

import axiosClient from 'api';
import { MENU_ENDPOINT, MENU_QUERY_KEY } from 'consts';

export const useGetMenuConfig = (tourId) => {
  return useQuery([MENU_QUERY_KEY.TOUR, tourId], () =>
    axiosClient.get(MENU_ENDPOINT.MENU + '/' + tourId)
  );
};
