import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// eslint-disable-next-line

// eslint-disable-next-line
import { formatDateRange } from 'views/charts/utils';
import { useGetTour } from 'features/tours/hooks';
import { getTourStatistic } from 'views/System/hook';
import { isAdmin } from 'features/customers/utils';

import { DATE_RANGE } from 'components/DateRangeFilter/constant';

import '../charts/Charts.scss';
import './EventDashboard.scss';

import EventTourReport from 'views/Report/TourPage/EventTourReport';
import EventTourReportV2 from 'views/Report/TourPage/EventTourReportV2';

const EventDashboardV2 = () => {
  const { t } = useTranslation(['report']);

  const [statisticData, setStatisticData] = useState({});
  const [tourID, setTourID] = useState('');
  // eslint-disable-next-line
  const [dateRangeData, setDateRangeData] = useState({
    range: DATE_RANGE.LAST_7_DAYS.BACKEND_ENUM,
    dateRange: [],
  });

  const userRole = useSelector((state) => state.user.role);
  const tourList = useSelector((state) => {
    if (isAdmin(userRole)) return;
    return state.tours;
  });
  const { data: tour } = useGetTour(tourID);
  const tours = useMemo(() => {
    return tourList?.map((item) => {
      return { value: item.id, label: item.name };
    });
  }, [tourList]);

  // useEffect(() => {
  //   if (!isAdmin(userRole)) window.location.href = '/tours';
  // }, [userRole]);

  useEffect(() => {
    const [from, to] = formatDateRange(dateRangeData.dateRange);
    if (tourID && !isAdmin(userRole))
      return getTourStatistic(tourID, undefined, undefined, undefined)
        .then((res) => setStatisticData(res))
        .catch((err) => console.log(err));
    else if (isAdmin(userRole))
      return getTourStatistic(undefined, dateRangeData.range, from, to)
        .then((res) => setStatisticData(res))
        .catch((err) => console.log(err));
  }, [userRole, dateRangeData, tourID]);
  // eslint-disable-next-line
  // const AdminChart = useMemo(
  //   () => <AdminDashBoard statisticData={statisticData} />,
  //   [statisticData]
  // );

  // const CompanyAdminChart = useMemo(
  //   () => (
  //     <CompanyAdminDashBoard
  //       statisticData={statisticData}
  //       tour={tour}
  //       tourID={tourID}
  //     />
  //   ),
  //   [statisticData, tour, tourID]
  // );
  //hard code to show tour report 11/25/2024 (Giang Dao requirements)
  return (
    <div className="event-dashboard">
      <EventTourReportV2 />
    </div>
  );
};

export default EventDashboardV2;
