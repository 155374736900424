import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DateRangePicker, Stack } from 'rsuite';

import { DATE_RANGE } from 'components/DateRangeFilter/constant';

import 'rsuite/dist/rsuite.css';
import './custom-styles.scss';

const DateRangeFilter = ({ value, onChange }) => {
  const { t } = useTranslation(['common']);

  const predefinedRanges = Object.keys(DATE_RANGE)
    .filter((key) => key !== 'CUSTOM_RANGE')
    .map((key) => ({
      label: t(DATE_RANGE[key].LABEL),
      value: DATE_RANGE[key].RANGE,
      placement: 'left',
      name: DATE_RANGE[key].NAME,
    }));

  const filterDateRange = useCallback(
    (data, e) => {
      const value = e.target.attributes?.name?.value;
      const dateRangeMap = {
        [DATE_RANGE.TODAY.NAME]: DATE_RANGE.TODAY.BACKEND_ENUM,
        [DATE_RANGE.YESTERDAY.NAME]: DATE_RANGE.YESTERDAY.BACKEND_ENUM,
        [DATE_RANGE.LAST_7_DAYS.NAME]: DATE_RANGE.LAST_7_DAYS.BACKEND_ENUM,
        [DATE_RANGE.LAST_30_DAYS.NAME]: DATE_RANGE.LAST_30_DAYS.BACKEND_ENUM,
        [DATE_RANGE.THIS_WEEK.NAME]: DATE_RANGE.THIS_WEEK.BACKEND_ENUM,
        [DATE_RANGE.LAST_WEEK.NAME]: DATE_RANGE.LAST_WEEK.BACKEND_ENUM,
        [DATE_RANGE.THIS_MONTH.NAME]: DATE_RANGE.THIS_MONTH.BACKEND_ENUM,
        [DATE_RANGE.LAST_MONTH.NAME]: DATE_RANGE.LAST_MONTH.BACKEND_ENUM,
      };

      const selectedValue =
        dateRangeMap[value] ?? DATE_RANGE.CUSTOM_RANGE.BACKEND_ENUM;
      onChange(selectedValue, value === undefined ? data : '');
    },
    [onChange]
  );

  const renderDateRangeValue = (value) => {
    for (const range of predefinedRanges) {
      if (range.value[0] === value[0] && range.value[1] === value[1]) {
        return t(range.label);
      }
    }
    return (
      format(value[0], 'MM/dd/yyyy') + '-' + format(value[1], 'MM/dd/yyyy')
    );
  };

  return (
    <>
      <span>{t('date_range')}</span>
      <Stack
        direction="column"
        spacing={8}
        alignItems="flex-end"
        style={{ transform: 'translateX(15px)' }}
      >
        <DateRangePicker
          ranges={predefinedRanges}
          style={{ width: 300 }}
          onChange={(data, e) => filterDateRange(data, e)}
          cleanable={false}
          editable={false}
          renderValue={renderDateRangeValue}
          value={value}
        />
      </Stack>
    </>
  );
};

export default DateRangeFilter;
