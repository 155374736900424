import { useState } from 'react';

const PopupTextTruncation = ({ text }) => {
  const [hovering, setHovering] = useState(false);

  const isOverflowing = (e) => {
    const scrollWidth = e.target.scrollWidth;
    const clientWidth = e.target.clientWidth;
    const isOverflowing = scrollWidth > clientWidth;
    setHovering(isOverflowing);
  };

  const handleMouseEnter = (e) => {
    isOverflowing(e);
  };

  const handleMouseLeave = (e) => {
    setHovering(false);
  };

  return (
    <p
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      className="m-0"
      data-coreui-toggle="tooltip"
      data-coreui-placement="right"
      title={hovering ? text : null}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </p>
  );
};

export default PopupTextTruncation;
