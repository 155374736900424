import { CCol } from '@coreui/react';
import './TotalCard.scss';
import { formatTimeDuration } from '../../utils';

export const TotalReportCharts = ({ label, total, color, percent }) => {
  return (
    <>
      <CCol
        sm="12"
        className="pb-0 pl-0 pr-0 d-flex justify-content-between align-items-start total-card"
      >
        <div
          className={`card ${color} text-white`}
          style={{ borderRadius: '5px' }}
        >
          <div className="card-body">
            <h5 className="card-text">{label}</h5>
            <div className="d-flex align-items-end">
              <h1 className="card-title mb-0">
                {total === 'NaN' || total === 'Infinity' ? 0 : `${formatTimeDuration(total)}` || 0}
              </h1>{' '}
              <h4 className="unit">
                {label.includes('Time')
                  ? 's'
                  : label.includes('Rate')
                  ? '%'
                  : ''}
              </h4>
              &nbsp;
              <p className="card-text">
                {`(${
                  percent === 'NaN' || percent === 'Infinity'
                    ? 0
                    : `${percent}` || 0
                }% )` || `( +12% )`}
                {percent >= 0 ? <>&#8593;</> : <>&#8595;</>}
              </p>
            </div>
          </div>
        </div>
      </CCol>
    </>
  );
};
