import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'consts';
import {
  adminRoutes,
  appRoutes,
  companyAdminRoutes,
  companyUserRoutes,
} from 'routes';

const PERMISSION_ROUTES = {
  [USER_ROLES.ADMIN]: adminRoutes,
  [USER_ROLES.COMPANY_ADMIN]: companyAdminRoutes,
  [USER_ROLES.COMPANY_USER]: companyUserRoutes,
  DEFAULT: [],
};

export const useGetRoutes = () => {
  const { t } = useTranslation(['headerSidebar']);
  const { role: userRole } = useSelector((state) => state.user);

  const translatedRoutes = useMemo(() => {
    const routes = [...appRoutes, ...PERMISSION_ROUTES[userRole || 'DEFAULT']];
    return routes.map((route) => ({
      ...route,
      name: t(route.name),
    }));
  }, [userRole, t]);

  return translatedRoutes;
};
