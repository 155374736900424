import { toast } from 'react-toastify';
import { history } from 'enviroments';
import i18n from 'utils/i18next';

export default function errorHandler(error) {
  const status = error?.response?.status;

  switch (status) {
    case 401:
      // handle goToLogin
      history.push('/login');
      break;
    case 500:
      toast.error(i18n('error.common.server_error', { ns: 'toast' }));
      return;
    case 400:
      toast.error(error?.response?.data?.data);
      return;
    default:
      break;
  }

  return Promise.reject(error);
}
