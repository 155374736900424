export const sceneReportData = [
  {
    "configuration": "",
    "id": "65377451a9103619f07e6ebd",
    "updatedAt": "2023-10-25T02:49:17.005Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "21e479d1-c390-4c07-9cfd-008bf845ecec",
    "title": "Showroom 2",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Showroom-2",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -173.84939058884657,
      -32.66473439015798,
      -500.00000000000006
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537744ba9103619f07e6eb8",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/3f8794af-d443-4a65-bc89-f230043dc526",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881cda9103619f07e79bc",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 5,
    "createdAt": "2023-10-24T07:37:53.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "6537745da9103619f07e6ec7",
    "updatedAt": "2023-10-25T02:49:21.724Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "478b13a3-fb33-4b93-9cc9-cf6fd0a2b97e",
    "title": "Showroom 1",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Showroom-1",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -20.225905699311365,
      -49.074054870774674,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65377452a9103619f07e6ec1",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/d4ccd752-0ae2-495a-b8e3-a4fd2ee5dd95",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881cda9103619f07e79bc",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 4,
    "createdAt": "2023-10-24T07:38:05.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "6537772da9103619f07e6f43",
    "updatedAt": "2023-10-25T02:50:35.448Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "7fd1893d-0e6a-4dae-b2a2-eb8346665853",
    "title": "Main Level",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Main-Level",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      106.77682933483953,
      -248.48921508734745,
      499.99999999999994
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537772943f06316ec63ecb0",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/bb793e93-b166-463d-885c-1b5e6424c505",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881d5a9103619f07e79c4",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 2,
    "createdAt": "2023-10-24T07:50:05.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "6537773143f06316ec63ecb6",
    "updatedAt": "2023-10-25T02:53:58.087Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "5c37cd31-96dd-4497-a1cf-7aa82c04a0a6",
    "title": "Side Way",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Side-Way",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      20.656161328004057,
      -18.430188619928362,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537772ea9103619f07e6f47",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1d9f0205-9cf7-43ab-bf2c-ef7ab0cc6430",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881d5a9103619f07e79c4",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T07:50:09.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65377ce0a9103619f07e70b2",
    "updatedAt": "2023-10-25T02:53:30.774Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "832cc8e7-63d9-49f7-a0c7-dc93253444fd",
    "title": "Tầng 1 - KV2",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "T-ng-1-KV2",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -499.99999999999994,
      -35.5045379664881,
      -33.46502118954437
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65377ccca9103619f07e7098",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/4bf140ff-e1f1-41e8-9a37-6cb4c7c7c2b9",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881dda9103619f07e79c9",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 4,
    "createdAt": "2023-10-24T08:14:24.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65377cf8a9103619f07e70bc",
    "updatedAt": "2023-10-25T02:53:26.583Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "4883e666-50db-4295-83a6-e182ac308f60",
    "title": "Tầng 1 - Sảnh",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "T-ng-1-S-nh",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -2.0151893483286107,
      -7.410487226360794,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65377ce3a9103619f07e70b6",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/a901f171-cd67-4303-b08a-f49975f6d1bf",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881dda9103619f07e79c9",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 4,
    "createdAt": "2023-10-24T08:14:48.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65377ed3a9103619f07e715e",
    "updatedAt": "2023-10-25T02:50:28.613Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "294ab54f-ee63-403d-ab93-327653bdbb1b",
    "title": "Art Science Museum - Facade",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Art-Science-Museum-Facade",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      54.90251014842054,
      99.1200602542067,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65377ed1a9103619f07e7159",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/24bc66bc-c7c7-4852-a48b-3f81d87cb1e8",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881e6a9103619f07e79ce",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 2,
    "createdAt": "2023-10-24T08:22:43.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65377ed6a9103619f07e7168",
    "updatedAt": "2023-10-25T02:51:03.291Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "17a91f9e-1120-46e8-afec-6d1776713103",
    "title": "Helix Bridge Blue Hour",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Helix-Bridge-Blue-Hour",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      40.89048440456738,
      32.203679740918524,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65377ed4a9103619f07e7162",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/9d4c36c2-7fc0-443c-a35a-c05a1323bb15",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881e6a9103619f07e79ce",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 2,
    "createdAt": "2023-10-24T08:22:46.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "6537803aa9103619f07e71d8",
    "updatedAt": "2023-10-25T02:52:48.093Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "272b0f82-c5cd-422d-a96d-35da8563a6fc",
    "title": "Waiting Stage",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Waiting-Stage",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      120.68199748306063,
      -107.01614302646007,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378035a9103619f07e71d3",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/beb0de14-c019-4e95-bde1-d43707f0b0c0",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881eea9103619f07e79d3",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T08:28:42.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378040a9103619f07e71e1",
    "updatedAt": "2023-10-25T02:52:38.962Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "1036d2c1-4e62-4f17-8a04-73cce3b0b5bc",
    "title": "Reception",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Reception",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      500,
      -2.148953696488758,
      -236.19649857458202
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537803ca9103619f07e71dc",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/276b36aa-5a9d-4ddd-afa6-a4bc7baa0f31",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881eea9103619f07e79d3",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T08:28:48.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "653781b4a9103619f07e7251",
    "updatedAt": "2023-10-25T02:51:49.745Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "d6c06c61-dfe2-4866-bf68-b8fa7b256f39",
    "title": "Self Study Area",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Self-Study-Area",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      499.99999999999994,
      -97.10455396276359,
      265.1914204560824
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "653781aca9103619f07e724c",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/03602bdc-c5ad-44bb-9ab1-f76397152155",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881f7a9103619f07e79d8",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 9,
    "createdAt": "2023-10-24T08:35:00.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "653781b9a9103619f07e725b",
    "updatedAt": "2023-10-25T02:51:38.536Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "ec9dc010-5916-4b58-8344-b501919760f5",
    "title": "Front of College",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Front-of-College",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -3.327987686011757,
      46.75199083620041,
      500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "653781b6a9103619f07e7255",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/664ca7c0-d6b0-4767-93b4-e74761a18672",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881f7a9103619f07e79d8",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T08:35:05.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378481a9103619f07e72d9",
    "updatedAt": "2023-10-25T02:49:42.282Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "832b3d55-1031-4398-af25-c6a28236ddc8",
    "title": "Window",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Window",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      22.578441223443317,
      -32.44740247543078,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378479a9103619f07e72d4",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/c2b6cd58-5ee6-478d-8722-f770e4ebce6e",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881fea9103619f07e79dd",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 5,
    "createdAt": "2023-10-24T08:46:57.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378488a9103619f07e72e3",
    "updatedAt": "2023-10-25T02:53:11.387Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "04a37fa6-63ca-4b7d-aad4-9b7ae90ac058",
    "title": "Modern Pub Entrance",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Modern-Pub-Entrance",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      500.00000000000006,
      -49.28198279723667,
      -300.5928983125064
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378482a9103619f07e72dd",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6501d51d-a830-4742-a6ce-eb0928789bef",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "653881fea9103619f07e79dd",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 5,
    "createdAt": "2023-10-24T08:47:04.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "6537873da9103619f07e7374",
    "updatedAt": "2023-10-25T02:49:53.166Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "4e7fa18a-b60b-4c0d-acda-60046611cf54",
    "title": "Museum Stage 1",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Museum-Stage-1",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -24.887926879353227,
      47.84226988465517,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537873ba9103619f07e736f",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/903a45d6-7e2b-4585-9fdf-18473921be6b",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "65388204a9103619f07e79e2",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 2,
    "createdAt": "2023-10-24T08:58:37.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378742a9103619f07e737e",
    "updatedAt": "2023-10-25T02:52:09.009Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "36f56ff8-25f8-438c-9435-3b7926ab8ccf",
    "title": "Museum Stage 2",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Museum-Stage-2",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      315.3635369159704,
      -28.391159103779216,
      499.99999999999994
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537873fa9103619f07e7378",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/a99c70fe-d10f-42c6-973e-93629ca26cc9",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "65388204a9103619f07e79e2",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 2,
    "createdAt": "2023-10-24T08:58:42.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "653788c6a9103619f07e741a",
    "updatedAt": "2023-10-25T02:51:22.035Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "20d472a7-d6cb-4e18-9958-2afedb2a5288",
    "title": "Council Room",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Council-Room",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      40.923420151577155,
      0.8879609079364694,
      499.99999999999994
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "653788c4a9103619f07e7415",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/875ce436-5e6d-4ee6-af57-4b5db354b34c",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538820da9103619f07e79e8",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:05:10.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "653788cfa9103619f07e7425",
    "updatedAt": "2023-10-25T02:51:17.340Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "395718b9-0d4c-4771-9aa9-e10f568bb0c9",
    "title": "Council Reception",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Council-Reception",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      500,
      7.030063409729985,
      76.04335307203445
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "653788c8a9103619f07e741f",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f4eb9107-710f-41f4-9e53-b6c401226d65",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538820da9103619f07e79e8",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:05:19.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378a7ca9103619f07e7510",
    "updatedAt": "2023-10-25T02:52:23.727Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "8a184004-91df-49d8-bdcd-d0540ef62bed",
    "title": "Gym Stage 2",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Gym-Stage-2",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      11.706255326195977,
      -94.28416237297887,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378a79a9103619f07e750b",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/85aebef4-871e-4f66-80d2-11e34cf6a12a",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "65388215a9103619f07e79ee",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 1,
    "createdAt": "2023-10-24T09:12:28.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378a7fa9103619f07e751b",
    "updatedAt": "2023-10-25T02:50:16.044Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "8401ff43-0bd1-4fff-9232-eca6de3873d0",
    "title": "Gym Stage 1",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Gym-Stage-1",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      89.92597582787049,
      -7.566951563535721,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378a7da9103619f07e7515",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/7f43a9d5-b9e8-4095-b403-e163b7898c98",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "65388215a9103619f07e79ee",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:12:31.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378baea9103619f07e759e",
    "updatedAt": "2023-10-25T02:53:00.301Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "5fc7f5a0-49a2-434f-a662-f602cdac23fa",
    "title": "Hospital Hall 1",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Hospital-Hall-1",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      24.822468946909765,
      -60.41618586311153,
      -499.99999999999994
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378baba9103619f07e7599",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/03a0ee5d-038b-4690-b37c-d22c23160968",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538821da9103619f07e79f3",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:17:34.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378bb2a9103619f07e75a9",
    "updatedAt": "2023-10-25T02:52:56.221Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "c70c844b-6e1b-4d2e-8d1c-904b7d00a677",
    "title": "Hospital Reception",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Hospital-Reception",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      431.7156668117153,
      5.055717067179177,
      -500
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378bafa9103619f07e75a3",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/ff0e32fa-a315-4bf0-b2f3-2a4ae3195dc9",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538821da9103619f07e79f3",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:17:38.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378eefa9103619f07e7656",
    "updatedAt": "2023-10-25T02:45:42.754Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "21fd4cd6-7157-4905-bdc2-0db06eb2dca9",
    "title": "NovaWorld Side",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "NovaWorld-Side",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -499.99999999999994,
      -41.48705125487799,
      -306.758814783518
    ],
    "coordinates": [
      107.53686388888889,
      10.53693611111111,
      7.23
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378eeba9103619f07e7651",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/83bb5025-0a03-4203-9f4b-17f7f4a6ef41",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538814ba9103619f07e799f",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:31:27.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65378ef3a9103619f07e7660",
    "updatedAt": "2023-10-25T02:45:46.792Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "066d1b4c-66e6-4e4e-a842-c9418d958cc1",
    "title": "NovaWorld Facade",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "NovaWorld-Facade",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      483.023795164595,
      48.073828633782824,
      499.99999999999994
    ],
    "coordinates": [
      107.536925,
      10.536933333333334,
      7.47
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65378ef0a9103619f07e765a",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/cbcbe043-6026-4223-a574-67cbd093b49d",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6538814ba9103619f07e799f",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:31:31.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65379043222c0b17dcf21635",
    "updatedAt": "2023-10-24T09:44:23.648Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "ff7e9278-e717-4f07-b158-bfd427a5d9a9",
    "title": "Factory Storage",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Factory-Storage",
    "isDefault": false,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      -500.00000000000006,
      1.0911836620846609,
      78.63615265711636
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "6537903d222c0b17dcf21630",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/606258c7-010e-4031-8fdb-f5862dfd824d",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6537734aa9103619f07e6e7e",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:37:07.000Z",
    "type": "Image360"
  },
  {
    "configuration": "",
    "id": "65379057a9103619f07e76b9",
    "updatedAt": "2023-10-24T11:10:06.054Z",
    "isDeleted": false,
    "deletedAt": null,
    "userIdDeleted": null,
    "uid": "5489a1cd-0870-459a-b800-e9da0d316d9b",
    "title": "Factory Facade",
    "tourId": "6537734aa9103619f07e6e7d",
    "districtName": null,
    "location": "",
    "friendlyName": "Factory-Facade",
    "isDefault": true,
    "sceneType": "Ground",
    "viewingAngle": 0,
    "defaultOrientation": [
      500,
      3.770495776721357,
      -123.65285810055865
    ],
    "coordinates": [
      0,
      0,
      0
    ],
    "lensFlareLocation": [],
    "noLensFlare": true,
    "sourceImgId": "65379045a9103619f07e76b3",
    "previewImgId": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6ed90d82-bb5f-4d4e-a7a9-d2e10db73239",
    "elevation": null,
    "isDone": true,
    "originalId": null,
    "showOnMap": false,
    "tourGroupId": "6537734aa9103619f07e6e7e",
    "mediaLogoOverride": null,
    "variantTitle": null,
    "variantOrder": 0,
    "statusTrack": 0,
    "yaw": 0,
    "roll": 0,
    "pitch": 0,
    "statusCheckCount": 3,
    "createdAt": "2023-10-24T09:37:27.000Z",
    "type": "Image360"
  }
]
