import React from 'react';
import { CCol, CRow, CSelect } from '@coreui/react';

const Filter = ({
  data,
  setSelected,
  title,
  firstOption,
  selectedValue,
  disabled,
}) => {
  const handleSelected = (e) => {
    const { value } = e.target;
    setSelected(value);
  };

  return (
    <CRow>
      <CCol xs="12" md="3" className="d-flex align-items-center">
        <p className="text-capitalize text-right m-0 input-line-height">
          {title}
        </p>
      </CCol>
      <CCol xs="12" md="9">
        <CSelect
          disabled={disabled}
          name={title}
          onChange={handleSelected}
          value={selectedValue}
        >
          <option value="">{firstOption}</option>
          {data?.map((item, idx) => (
            <option value={item.value} key={idx}>
              {item.label}
            </option>
          ))}
        </CSelect>
      </CCol>
    </CRow>
  );
};

export default Filter;
