export const PLAN_SCOPES = {
  NOT_ALLOWED: 'NOT_ALLOWED',
  UNLIMITED: 'UNLIMITED',
  NORMAL: 'NORMAL',
};

export const PLAN_SCOPE_KEYS = {
  SCOPE_TYPE: 'SCOPE_TYPE',
  PROJECT_COUNT: 'PROJECT_COUNT',
  TOUR_COUNT: 'TOUR_COUNT',
  SCENE_COUNT: 'SCENE_COUNT',
  MEDIA_COUNT: 'MEDIA_COUNT',
  USER_COUNT: 'USER_COUNT',
  SCENE_PER_TOUR_COUNT: 'SCENE_PER_TOUR_COUNT',
  MEDIA_PER_TOUR_COUNT: 'MEDIA_PER_TOUR_COUNT',
};

export const SCOPE_NAME = {
  PROJECT: 'project',
  TOUR: 'tour',
  SCENE: 'scene',
  MEDIA: 'media',
  USER: 'user',
};

export const ALLOW_ACTIONS = {
  CAN_DO: true,
  CANNOT_DO: false,
};

export const DEFAULT_SCOPES_DATA = {
  scopeType: -1,
  project: {
    value: -1,
  },
  tour: {
    value: -1,
    limitPerProject: -1,
  },
  scene: {
    value: -1,
    limitPerTour: -1,
  },
  media: {
    value: -1,
    limitPerTour: -1,
  },
  user: {
    value: -1,
  },
};

export const SITE_SCOPE = {
  NOT_ALLOW: 0,
  UNLIMITED: -1,
};
