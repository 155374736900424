import configs from 'configs';
import urls from 'consts/urls';
import api from 'helpers/api';

export const getGoogleAnalyticsToken = (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return api.get(urls.GOOGLE_ANALYTICS, { headers });
};

export const getGoogleAnalyticsRunReport = (
  googleToken,
  object,
  dimensions,
  metrics,
  arrayObject,
  dateRange
) => {
  const apiUrl = `https://analyticsdata.googleapis.com/v1beta/properties/${configs.ga4ReportingId}:runReport`;
  const requestBody = {
    dimensions: dimensions,
    metrics: metrics,
    dateRanges: { startDate: dateRange.startDate, endDate: dateRange.endDate },
    dimensionFilter: {
      andGroup: {
        expressions: [
          {
            filter: {
              fieldName: `customEvent:${object}_id`,
              inListFilter: {
                values: arrayObject,
              },
            },
          },
        ],
      },
    },
    metricAggregations: ['TOTAL'],
  };

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${googleToken}`);
  headers.append('Content-Type', 'application/json');

  return fetch(apiUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(requestBody, null, 2),
  });
};
