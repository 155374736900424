export default function configHandler(config) {
  if (config.pure) return config;

  const token = localStorage.getItem('token');

  if (token)
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };

  return config;
}
