import { getGoogleAnalyticsToken } from 'services/googleAnalytics';

export const formatTimeDuration = (seconds) => {
  seconds = Math.floor(seconds);

  const hours = Math.floor(seconds / 3600); 
  const remainingSeconds = seconds % 3600; 
  const minutes = Math.floor(remainingSeconds / 60); 
  const secs = remainingSeconds % 60; 

  let result = '';
  if (hours > 0) result += `${hours}h `;
  if (minutes > 0) result += `${minutes}m `;
  if (secs > 0 || result === '') result += `${secs}`;

  return result.trim(); 
};

export const fetchGoogleToken = async (shouldRefreshToken = false) => {
  const userToken = localStorage.getItem('token');

  if (!userToken) {
    return null;
  }

  let googleToken = localStorage.getItem('googleToken');
  const currentDate = new Date();

  // Thêm 30 phút vào thời gian hiện tại
  currentDate.setMinutes(currentDate.getMinutes() + 30);

  if (shouldRefreshToken || !googleToken) {
    const response = await getGoogleAnalyticsToken(userToken);

    if (!response?.data?.data) {
      return null;
    }

    googleToken = response.data.data;
    localStorage.setItem('googleToken', googleToken);
    localStorage.setItem('gTokenExprity', currentDate.toISOString());
  }

  return googleToken;
};

function cleanData(inputData, filteredDataArray, listIndex) {
  const cleanedData = {};
  const hasDateObject = inputData.dimensionHeaders.find(function (item) {
    return item.name === 'date';
  });
  filteredDataArray.forEach((row) => {
    const dimensionName = row.dimensionValues[0].value;
    const dimensionValue = row.dimensionValues[listIndex].value;
    const metricData = {};

    inputData.metricHeaders.forEach((metricHeader, index) => {
      metricData[metricHeader.name] = row.metricValues[index].value;
      metricData.location =
        row.dimensionValues[row.dimensionValues.length - 2].value;
      if (hasDateObject)
        metricData.date =
          row.dimensionValues[row.dimensionValues.length - 1].value;
    });

    if (!cleanedData[dimensionValue]) {
      cleanedData[dimensionValue] = {};
    }
    cleanedData[dimensionValue][dimensionName] = metricData;
  });

  return cleanedData;
}

function formatReportData(cleanedData, tourSelected, key1, key2) {
  return Object.entries(cleanedData).map(([id, values]) => {
    const newData = {};
    if (id === tourSelected) {
      newData[key1] = id;
    } else {
      newData[key2] = id;
    }
    return {
      ...newData,
      ...values,
    };
  });
}

function cleanDataAllTour(inputData, filteredDataArray, key) {
  const cleanedData = cleanData(inputData, filteredDataArray, 1);

  return Object.entries(cleanedData).map(([tourId, values]) => {
    const newObj = {};
    newObj[key] = tourId;
    return {
      ...newObj,
      ...values,
    };
  });
}

function cleanDataTour(inputData, filteredDataArray, tourSelected, key1, key2) {
  const cleanedData = cleanData(inputData, filteredDataArray, 1);
  const test = formatReportData(cleanedData, tourSelected, key1, key2);
  const tourViewData = test.find((item) => item.hasOwnProperty('tour_view'));
  const tourIdData = test.find((item) => item.hasOwnProperty(key1));

  if (tourViewData && tourIdData) {
    tourIdData['tour_view'] = tourViewData['tour_view'];
    delete tourViewData['tour_view'];
  }

  const childList = test.filter((item) => key2 in item);
  const getDataWhenHaveNotSet = test.find((item) => key1 in item);
  const getDataWhenFullDimension =
    !getDataWhenHaveNotSet && cleanData(inputData, filteredDataArray, 2);
  const formatGetDataFullDimension = formatReportData(
    getDataWhenFullDimension,
    tourSelected,
    key1,
    key2
  )[0];
  const data = getDataWhenHaveNotSet
    ? getDataWhenHaveNotSet
    : formatGetDataFullDimension;

  const formatData = key2?.includes('project')
    ? { ...data, projects: [...childList] }
    : { ...data, scenes: [...childList] };

  return [formatData];
}

export const cleanseData = (inputData, tourSelected, key1, key2) => {
  if (!inputData.rows || !Array.isArray(inputData.rows)) {
    return []; // Return an empty array if no data
  }
  const filteredDataArray = inputData.rows.map((item) => ({
    dimensionValues: item.dimensionValues.filter((dimension) => {
      return dimension.value !== '(not set)';
    }),
    metricValues: item.metricValues,
  }));

  return tourSelected
    ? cleanDataTour(inputData, filteredDataArray, tourSelected, key1, key2)
    : cleanDataAllTour(inputData, filteredDataArray, key1, key2);
};

function removeNotSetKey(obj) {
  for (let key in obj) {
    if (key === '(not set)') {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNotSetKey(obj[key]);
    }
  }
}

export const cleanWebsiteData = (inputData, tourSelected, key1, key2) => {
  const filteredDataArray = inputData.rows.map((item) => ({
    dimensionValues: item.dimensionValues.filter((dimension) => {
      return dimension.value !== '(not set)';
    }),
    metricValues: item.metricValues,
  }));
  const cleanedData = cleanData(inputData, filteredDataArray, 1);
  const test = formatReportData(cleanedData, tourSelected, key1, key2);
  const childList = test.filter((item) => key2 in item);
  const getDataWhenFullDimension = cleanData(inputData, filteredDataArray, 2);
  const getContentData = cleanData(inputData, inputData.rows, 3);
  removeNotSetKey([getContentData]);

  const websiteList = Object.entries(getDataWhenFullDimension).map(
    ([tourId, values]) => {
      const newObj = {};
      newObj[key1] = tourId;
      return {
        ...newObj,
        ...values,
      };
    }
  );
  const contentList = Object.entries(getContentData).map(([tourId, values]) => {
    const newObj = {};
    newObj['contentId'] = tourId;
    return {
      ...newObj,
      ...values,
    };
  });
  const formatData = {
    websites: [...websiteList],
    projects: [...childList],
    contents: [...contentList],
  };
  return [formatData];
};

export const formatPageLocationData = (inputData, tourSelected, key1, key2) => {
  const filteredDataArray = inputData.rows.map((item) => ({
    dimensionValues: item.dimensionValues.filter((dimension) => {
      return dimension.value !== '(not set)';
    }),
    metricValues: item.metricValues,
  }));
  const getDataWhenFullDimension = cleanData(inputData, filteredDataArray, 2);
  const getContentData = cleanData(inputData, inputData.rows, 3);
  removeNotSetKey([getContentData]);

  const websiteList = Object.entries(getDataWhenFullDimension).map(
    ([tourId, values]) => {
      const newObj = {};
      newObj[key1] = tourId;
      return {
        ...newObj,
        ...values,
      };
    }
  );
  const filterData = websiteList.filter(
    (t) => !t.location.includes('https://staging.sites.vizion.space')
  );
  const formatData = {
    websites: [...filterData],
  };
  return [formatData];
};

export const cleanTotalData = (inputData) => {
  if (!inputData.totals || !Array.isArray(inputData.totals)) {
    return []; // Return an empty array if no data
  }

  const cleanedData = {};

  inputData.totals.forEach((total) => {
    const metricData = {};

    inputData.metricHeaders.forEach((metricHeader, index) => {
      return (metricData[metricHeader.name] = total.metricValues[index].value);
    });

    cleanedData.total = metricData;
  });

  return Object.values(cleanedData);
};

export function findCommonElementsWithOrder(arr1, arr2, key) {
  const commonElements = [];
  for (const item1 of arr1) {
    const foundItem = arr2?.find((item2) => {
      const sceneId = item2.value ? item2.value : item2.id;
      return sceneId === item1[key];
    });
    if (foundItem) {
      commonElements.push(foundItem);
    }
  }
  return commonElements;
}

export function isCurrentTimeGreaterThan30Minutes(gTokenExprity) {
  // Tạo đối tượng Date cho thời gian hiện tại
  const currentTime = new Date();

  // Chuyển đổi thời điểm được cung cấp thành đối tượng Date
  const targetTime = new Date(gTokenExprity);

  // Tính khoảng thời gian (đơn vị: phút) giữa thời gian hiện tại và thời gian đã cho
  const timeDifferenceInMinutes = (currentTime - targetTime) / (1000 * 60);

  // So sánh với 20 phút
  if (timeDifferenceInMinutes > 20) {
    return true;
  } else {
    return false;
  }
}

export const getDaysBetween = (start, end) => {
  const betweenDates = [];
  const endDate = new Date(end);
  const currentDate = new Date(start);

  while (currentDate <= endDate) {
    betweenDates.push(currentDate.toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return betweenDates;
};

export const formatCleanData = (data, key) => {
  return data.map((item) =>
    !item.user_engagement
      ? {
          ...item,
          user_engagement: {
            eventCount: 0,
            date: item?.[key]?.date,
            totalUsers: 0,
          },
        }
      : { ...item, user_engagement: item.user_engagement }
  );
};

export function formatDecimal(number) {
  if (typeof number !== 'number' || Number.isNaN(number)) {
    return 'NaN';
  }

  if (Number.isInteger(number) || number % 1 === 0) {
    return number.toString();
  } else {
    return number.toFixed(1);
  }
}
