export const ENDPOINT = {
  CUSTOMERS: 'Customers',
  CUSTOMER_DETAIL: 'Customers/:id',
  USER_CUSTOMERS: 'Users/Customers',
  CUSTOMERS_USERS: 'Customers/Users',
};

export const QUERY_KEY = {
  GET_ALL_CUSTOMERS: 'getAllCustomers',
  GET_CUSTOMER_DETAIL: 'getCustomerDetail',
};

export const CUSTOMER_STATUS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const CUSTOMER_STATUS_CODE = {
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const CUSTOMER_STATUS_LABEL = [
  { key: 0, status: 'Pending' },
  { key: 1, status: 'Active' },
  { key: 2, status: 'Inactive' },
];

export const DEFAULT_CUSTOMER_DATA = {
  friendlyName: '',
  companyName: '',
  companyTagline: '',
  companyDescription: '',
  email: '',
  phoneNumber: '',
  websiteUrl: '',
  logoUrl: '',
  brandColor1: '',
  brandColor2: '',
  facebookUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  snapchatUrl: '',
  youtubeUrl: '',
  pinterestUrl: '',
  status: CUSTOMER_STATUS_CODE.PENDING,
};

export const DEFAULT_COUNTRY = 'vn';
