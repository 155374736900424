import { put, select } from 'redux-saga/effects';

import ROLES from 'consts/role';
import { getCustomers, getCustomersByUser } from 'services/customers';

import * as ACTION_TYPES from '../actionTypes';
import configs from 'configs';

const selectUser = ({ user }) => user;
const selectcustomerId = ({ customer }) => customer.id;

function* checkCustomerExist(customers) {
  const customerId = yield select(selectcustomerId);
  const hasCustomer = customers.find((customer) => customer.id === customerId);
  if (!hasCustomer) {
    // customer save in local storage is not available in this user's customers
    const { id, companyName } = customers[0] || { id: '', companyName: '' };
    yield put({
      type: ACTION_TYPES.SET_CUSTOMER,
      customer: { id, companyName },
    });
  }
}

export function* loadCustomersByRole() {
  const { id, role } = yield select(selectUser);
  // Get list Customers by role
  let res = null;
  const isAdmin = role === ROLES.ADMIN;
  if (isAdmin) {
    res = yield getCustomers();
  } else {
    res = yield getCustomersByUser(id);
  }

  const customers = res.data.data;

  if (!isAdmin && customers.length) {
    window.gtag('config', configs.ga4TrackingId, {
      customer_id: customers[0].id,
    });
  }

  // Put Customers data to store
  if (customers && customers.length !== 0) {
    yield put({
      type: ACTION_TYPES.SET_USER_CUSTOMERS,
      customers,
    });
  }

  yield checkCustomerExist(customers);
}
