import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';

export const DATE_RANGE = {
  TODAY: {
    BACKEND_ENUM: 4,
    RANGE: [new Date(), new Date()],
    LABEL: 'today',
    NAME: 'today',
  },
  YESTERDAY: {
    BACKEND_ENUM: 5,
    RANGE: [addDays(new Date(), -1), addDays(new Date(), -1)],
    LABEL: 'yesterday',
    NAME: 'yesterday',
  },
  LAST_7_DAYS: {
    BACKEND_ENUM: 0,
    RANGE: [subDays(new Date(), 6), new Date()],
    LABEL: 'last_7_days',
    NAME: 'last7days',
  },
  LAST_WEEK: {
    BACKEND_ENUM: 1,
    RANGE: [
      addDays(startOfWeek(new Date(), { weekStartsOn: 0 }), -7),
      addDays(endOfWeek(new Date(), { weekStartsOn: 0 }), -7),
    ],
    LABEL: 'last_week',
    NAME: 'lastWeek',
  },
  LAST_30_DAYS: {
    BACKEND_ENUM: 2,
    RANGE: [subDays(new Date(), 29), new Date()],
    LABEL: 'last_30_days',
    NAME: 'last30days',
  },
  LAST_MONTH: {
    BACKEND_ENUM: 3,
    RANGE: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    LABEL: 'last_month',
    NAME: 'lastMonth',
  },
  THIS_WEEK: {
    BACKEND_ENUM: 6,
    RANGE: [startOfWeek(new Date()), endOfWeek(new Date())],
    LABEL: 'this_week',
    NAME: 'thisWeek',
  },
  THIS_MONTH: {
    BACKEND_ENUM: 7,
    RANGE: [startOfMonth(new Date()), new Date()],
    LABEL: 'this_month',
    NAME: 'thisMonth',
  },
  CUSTOM_RANGE: {
    BACKEND_ENUM: 8,
  },
};

export const REPORT_TITLE = {
  HOME_PAGE_OVERALL: 'overall_statistics',
  HOME_PAGE_DAY_BY_DAY: 'day_by_day_statistics',
  TOP_ACCESS_SCENE: 'top_10_most_access_scenes',
  DASHBOARD_TOUR: 'tour',
  DASHBOARD_ACCOUNTS: 'accounts',
  DASHBOARD_PARTNER: 'partners',
  DASHBOARD_LANDING_PAGES: 'landing_pages',
  DASHBOARD_SCENES: 'scenes',
};

export const REPORT_LABEL = ['views', 'unique_views', 'cta_clicks'];

export const STATUS_COLOR = {
  ACTIVE: '#a2ff93',
  INAVTIVE: '#f87192',
  PENDING: '#fafd6d',
  SCENES: '#FF8080',
  LANDING_PAGES: '#A975FF',
};

export const DASHBOARD_STATUS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
