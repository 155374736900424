import urls from 'consts/urls';
import api from 'helpers/api';

export const getToursBySearchQuery = (data) => {
  return api.patch(urls.TOURS, data);
};

export const getTourDetail = (tourId) => {
  return api.get(urls.TOURS + '/' + tourId);
};

export const saveTour = (data) => {
  const { id: dataId, ...reqData } = data;
  if (dataId) {
    return api.put(urls.TOURS + '/' + dataId, reqData);
  }
  return api.post(urls.TOURS, data);
};

export const getTourJson = ({ tours }) => {
  return api.patch(`${urls.TOURS}/json`, { tours, isPublished: true });
};

export const getGenerateTourJson = (id) => {
  const data = {
    tours: [id],
    isPublished: false,
  };

  return api.patch(`${urls.TOURS}/json`, data);
};

export const generateTourJson = (id) => {
  return api.post(`${urls.TOURS}/${id}/json`);
};

export const publishTourJson = (jsonId) => {
  return api.post(`${urls.TOURS}/${jsonId}/publish`);
};

export const softDelete = (items = []) => {
  return api.post(`${urls.TOURS}/SoftDelete`, { items });
};

export const copyTour = (id, data) => {
  return api.post(urls.TOURS + '/' + id + '/copy', data);
};

export const getToursJson = (tours = [], isPublished = true) => {
  return api.patch(urls.TOURS + '/json', { tours, isPublished });
};

export const updateToursStatus = ({ tourIds, active }) => {
  return api.post(`${urls.TOURS}/BatchUpdateStatus`, { tourIds, active });
};

export const getAllTargetTours = (data) => {
  return api.patch(`${urls.TOURS}/TargetTour`, data);
};

export const checkLinkTour = (tourId) => {
  return api.get(`${urls.HOTSPOTS}/CheckLinkTour/${tourId}`);
};

export default {
  getToursBySearchQuery,
  getTourDetail,
  saveTour,
  getTourJson,
  generateTourJson,
  publishTourJson,
  softDelete,
  copyTour,
  getToursJson,
  getGenerateTourJson,
  updateToursStatus,
  getAllTargetTours,
  checkLinkTour,
};
