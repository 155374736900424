export const tourReportData = [
  {
    "accessCode": "vzdemo",
    "active": true,
    "createdAt": "2022-04-05T03:31:31.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "624bb8135495071290332974",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIZION Demo Tour.",
    "uid": "ea822070-946b-47af-b508-c8bbe2d8f2a8",
    "updatedAt": "2022-10-19T11:31:05.591Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "vizdemo",
    "active": true,
    "createdAt": "2022-04-14T04:09:05.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "62579e616c21ef12584996be",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIZION DEMO TOUR 2.",
    "uid": "3bd9fd03-db04-405c-b72a-f22ebbd923f0",
    "updatedAt": "2022-10-21T06:55:02.240Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "vzdemo2",
    "active": false,
    "createdAt": "2022-04-19T07:12:59.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "625e60fb0268db17d404b80d",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIZION Demo Tour - Back Up",
    "uid": "ac08a1f5-45a0-4092-9a23-3a9bedd80649",
    "updatedAt": "2023-01-11T06:38:03.716Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "vizdemo2",
    "active": false,
    "createdAt": "2022-04-28T04:12:05.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "626a14156c80cc0f68823779",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIZION Demo Tour 2 - Back Up",
    "uid": "9b729e48-da11-4422-a0ff-f4d3f4e09f07",
    "updatedAt": "2022-10-20T07:40:06.871Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "miami-condo",
    "active": true,
    "createdAt": "2022-07-15T15:45:34.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "62d18b9e07f76b15149c3086",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami Condo",
    "uid": "ff786efc-697e-4d15-b383-11df3b7ee37f",
    "updatedAt": "2023-08-30T08:29:41.493Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/fe29accb-7396-4e64-8504-f3542db211d0"
  },
  {
    "accessCode": "nhamaucongtya",
    "active": true,
    "createdAt": "2022-08-01T08:32:08.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "62e78f8883ae0517bc83dc60",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tham quan căn nhà mẫu",
    "uid": "e34617d3-2f05-41ef-be4f-dde3e0d05418",
    "updatedAt": "2023-05-16T06:42:23.860Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "duc-city-house",
    "active": true,
    "createdAt": "2022-10-20T07:41:58.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6350fbc6e6f59417849a8cca",
    "isDeleted": false,
    "isPublic": false,
    "name": "Duc City House",
    "uid": "1a957144-befb-4e17-8835-6b2b5eb4e9f3",
    "updatedAt": "2023-04-17T10:07:15.511Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/4c8621ae-b07c-4fae-9a58-2857a0492dfd"
  },
  {
    "accessCode": "homepage-hero-video-de772400",
    "active": true,
    "createdAt": "2022-11-24T06:36:05.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "637f10d5f6a57809c8eca8d8",
    "isDeleted": false,
    "isPublic": false,
    "name": "Homepage Hero Video",
    "uid": "b7a656f0-0e65-479f-8406-8595be5908c1",
    "updatedAt": null,
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/a2b3f224-a238-47d7-9b54-2abc035d30f8"
  },
  {
    "accessCode": "vietnam-web-summit-2022-0ffb6a8d",
    "active": true,
    "createdAt": "2022-12-02T01:34:33.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "638956296fff951da0a43bf8",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIETNAM WEB SUMMIT 2022",
    "uid": "dfc86f36-f256-4e21-87ee-914b6a0c7c18",
    "updatedAt": "2022-12-09T10:51:53.317Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/832f3b28-8e50-4c63-b34f-13fc97dd5dab"
  },
  {
    "accessCode": "demo-miami",
    "active": true,
    "createdAt": "2022-12-06T10:35:45.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": "2023-06-16T07:00:02.953Z",
    "id": "638f1b015a16b51e58738fe2",
    "isDeleted": false,
    "isPublic": false,
    "name": "Demo Miami",
    "uid": "f9c28d5f-fa77-4f35-9aa4-cf353a5d8ffa",
    "updatedAt": "2023-09-11T13:50:19.518Z",
    "userIdDeleted": "61c040afba4fc005141369e5",
    "previewImgUrl": null
  },
  {
    "accessCode": "dat-nguyen-house-96a29d45",
    "active": true,
    "createdAt": "2023-02-15T08:11:56.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "63ec93ccd431ff111c2491eb",
    "isDeleted": false,
    "isPublic": false,
    "name": "Dat Nguyen House.",
    "uid": "db4c26ec-0f52-43b0-ae9e-a55dff7926d8",
    "updatedAt": "2023-03-14T03:47:39.263Z",
    "userIdDeleted": "",
    "previewImgUrl": null
  },
  {
    "accessCode": "truoc-cua-va-phong-khach-e1830b09",
    "active": true,
    "createdAt": "2023-02-15T09:08:22.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "63eca106573bbd1464da9a4d",
    "isDeleted": false,
    "isPublic": false,
    "name": "Trước Cửa và Phòng Khách.",
    "uid": "13a71879-4a32-4f1a-8f1b-72c50c5592a9",
    "updatedAt": "2023-03-14T02:40:14.076Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/af66b421-d5d7-4df2-9a0c-aa19ff57186e"
  },
  {
    "accessCode": "phong-thuong-e4786be5",
    "active": true,
    "createdAt": "2023-02-16T06:37:11.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "63edcf17f2554d188c4f6c37",
    "isDeleted": false,
    "isPublic": false,
    "name": "phong thượng.",
    "uid": "d255c9f2-75ea-446d-8979-37f1a64098ea",
    "updatedAt": "2023-04-05T06:39:56.385Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/d9a70369-abb2-4632-83e5-b4ae363bdc0d"
  },
  {
    "accessCode": "speech-01-eb3a71c2",
    "active": true,
    "createdAt": "2023-02-27T03:45:13.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "63fc2749a8b1c10ee82cca08",
    "isDeleted": false,
    "isPublic": false,
    "name": "Rang Rang Coffee - Test Speech",
    "uid": "bbd337f4-2046-410f-8577-0cbe313ba009",
    "updatedAt": "2023-03-14T08:01:25.663Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/021c23c4-24df-4887-a5a5-6a3ef285d7b1"
  },
  {
    "accessCode": "demo-ocean-villa-aquacity-1088ddde",
    "active": true,
    "createdAt": "2023-02-28T10:11:17.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "63fdd345e5e9be1d68c9521b",
    "isDeleted": false,
    "isPublic": false,
    "name": "Demo Ocean Villa- Aquacity",
    "uid": "213c3d10-7a33-49e7-aae9-52e56b1bdc3b",
    "updatedAt": "2023-03-02T02:52:12.982Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/69270995-3ea7-47b5-bf73-03c679236c37"
  },
  {
    "accessCode": "high-land-coffee-1-7222276e",
    "active": true,
    "createdAt": "2023-04-04T03:19:07.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "642b972b42799f0d44b81ee7",
    "isDeleted": false,
    "isPublic": false,
    "name": "High Land Coffee - 1",
    "uid": "c56e6417-2884-4f94-81c5-a0f98741c6af",
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": null
  },
  {
    "accessCode": "ecohome-nhon-binh",
    "active": true,
    "createdAt": "2023-04-05T06:37:22.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "642d172263cd3e0c58b95968",
    "isDeleted": false,
    "isPublic": false,
    "name": "Ecohome Nhơn Bình",
    "uid": "bb24181b-4d50-4b5b-91c0-8fa749c2ca90",
    "updatedAt": "2023-04-13T10:22:18.895Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e2479fb4-6482-4e2b-9571-200795dd8312"
  },
  {
    "accessCode": "miami-tour",
    "active": true,
    "createdAt": "2023-04-05T08:02:43.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "642d2b23f5067413d0bcab5f",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami Tour",
    "uid": "072e59f5-3f1e-4be2-ab45-5950a5415cdc",
    "updatedAt": "2023-10-25T05:46:43.979Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/305831a7-c350-4b47-83f1-b91654fe4abb"
  },
  {
    "accessCode": "milky-binhthuy-hotel",
    "active": true,
    "createdAt": "2023-04-17T10:49:47.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "643d244b6cb59313d8072644",
    "isDeleted": false,
    "isPublic": false,
    "name": "Mliky Way - Binh Thuy Hotel",
    "uid": "19b54244-2a68-484b-b1f9-236c3841a1c1",
    "updatedAt": "2023-12-27T02:48:31.540Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e11434e0-2bf0-4549-9c68-995d33c5731b"
  },
  {
    "accessCode": "yesmoke-factory",
    "active": true,
    "createdAt": "2023-04-18T08:36:03.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "643e5673e7a0d10d844e1de8",
    "isDeleted": false,
    "isPublic": false,
    "name": "Yesmoke Factory",
    "uid": "7fe1f081-87de-443e-803a-840890ec16ad",
    "updatedAt": "2023-12-01T08:45:18.521Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/d4fef505-d4a8-4c88-8adf-a42b7522a245"
  },
  {
    "accessCode": "lumina-campus",
    "active": true,
    "createdAt": "2023-04-19T11:25:51.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "643fcfbff076da09c4838591",
    "isDeleted": false,
    "isPublic": false,
    "name": "Lumina State Campus",
    "uid": "21e9c7ff-74ff-4753-956b-2e3e2008665a",
    "updatedAt": "2023-12-27T03:31:15.197Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1b621c2f-d760-48ca-a3d3-8e4e70c14091"
  },
  {
    "accessCode": "manufacturing-factory",
    "active": true,
    "createdAt": "2023-04-24T09:15:17.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "644648a512a788191c706877",
    "isDeleted": false,
    "isPublic": false,
    "name": "Manufacturing Factory",
    "uid": "fa06d58d-3834-4e09-be11-5c711e0dce3c",
    "updatedAt": "2023-05-09T09:10:21.552Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/ae60505d-f652-4107-b86e-9ad86a08bbae"
  },
  {
    "accessCode": "ground-navigation-demo",
    "active": true,
    "createdAt": "2023-05-04T03:42:28.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645329a46014d109f0ec518f",
    "isDeleted": false,
    "isPublic": false,
    "name": "Demo Ground Navigation",
    "uid": "2f26abe7-b97f-459f-ae59-4e1dcb5a2ddc",
    "updatedAt": "2023-06-15T03:51:07.906Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/9dc7f842-8eb7-4fac-99db-eaa94f82ed51"
  },
  {
    "accessCode": "milky-way-hotel-reception",
    "active": true,
    "createdAt": "2023-05-04T04:07:08.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64532f6c6014d109f0ec51a5",
    "isDeleted": false,
    "isPublic": false,
    "name": "Milky Way Hotel - Reception",
    "uid": "e6afd088-8890-4c16-8827-797b9f69c457",
    "updatedAt": "2023-12-27T02:47:17.491Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/06ffe1a1-0c51-490a-9c69-90a1a589d0fc"
  },
  {
    "accessCode": "milky-way-hotel-luxury-interior-room",
    "active": true,
    "createdAt": "2023-05-04T04:42:57.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645337d16014d109f0ec51b3",
    "isDeleted": false,
    "isPublic": false,
    "name": "Milky Way Hotel - Deluxe Room",
    "uid": "6bc375a9-6d2b-4d84-9657-930580c050c5",
    "updatedAt": "2023-12-27T02:46:39.804Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/70b062d4-95dc-427d-a814-5248d3478f7f"
  },
  {
    "accessCode": "milky-way-hotel-utilities",
    "active": true,
    "createdAt": "2023-05-04T04:48:35.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645339236014d109f0ec51ba",
    "isDeleted": false,
    "isPublic": false,
    "name": "Milky Way Hotel - Utilities",
    "uid": "b273d475-8b4d-4ae2-84d3-75d0f30cff5f",
    "updatedAt": "2023-12-27T02:44:52.426Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/349aa423-871a-4ae6-a248-c5697109ffec"
  },
  {
    "accessCode": "manufacturing-factory-vn",
    "active": true,
    "createdAt": "2023-05-09T09:37:35.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645a145fc678a00b5c1f09f3",
    "isDeleted": false,
    "isPublic": false,
    "name": "Nhà Máy Xử Lý Rác Thải",
    "uid": "64dbba5a-ab58-48ca-9bef-d4191ed45efc",
    "updatedAt": "2023-05-09T09:38:18.549Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/8c7caaa2-a69c-4a70-b5cc-809a747ddd86"
  },
  {
    "accessCode": "truong-dai-hoc-lumina",
    "active": true,
    "createdAt": "2023-05-09T09:40:03.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645a14f3bb32a40a0833119c",
    "isDeleted": false,
    "isPublic": false,
    "name": "Trường Đại học Lumina",
    "uid": "f442bd51-3f47-456d-88ac-5ed0262bee22",
    "updatedAt": "2023-05-11T04:22:08.666Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1b621c2f-d760-48ca-a3d3-8e4e70c14091"
  },
  {
    "accessCode": "khach-san-milkyway-binh-thuy",
    "active": true,
    "createdAt": "2023-05-10T10:28:57.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "645b71e9802e750fa884757a",
    "isDeleted": false,
    "isPublic": false,
    "name": "Khách sạn Milky Way - Bình Thủy",
    "uid": "9a8e89f0-0800-4571-a0d8-1c9cfc5221b7",
    "updatedAt": "2023-05-10T10:33:25.663Z",
    "userIdDeleted": "",
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e11434e0-2bf0-4549-9c68-995d33c5731b"
  },
  {
    "accessCode": "modern-pub",
    "active": true,
    "createdAt": "2023-05-30T07:25:20.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6475a4e000bf8f02bc3e55f0",
    "isDeleted": false,
    "isPublic": false,
    "name": "Modern Pub",
    "uid": "6aaef793-d670-428e-bd86-b1fd12aa371d",
    "updatedAt": "2023-12-27T03:44:31.365Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/cd7f961f-c2fd-436b-b302-8fefe37fe5a1"
  },
  {
    "accessCode": "real-estate-client-american-47d6f9f1",
    "active": true,
    "createdAt": "2023-05-30T08:43:03.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6475b717edc2e619946fe2de",
    "isDeleted": false,
    "isPublic": false,
    "name": "Real Estate (Client American)",
    "uid": "01f9eeaa-f5d3-4567-86d6-0d0043bc34ba",
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/72173e63-9e6c-45fd-bbf0-3a7f318c6e77"
  },
  {
    "accessCode": "vinhomes-smart-city",
    "active": true,
    "createdAt": "2023-06-01T07:52:15.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64784e2fbac4dd150047e1a3",
    "isDeleted": false,
    "isPublic": false,
    "name": "VINHOMES SMART CITY",
    "uid": "4e19a7e0-0abc-4f25-ba62-62ed6f7b5dd3",
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/4de209f8-a600-4be7-873f-678d540fe4cd"
  },
  {
    "accessCode": "miami-beach-and-condo",
    "active": true,
    "createdAt": "2023-06-16T06:50:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "648c063031b29f13b84814d9",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami Beach and Condo",
    "uid": "d1f9e643-7478-4409-8403-e56b09b24123",
    "updatedAt": "2023-06-16T07:05:50.814Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/a7b4144c-6da4-429d-975a-75cc04a0c737"
  },
  {
    "accessCode": "vizion-dx-partnership-ceremony",
    "active": true,
    "createdAt": "2023-06-26T06:34:10.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "649931629f210e0924405f31",
    "isDeleted": false,
    "isPublic": false,
    "name": "VIZION-DX: PARTNERSHIP CEREMONY",
    "uid": "dc6196b8-9b5d-427f-b24f-3f470c18b927",
    "updatedAt": "2023-06-26T06:56:42.331Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/3dad60e2-a604-4529-8e04-dda07cafaa80"
  },
  {
    "accessCode": "car-tour",
    "active": true,
    "createdAt": "2023-07-06T05:41:39.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64a654130bb4bd0b1c9774c6",
    "isDeleted": false,
    "isPublic": false,
    "name": "Car Tour",
    "uid": "b7af4ff1-7cf1-4af5-93eb-0559d8cda48f",
    "updatedAt": "2023-07-06T10:12:17.230Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/bc620ef8-d17c-43c8-b7d0-dfba227e2af4"
  },
  {
    "accessCode": "mercedes-benz-v-class",
    "active": true,
    "createdAt": "2023-07-14T02:54:18.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b0b8dabd1a1d1878a267a3",
    "isDeleted": false,
    "isPublic": false,
    "name": "Mercedes Benz V-Class",
    "uid": "89786c5b-71f0-49a2-a6c3-18cb381757bd",
    "updatedAt": "2023-07-14T03:24:44.631Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/c1e4f168-4235-4c1b-af6a-87e1676537aa"
  },
  {
    "accessCode": "mitsubishi-outlander-tour",
    "active": true,
    "createdAt": "2023-07-14T03:00:39.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b0ba577f158115c03db3ac",
    "isDeleted": false,
    "isPublic": false,
    "name": "Mitsubishi Outlander",
    "uid": "f996d76f-26a6-4333-ab28-8f696e22d7ae",
    "updatedAt": "2023-07-14T03:15:16.289Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/61e23296-e772-4a9c-87a8-b34a16e98bef"
  },
  {
    "accessCode": "kia-sorento",
    "active": true,
    "createdAt": "2023-07-14T03:11:44.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b0bcf07f158115c03db3c9",
    "isDeleted": false,
    "isPublic": false,
    "name": "Kia Sorento",
    "uid": "5bd40edf-1d74-48f9-b90e-b2bead03d0af",
    "updatedAt": "2023-07-14T08:19:26.687Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/b124ca13-6827-4dd8-991b-eb29c6b22bfd"
  },
  {
    "accessCode": "kia-carnival",
    "active": true,
    "createdAt": "2023-07-14T03:12:51.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b0bd33bd1a1d1878a26801",
    "isDeleted": false,
    "isPublic": false,
    "name": "Kia Carnival",
    "uid": "10a7f3e5-fbbf-4afc-9316-e9a6d33a1855",
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/24a8d551-3948-4aad-a474-c4ce01069b69"
  },
  {
    "accessCode": "kiosk-cinema",
    "active": true,
    "createdAt": "2023-07-18T09:35:25.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b65cddc15e8312bcc5824b",
    "isDeleted": false,
    "isPublic": false,
    "name": "Kiosk Cinema",
    "uid": "80d44048-8bd4-4a62-8579-8fe133ed6e28",
    "updatedAt": "2023-12-27T03:46:34.616Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/969be730-b4ad-496b-ad78-58b2a08f92d1"
  },
  {
    "accessCode": "gymtronic",
    "active": true,
    "createdAt": "2023-07-19T06:13:59.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b77f278889f610c4af844a",
    "isDeleted": false,
    "isPublic": false,
    "name": "Gymtronic",
    "uid": "e553d89d-75a5-4c18-943c-9734427a0db4",
    "updatedAt": "2023-12-27T03:51:53.924Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/9d76fda6-0512-4f2b-8f25-235d4927a4ca"
  },
  {
    "accessCode": "terra-care",
    "active": true,
    "createdAt": "2023-07-19T10:00:48.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b7b4500c2bc50ef0105db1",
    "isDeleted": false,
    "isPublic": false,
    "name": "Terra Care",
    "uid": "fe844a77-11ce-44e4-b70b-ffd4ac279b52",
    "updatedAt": "2023-12-27T03:55:39.653Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/86c2a2b9-9514-4a5a-be9e-71b82c75914c"
  },
  {
    "accessCode": "aged-care-facilities",
    "active": true,
    "createdAt": "2023-07-20T03:33:26.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b8ab06e5dc8019e8c3c350",
    "isDeleted": false,
    "isPublic": false,
    "name": "Aged Care Facilities",
    "uid": "1bbb5168-0dd3-4f41-a80d-b97cb0480b0c",
    "updatedAt": "2023-12-27T03:57:11.234Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/5fbbe9f5-4b40-42fc-808e-25fe35452565"
  },
  {
    "accessCode": "childcare-facilities",
    "active": true,
    "createdAt": "2023-07-20T05:37:50.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b8c82ed8555e0b5caa6ca6",
    "isDeleted": false,
    "isPublic": false,
    "name": "Childcare Facilities",
    "uid": "85351ff0-507b-4f7e-b309-07b652478a0c",
    "updatedAt": "2023-12-27T03:58:08.250Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/39ae8916-a896-48bf-835f-fca40a989a9e"
  },
  {
    "accessCode": "bdi-herault-store",
    "active": true,
    "createdAt": "2023-07-20T07:08:42.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b8dd7ae964cf1770681250",
    "isDeleted": false,
    "isPublic": false,
    "name": "Bdi-Herault Store",
    "uid": "084f5adc-e9bb-48aa-8a6c-38a3f8217e10",
    "updatedAt": "2023-12-26T03:08:16.893Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/0df24e3b-8e1d-49d7-89c6-7ba94a1b4826"
  },
  {
    "accessCode": "central-park-shopping-centre",
    "active": true,
    "createdAt": "2023-07-20T09:36:46.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b9002eb8e5e10bb0146187",
    "isDeleted": false,
    "isPublic": false,
    "name": "Central Park Shopping Centre",
    "uid": "3a13575d-f4cc-48e7-8cec-a8f46a29d140",
    "updatedAt": "2023-07-20T09:38:52.581Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/657b2aae-37ab-43c3-9b71-a7eacf4be8f7"
  },
  {
    "accessCode": "museum",
    "active": true,
    "createdAt": "2023-07-20T10:35:38.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b90dfab8e5e10bb01464a5",
    "isDeleted": false,
    "isPublic": false,
    "name": "Museum",
    "uid": "f2918778-113f-47f6-833b-be395e7868be",
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e4461ecf-7378-4240-8669-e91988468e66"
  },
  {
    "accessCode": "conference-center",
    "active": true,
    "createdAt": "2023-07-21T03:23:29.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64b9fa313c53b20114b6adba",
    "isDeleted": false,
    "isPublic": false,
    "name": "Conference Center",
    "uid": "401b5b75-75cd-469a-a237-b0ea86434e98",
    "updatedAt": "2023-12-27T03:18:43.068Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f0eeab92-f4cb-44b6-9587-4352505ae911"
  },
  {
    "accessCode": "miami-tourism",
    "active": true,
    "createdAt": "2023-07-21T06:02:10.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64ba1f620859370f14b75066",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami Tourism",
    "uid": "6a82bb1c-5691-47b2-a6b4-fdd71951c2e1",
    "updatedAt": "2023-08-09T09:59:15.427Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/89028171-a977-4ea7-b601-33238d34f630"
  },
  {
    "accessCode": "council",
    "active": true,
    "createdAt": "2023-07-21T08:08:27.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64ba3cfb0859370f14b756a5",
    "isDeleted": false,
    "isPublic": false,
    "name": "Council",
    "uid": "a75c5462-e649-4589-a85b-4b0d51018d2e",
    "updatedAt": "2023-12-27T03:17:43.611Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/15fc1e97-8b8d-43cf-8fb0-572239adf815"
  },
  {
    "accessCode": "marina-bay-sands",
    "active": true,
    "createdAt": "2023-07-24T03:06:16.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64bdeaa8df54e51c0c6ad2c1",
    "isDeleted": false,
    "isPublic": false,
    "name": "Marina Bay Sands",
    "uid": "10cd82ab-7ae5-4991-bb91-b240e929854c",
    "updatedAt": "2023-12-27T02:28:34.433Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/2969684a-676b-436a-853c-b0562d1db2ce"
  },
  {
    "accessCode": "holey-moley-clarke-quay",
    "active": true,
    "createdAt": "2023-07-24T04:18:02.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64bdfb7a9d99ac0508f30119",
    "isDeleted": false,
    "isPublic": false,
    "name": "Holey Moley Clarke Quay",
    "uid": "896bdc4e-3645-43e6-8aee-46f820d802c5",
    "updatedAt": "2023-12-26T03:11:20.453Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/57aff2d8-4257-479b-bfe1-63a78ce51918"
  },
  {
    "accessCode": "hebre-rochefort-museum",
    "active": true,
    "createdAt": "2023-07-24T06:59:00.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64be2134df54e51c0c6ad522",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hèbre-Rochefort Museum",
    "uid": "469d0388-180a-4762-a705-11ebdc8cdfbe",
    "updatedAt": "2023-12-27T03:48:44.193Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/5b76e4d4-78bb-44f2-9290-77e99e585c83"
  },
  {
    "accessCode": "promotion-house",
    "active": true,
    "createdAt": "2023-07-24T09:33:29.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64be4569df54e51c0c6ada94",
    "isDeleted": false,
    "isPublic": false,
    "name": "Promotion House",
    "uid": "7eeececa-cd4a-404a-a1d7-d303c44890a7",
    "updatedAt": "2023-12-26T11:53:57.544Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f7a00342-30de-477f-a75e-0f9dc443657a"
  },
  {
    "accessCode": "hanoi-phoenix-house",
    "active": true,
    "createdAt": "2023-07-24T09:53:15.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64be4a0bdf54e51c0c6adbd7",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hanoi Phoenix House",
    "uid": "e69f63c7-5efe-47c0-965f-c05635544d55",
    "updatedAt": "2023-12-26T11:51:13.283Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/09b9ec0c-46e4-4d8a-8341-ac675ce535bb"
  },
  {
    "accessCode": "keeaumoku-house",
    "active": true,
    "createdAt": "2023-07-24T10:43:55.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64be55ebdf54e51c0c6ae08e",
    "isDeleted": false,
    "isPublic": false,
    "name": "Keeaumoku House",
    "uid": "ae06a01b-d51e-44dc-840f-0527fbe1031e",
    "updatedAt": "2023-12-26T11:52:39.399Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/401b11e9-91b9-46c3-983d-73002b5a81f7"
  },
  {
    "accessCode": "keeaumoku-unit-11-house",
    "active": true,
    "createdAt": "2023-07-24T10:48:08.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64be56e8df54e51c0c6ae12e",
    "isDeleted": false,
    "isPublic": false,
    "name": "Keeaumoku Unit 11 House",
    "uid": "ad45b1b1-d2ff-49e3-be03-4b8c5b7072f8",
    "updatedAt": "2023-12-26T11:56:15.023Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/2deb10d1-212c-46d5-8b81-1275cb4b875e"
  },
  {
    "accessCode": "spa-salon",
    "active": true,
    "createdAt": "2023-07-25T02:47:17.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64bf37b5b0551616182d115c",
    "isDeleted": false,
    "isPublic": false,
    "name": "Spa & Salon",
    "uid": "e5a0c916-2aec-44c3-b47e-2a488875bbc6",
    "updatedAt": "2023-12-27T03:53:32.231Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/48832eb8-1177-4f2e-bb3d-1605a2c05816"
  },
  {
    "accessCode": "vietnam-web-summit",
    "active": true,
    "createdAt": "2023-07-25T02:56:23.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64bf39d71b44141b184c4305",
    "isDeleted": false,
    "isPublic": false,
    "name": "VietNam Web Summit",
    "uid": "6d76935a-04fd-4a11-bfbb-ecb4bed6955d",
    "updatedAt": "2024-06-28T03:37:05.891Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/92e6c439-4cb5-4aea-8113-8358ccd20b3b"
  },
  {
    "accessCode": "casa-house",
    "active": true,
    "createdAt": "2023-08-09T10:38:37.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64d36cadad38860e0cd2ad8f",
    "isDeleted": false,
    "isPublic": false,
    "name": "Casa House",
    "uid": "92a440ee-3044-464a-868d-32a32264a76c",
    "updatedAt": "2023-12-26T11:43:46.908Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/2ee53710-1ad8-4f90-8231-b3c18fe2135a"
  },
  {
    "accessCode": "viet-ad-2023",
    "active": true,
    "createdAt": "2023-08-18T08:32:15.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64df2c8f1a24a8182c66296c",
    "isDeleted": false,
    "isPublic": false,
    "name": "VietAd Exhibition 2023",
    "uid": "36336120-0809-49cf-b4ea-b39f4dd8bd09",
    "updatedAt": "2023-12-27T03:24:02.153Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1a06a0bf-7fb9-4d40-88ed-6115d44602a7"
  },
  {
    "accessCode": "innoex",
    "active": true,
    "createdAt": "2023-08-25T16:09:38.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64e8d242086a2e1bc8873679",
    "isDeleted": false,
    "isPublic": false,
    "name": "INNOEX",
    "uid": "3a734769-45ba-462f-980b-2bceafd39e6a",
    "updatedAt": "2023-12-27T03:27:31.823Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e6d36007-5537-4043-9405-4f00168d02aa"
  },
  {
    "accessCode": "miami-city-floria",
    "active": true,
    "createdAt": "2023-08-30T03:37:09.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64eeb9657901f119bcdac601",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami City, Florida",
    "uid": "e5bdeee3-7960-4678-8879-09cf877539e5",
    "updatedAt": "2023-09-11T13:52:49.227Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/83a0b5a3-5ee7-4e5c-9ca9-7502fd66ea3a"
  },
  {
    "accessCode": "aeon-mall",
    "active": true,
    "createdAt": "2023-09-05T04:24:32.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64f6ad80b3bee50b049cefb4",
    "isDeleted": false,
    "isPublic": false,
    "name": "AEON MALL TÂN PHÚ",
    "uid": "98aeec5f-1053-43c1-9ad4-6009b2847389",
    "updatedAt": "2024-03-29T08:01:41.254Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/972b6ec9-dc09-4185-899e-3d51c2dd2fb1"
  },
  {
    "accessCode": "for-map-navigation",
    "active": true,
    "createdAt": "2023-09-05T10:08:34.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "64f6fe2272fd6216cc38aad3",
    "isDeleted": false,
    "isPublic": false,
    "name": "For Map Navigation",
    "uid": "517d3af0-9a86-4ea2-aaa3-363d9f09ed3e",
    "updatedAt": "2023-09-05T10:17:32.833Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1cf4c867-dd26-4bc8-8f81-2b393828baf8"
  },
  {
    "accessCode": "showroom-an-cuong",
    "active": true,
    "createdAt": "2023-10-01T15:00:25.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6519898959dc0c17102ac056",
    "isDeleted": false,
    "isPublic": false,
    "name": "Showroom An Cường",
    "uid": "a3b227e6-f2d8-4a7d-b48a-592fe7b9e129",
    "updatedAt": "2023-10-01T16:51:28.493Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/49834527-ba21-4f62-95ec-ec31232df9e3"
  },
  {
    "accessCode": "showroom-360-demo",
    "active": true,
    "createdAt": "2023-10-06T07:41:57.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "651fba45ec9fec1a4cb33f10",
    "isDeleted": false,
    "isPublic": false,
    "name": "Showroom 360 Demo",
    "uid": "ef35a8ea-7a35-427e-b607-36642ade204d",
    "updatedAt": "2023-12-27T02:24:39.039Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/034cce30-b697-41c8-996b-c02daf98ae83"
  },
  {
    "accessCode": "showroom-360-demo-2",
    "active": true,
    "createdAt": "2023-10-08T09:03:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6522705cf789cd1bf0ff2076",
    "isDeleted": false,
    "isPublic": false,
    "name": "Showroom 360 Demo 2",
    "uid": "a8ecc4e9-2535-46fa-89ab-363e80ac95ac",
    "updatedAt": "2023-12-26T02:51:35.028Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/2ab47ca6-dde1-4505-b68c-11411d2baf73"
  },
  {
    "accessCode": "miami",
    "active": true,
    "createdAt": "2023-10-09T16:19:08.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "652427fcef80211e90753457",
    "isDeleted": false,
    "isPublic": false,
    "name": "Miami",
    "uid": "58e6da5f-4ed2-4ce3-a309-6a8bcef674a9",
    "updatedAt": "2023-12-27T02:30:50.227Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/02d8406f-2e88-4370-a568-727fff07ccf8"
  },
  {
    "accessCode": "aerial-cities",
    "active": true,
    "createdAt": "2023-10-11T07:42:09.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "652651d19f451c103c036935",
    "isDeleted": false,
    "isPublic": false,
    "name": "Aerial Cities",
    "uid": "437f19b6-9443-4d05-bbc6-d77a139b9126",
    "updatedAt": "2023-12-27T02:33:03.045Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f6d26200-a84e-45b0-924e-38c2c7db9c0e"
  },
  {
    "accessCode": "car-services",
    "active": true,
    "createdAt": "2023-10-16T09:14:11.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "652cfee390d61511cc130083",
    "isDeleted": false,
    "isPublic": false,
    "name": "Car Services ",
    "uid": "972ddbda-c87e-4c0a-b28a-000ced22436d",
    "updatedAt": "2024-06-24T03:23:44.372Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/ceede300-4094-47f5-bc18-5220a3f340b1"
  },
  {
    "accessCode": "demo",
    "active": true,
    "createdAt": "2023-10-24T07:33:30.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6537734aa9103619f07e6e7d",
    "isDeleted": false,
    "isPublic": false,
    "name": "Vizion Demo",
    "uid": "4eac076f-454d-41f3-b24b-35342caf5782",
    "updatedAt": "2023-10-25T09:41:36.283Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/3f8794af-d443-4a65-bc89-f230043dc526"
  },
  {
    "accessCode": "techfest-2023",
    "active": true,
    "createdAt": "2023-11-16T04:18:54.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6555982e7a33331d300b9339",
    "isDeleted": false,
    "isPublic": false,
    "name": "TechFest 2023",
    "uid": null,
    "updatedAt": "2023-12-27T03:26:47.764Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/402ab5d2-1b7f-4275-9507-2d84a4fe2d31"
  },
  {
    "accessCode": "bds-1pn",
    "active": true,
    "createdAt": "2023-11-17T07:58:31.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65571d277a33331d300ba469",
    "isDeleted": false,
    "isPublic": false,
    "name": "BDS 1PN",
    "uid": null,
    "updatedAt": "2023-11-27T06:50:06.975Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1e2900f8-27a1-451f-bf45-e784e1200470"
  },
  {
    "accessCode": "bds-2pn",
    "active": true,
    "createdAt": "2023-11-17T07:59:32.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65571d647a33331d300ba4bf",
    "isDeleted": false,
    "isPublic": false,
    "name": "BDS 2PN",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/c7055e7b-0a0e-456e-8058-87c9e5f90de3"
  },
  {
    "accessCode": "bds-3pn",
    "active": true,
    "createdAt": "2023-11-17T08:01:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65571dd47a33331d300ba515",
    "isDeleted": false,
    "isPublic": false,
    "name": "BDS 3PN",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/74e0d685-4c47-4a9a-ae93-fa706e6551f1"
  },
  {
    "accessCode": "vn-nic-tour",
    "active": true,
    "createdAt": "2023-12-12T10:43:15.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65783943c2496d19ccf312dc",
    "isDeleted": false,
    "isPublic": false,
    "name": "vn-nic-tour",
    "uid": null,
    "updatedAt": "2023-12-12T11:11:33.010Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/d949fe7b-e725-42a3-a303-c626adafbb62"
  },
  {
    "accessCode": "woac-furniture",
    "active": true,
    "createdAt": "2023-12-22T03:51:00.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658507a42293c11a08f69e93",
    "isDeleted": false,
    "isPublic": false,
    "name": "WOAC - Furniture",
    "uid": null,
    "updatedAt": "2023-12-22T08:36:20.292Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1419915e-f728-4a32-bc15-a2486eeeaf3e"
  },
  {
    "accessCode": "hung-phat-sai-gon",
    "active": true,
    "createdAt": "2023-12-22T03:59:12.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658509906920aa1fdc53a863",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hưng Phát Sài Gòn - Furniture",
    "uid": null,
    "updatedAt": "2023-12-22T08:29:45.504Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/7ef5be93-1593-467d-9928-39e1905e5c29"
  },
  {
    "accessCode": "makxim-water-filter",
    "active": true,
    "createdAt": "2023-12-22T07:57:26.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65854166f2e010139cdba796",
    "isDeleted": false,
    "isPublic": false,
    "name": "Makxim - Water Filter",
    "uid": null,
    "updatedAt": "2023-12-22T08:13:58.397Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/81e735b6-15c2-4466-9d9b-409235e3ba69"
  },
  {
    "accessCode": "caltheza-plan-pot",
    "active": true,
    "createdAt": "2023-12-22T08:41:27.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65854bb7f2e010139cdba9dd",
    "isDeleted": false,
    "isPublic": false,
    "name": "Caltheza - Plan pot",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/c6dde8c5-ea65-4e3d-b477-e0f59705cc99"
  },
  {
    "accessCode": "crystal-marble-group",
    "active": true,
    "createdAt": "2023-12-22T08:43:58.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65854c4ef2e010139cdbaa4e",
    "isDeleted": false,
    "isPublic": false,
    "name": "Crystal Marble Group",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/60ffac92-b8ff-4583-83ef-a38b07bb5799"
  },
  {
    "accessCode": "da-hoa-cuong-hai-duong",
    "active": true,
    "createdAt": "2023-12-22T08:46:52.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65854cfcf2e010139cdbaac4",
    "isDeleted": false,
    "isPublic": false,
    "name": "Đá Hoa Cương Hải Dương",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/d94c23bd-b735-40b0-a345-35f29741f47e"
  },
  {
    "accessCode": "lelumber-furniture",
    "active": true,
    "createdAt": "2023-12-22T09:16:57.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658554091107a110ccf5698b",
    "isDeleted": false,
    "isPublic": false,
    "name": "Lelumber - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/43f7cc55-8705-4a04-83e0-66897d381b90"
  },
  {
    "accessCode": "fibiao-furniture",
    "active": true,
    "createdAt": "2023-12-22T09:20:04.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658554c41107a110ccf569fb",
    "isDeleted": false,
    "isPublic": false,
    "name": "Fibiao - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/1c909980-634b-4335-889d-ad4182987497"
  },
  {
    "accessCode": "green-lens-trees",
    "active": true,
    "createdAt": "2023-12-22T09:23:23.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6585558bbb05ac07383f7598",
    "isDeleted": false,
    "isPublic": false,
    "name": "Green Lens - Trees",
    "uid": null,
    "updatedAt": "2023-12-22T09:25:04.819Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/827a7ed3-3b94-4c09-bf7e-2c39ea265456"
  },
  {
    "accessCode": "hawonkoo-home-appliances",
    "active": true,
    "createdAt": "2023-12-22T09:32:32.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658557b0bb05ac07383f76e0",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hawonkoo - Home Appliances",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/40f28959-02a6-4bf1-95dd-ee11f10e7afb"
  },
  {
    "accessCode": "junger-home-appliances",
    "active": true,
    "createdAt": "2023-12-22T09:36:16.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65855890bb05ac07383f77ad",
    "isDeleted": false,
    "isPublic": false,
    "name": "Junger - Home Appliances",
    "uid": null,
    "updatedAt": "2023-12-22T09:36:32.704Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/ce039d6f-4f09-4845-96b2-000c7631e24e"
  },
  {
    "accessCode": "kha-ly-blankets",
    "active": true,
    "createdAt": "2023-12-22T09:39:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6585594c1107a110ccf56a63",
    "isDeleted": false,
    "isPublic": false,
    "name": "Kha Ly - Blankets",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/91858b6b-732d-4b46-897e-9087a4168c84"
  },
  {
    "accessCode": "larano-drying-rack",
    "active": true,
    "createdAt": "2023-12-22T09:42:18.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658559fa1107a110ccf56ac0",
    "isDeleted": false,
    "isPublic": false,
    "name": "Larano - Drying Rack",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/5f874a85-0018-483b-a3a7-37490620e539"
  },
  {
    "accessCode": "moc-thinh-noi-that",
    "active": true,
    "createdAt": "2023-12-22T09:45:36.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65855ac01107a110ccf56b47",
    "isDeleted": false,
    "isPublic": false,
    "name": "Mộc Thịnh - Nội Thất",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/471b5f89-c0fd-4ca2-badc-621217985508"
  },
  {
    "accessCode": "noi-that-1",
    "active": true,
    "createdAt": "2023-12-22T09:48:16.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65855b601107a110ccf56bbc",
    "isDeleted": false,
    "isPublic": false,
    "name": "Nội Thất 1",
    "uid": null,
    "updatedAt": "2023-12-22T09:48:22.690Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/38da9d3c-843e-42c7-9d50-ea1b52622ba1"
  },
  {
    "accessCode": "izen-home-furniture",
    "active": true,
    "createdAt": "2023-12-22T09:52:43.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65855c6b1107a110ccf56c21",
    "isDeleted": false,
    "isPublic": false,
    "name": "Izen Home - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/5eb0ae87-9161-4f78-b256-00196e980629"
  },
  {
    "accessCode": "lucky-furniture",
    "active": true,
    "createdAt": "2023-12-22T09:54:40.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65855ce078c6130ce4e610ce",
    "isDeleted": false,
    "isPublic": false,
    "name": "Lucky - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6094fdc4-57e6-456a-9f0d-c526d6947f96"
  },
  {
    "accessCode": "pg-home-furniture",
    "active": true,
    "createdAt": "2023-12-22T10:12:25.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856109c709c3172457fa41",
    "isDeleted": false,
    "isPublic": false,
    "name": "PG Home - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/578133bb-4e76-4a1f-9ebe-5567cb820807"
  },
  {
    "accessCode": "raimu-home-furniture",
    "active": true,
    "createdAt": "2023-12-22T10:16:54.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856216c709c3172457fb06",
    "isDeleted": false,
    "isPublic": false,
    "name": "Raimu Home - Furniture",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/c190b6d4-8396-476f-837c-4f19a2b5f864"
  },
  {
    "accessCode": "hoang-gia-san-go",
    "active": true,
    "createdAt": "2023-12-22T10:19:10.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6585629ec709c3172457fb7b",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hoàng Gia - Sàn Gỗ",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f70e34ab-1e10-49a9-b9ff-8f63c7b09702"
  },
  {
    "accessCode": "shigeru-lavabo",
    "active": true,
    "createdAt": "2023-12-22T10:23:51.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658563b7c709c3172457fc3b",
    "isDeleted": false,
    "isPublic": false,
    "name": "Shigeru - Lavabo",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/3830552b-0938-451f-b017-499ddc8d17da"
  },
  {
    "accessCode": "squeen-san-go",
    "active": true,
    "createdAt": "2023-12-22T10:26:25.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856451c709c3172457fcd2",
    "isDeleted": false,
    "isPublic": false,
    "name": "Squeen - Sàn Gỗ",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/fa7ec092-97d3-47a1-8ba0-36e9c71ff47d"
  },
  {
    "accessCode": "suit-tu-te",
    "active": true,
    "createdAt": "2023-12-22T10:28:21.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658564c5c709c3172457fd45",
    "isDeleted": false,
    "isPublic": false,
    "name": "Suit Tử Tế",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6b70cfe0-b698-4a7f-8d5d-4f7be7765af2"
  },
  {
    "accessCode": "tan-gia-phat-noi-ngoai-that",
    "active": true,
    "createdAt": "2023-12-22T10:30:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856540c709c3172457fdbe",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tân Gia Phát - Nội Ngoại Thất",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6c162add-4a1f-42ac-b4d1-e8a7ca186f19"
  },
  {
    "accessCode": "tan-hoa-1-do-noi-that",
    "active": true,
    "createdAt": "2023-12-22T10:33:07.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658565e3c709c3172457fe3f",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tân Hoa 1 - Đồ Nội Thất",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f17f761b-1fa9-457b-a9b1-b74fe8e99adf"
  },
  {
    "accessCode": "thiet-bi-xuong",
    "active": true,
    "createdAt": "2023-12-22T10:36:51.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658566c3c709c3172457fefc",
    "isDeleted": false,
    "isPublic": false,
    "name": "Thiết Bị Xưởng",
    "uid": null,
    "updatedAt": "2023-12-22T10:39:52.055Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/6aaac743-133b-4c1c-9fc0-9ce08edeea51"
  },
  {
    "accessCode": "tien-thanh-water",
    "active": true,
    "createdAt": "2023-12-22T10:43:04.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856838c709c3172457ffc2",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tiến Thành Water",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/dadbd327-0147-4e2b-a188-94d7c421c575"
  },
  {
    "accessCode": "tokyo-pump",
    "active": true,
    "createdAt": "2023-12-22T10:45:19.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658568bf54e0f9161c742339",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tokyo - Pump",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/9c1ed001-e0c7-4255-98b5-26423f5f8726"
  },
  {
    "accessCode": "tpc-dung-cu-cam-tay",
    "active": true,
    "createdAt": "2023-12-22T10:47:20.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6585693854e0f9161c742373",
    "isDeleted": false,
    "isPublic": false,
    "name": "TPC - Dụng Cụ Cầm Tay",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/f5371d13-5ec4-465c-b27e-de85204ede49"
  },
  {
    "accessCode": "tre-viet",
    "active": true,
    "createdAt": "2023-12-22T10:49:46.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "658569ca54e0f9161c7423c5",
    "isDeleted": false,
    "isPublic": false,
    "name": "Tre Viet",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/93c307e5-a475-403c-a843-c90b78660ee4"
  },
  {
    "accessCode": "uten-thiet-bi-dien",
    "active": true,
    "createdAt": "2023-12-22T11:04:08.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856d2854e0f9161c74248e",
    "isDeleted": false,
    "isPublic": false,
    "name": "Uten - Thiết Bị Điện",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/50798ce4-fa39-409f-a9c3-00f2e90535e8"
  },
  {
    "accessCode": "zinus-nem",
    "active": true,
    "createdAt": "2023-12-22T11:08:07.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65856e1754e0f9161c742515",
    "isDeleted": false,
    "isPublic": false,
    "name": "Zinus - Nệm",
    "uid": null,
    "updatedAt": "2023-12-22T11:08:14.427Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e43edd86-ff4e-42af-bf91-c3e9e7ca719a"
  },
  {
    "accessCode": "3a398dcb-d926-4e93-ae1f-c4c47c4ad282",
    "active": true,
    "createdAt": "2024-02-22T11:32:38.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65d730d64773fd1c3c392670",
    "isDeleted": false,
    "isPublic": false,
    "name": "Duc Thanh Wood ",
    "uid": "afa68f91-3462-4087-ba4e-3d8e8720e0c3",
    "updatedAt": "2024-03-22T06:26:42.386Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/8a3cd8ba-d1ea-4b11-b137-b3d916b22abf"
  },
  {
    "accessCode": "aa-corporation",
    "active": true,
    "createdAt": "2024-02-29T07:13:21.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65e02e91e149ec1208beeac9",
    "isDeleted": false,
    "isPublic": false,
    "name": "AA Corporation ",
    "uid": null,
    "updatedAt": "2024-03-11T03:42:41.734Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/8c703865-3891-45cb-8e8a-462a024b19f0"
  },
  {
    "accessCode": "step-school-traveling",
    "active": true,
    "createdAt": "2024-03-19T12:35:54.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "65f986aa8d810316b8982d01",
    "isDeleted": false,
    "isPublic": false,
    "name": "Step School Traveling",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/b5295b0e-9035-4fbc-9037-0aefbdde7f7f"
  },
  {
    "accessCode": "condo-minium",
    "active": true,
    "createdAt": "2024-04-01T06:16:28.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "660a513cf40d271ad8dc594b",
    "isDeleted": false,
    "isPublic": false,
    "name": "CONDOMINIUM",
    "uid": null,
    "updatedAt": "2024-04-01T08:43:54.890Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/3f4d4818-e113-4493-a839-6f09f8e5e134"
  },
  {
    "accessCode": "lmark-68-phan-dang-luu",
    "active": true,
    "createdAt": "2024-04-19T03:53:16.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "6621eaac1fc3a50d44eec9e2",
    "isDeleted": false,
    "isPublic": false,
    "name": "Lmark 68 Phan Dang Luu",
    "uid": null,
    "updatedAt": "2024-04-19T04:10:33.005Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/45cf8f3e-2af5-43f1-a83a-c9174b7b1fc3"
  },
  {
    "accessCode": "patawan-island",
    "active": true,
    "createdAt": "2024-04-26T10:54:23.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "662b87df1fc3a50d44eed61a",
    "isDeleted": false,
    "isPublic": false,
    "name": "Patawan Island",
    "uid": null,
    "updatedAt": null,
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/e8e81f9d-429b-4719-bb03-1c3e1904924e"
  },
  {
    "accessCode": "cerro-tololo",
    "active": true,
    "createdAt": "2024-05-02T03:47:17.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "66330cc51fc3a50d44eed6ef",
    "isDeleted": false,
    "isPublic": false,
    "name": "Cerro Tololo",
    "uid": null,
    "updatedAt": "2024-05-02T11:12:54.507Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/66e4a6bf-ce7c-47b4-a185-f055561f14bb"
  },
  {
    "accessCode": "gemini-south",
    "active": true,
    "createdAt": "2024-05-02T04:22:40.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "663315101fc3a50d44eed7a0",
    "isDeleted": false,
    "isPublic": false,
    "name": "Gemini South",
    "uid": null,
    "updatedAt": "2024-05-28T07:40:50.962Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/7def43bf-9f9b-48a7-9b2f-e459a1e32928"
  },
  {
    "accessCode": "toa-nha-golden-prime",
    "active": true,
    "createdAt": "2024-06-12T10:49:41.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "66697d455506651af06bdb27",
    "isDeleted": false,
    "isPublic": false,
    "name": "Toà Nhà Golden Prime",
    "uid": null,
    "updatedAt": "2024-06-13T04:21:25.852Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/7bfce8fb-4a54-42f0-8c10-ec2e9fe3d4d7"
  },
  {
    "accessCode": "hanoi-phoenix",
    "active": true,
    "createdAt": "2024-07-09T09:35:52.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "668d0478570aa31a641a05d2",
    "isDeleted": false,
    "isPublic": false,
    "name": "Hanoi Phoenix ",
    "uid": null,
    "updatedAt": "2024-07-10T03:53:53.747Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/52f22801-5312-46e7-805c-46401ce7e5d0"
  },
  {
    "accessCode": "mbh-wonderland-resort",
    "active": true,
    "createdAt": "2024-07-17T08:11:23.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "66977cab4bd0d81958968fe2",
    "isDeleted": false,
    "isPublic": false,
    "name": "MBH - Wonderland Resort",
    "uid": null,
    "updatedAt": "2024-08-02T10:41:54.026Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/aaf268cb-99b3-4c84-a2e1-c316406efe1b"
  },
  {
    "accessCode": "vinhomes-grand-park",
    "active": true,
    "createdAt": "2024-07-31T07:38:24.000Z",
    "createdBy": null,
    "customerId": "624bb6155495071290332972",
    "deletedAt": null,
    "id": "66a9e9f0c4dba31908fd5dce",
    "isDeleted": false,
    "isPublic": false,
    "name": "Vinhomes Grand Park ",
    "uid": null,
    "updatedAt": "2024-08-12T09:22:26.131Z",
    "userIdDeleted": null,
    "previewImgUrl": "https://vizionplatformprod.blob.core.windows.net/thumb/49188215-ab9c-4fcd-8dd4-09ea9c4b0153"
  }
]