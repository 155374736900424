import axios from 'axios';

import { ENV } from 'enviroments';

import requestInterceptor from './interceptor/request';
import responseInterceptor from './interceptor/response';

const axiosClient = axios.create({
  baseURL: ENV.BASE_API_URL,
});

// request interceptor handler
requestInterceptor(axiosClient.interceptors.request);

// response interceptor handler
responseInterceptor(axiosClient.interceptors.response);

export default axiosClient;
