import { ENDPOINT } from 'features/scenes/constants';
import axiosClient from 'api';

export const GetStatisticData = async (
  endPoint,
  dateRange,
  fromDate = '',
  toDate = '',
  landingPageAccessCode = undefined,
  tourId = undefined,
  customerId = undefined
) => {
  try {
    return await axiosClient.patch(endPoint, {
      StatisticType: dateRange,
      fromDate: fromDate,
      toDate: toDate,
      landingPageAccessCode,
      tourId,
      customerId,
    });
  } catch (error) {
    return error;
  }
};

export const getLandingPageStatistic = (
  dateRange,
  fromDate,
  toDate,
  landingPageAccessCode
) => {
  return GetStatisticData(
    ENDPOINT.REPORT_LANDING_PAGE,
    dateRange,
    fromDate,
    toDate,
    landingPageAccessCode
  );
};

export const getHomePageStatistic = (dateRange, fromDate, toDate) => {
  return GetStatisticData(
    ENDPOINT.REPORT_HOME_PAGE,
    dateRange,
    fromDate,
    toDate
  );
};

export const getBackJobStatistic = (dateRange, fromDate, toDate) => {
  return GetStatisticData(ENDPOINT.BACKJOB_STATUS, dateRange, fromDate, toDate);
};

export const getTourPageStatistic = (
  dateRange,
  fromDate,
  toDate,
  tourId,
  customerId
) => {
  return GetStatisticData(
    ENDPOINT.REPORT_TOUR_PAGE,
    dateRange,
    fromDate,
    toDate,
    undefined,
    tourId,
    customerId
  );
};

export const getSceneStatistic = (dateRange, fromDate, toDate, tourId) => {
  return GetStatisticData(
    ENDPOINT.REPORT_SCENE_PAGE,
    dateRange,
    fromDate,
    toDate,
    undefined,
    tourId
  );
};

export const getTourStatistic = (tourId, dateRange, fromDate, toDate) => {
  return GetStatisticData(
    ENDPOINT.REPORT_TOUR,
    dateRange,
    fromDate,
    toDate,
    undefined,
    tourId
  );
};
