import { CCardHeader } from '@coreui/react';
import { CChartBar } from '@coreui/react-chartjs';
import React from 'react';

const BarChar = ({
  labels,
  title,
  data,
  backgroundColor,
  xLabel,
  yLabel,
  unit,
}) => {
  return (
    <>
      <CCardHeader>
        <h5 className="text-center">{title}</h5>
      </CCardHeader>
      <CChartBar
        className="chart-wrap"
        labels={labels}
        datasets={[
          {
            data: data,
            backgroundColor: backgroundColor,
            barPercentage: 0.5,
            label: unit,
          },
        ]}
        options={{
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              title: () => {},
              // label: ({ yLabel }) => yLabel,
            },
            displayColors: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: xLabel,
                  fontColor: '#000',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: yLabel,
                  fontColor: '#000',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

export default BarChar;
