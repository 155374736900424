import URL from 'consts/urls';
import api from 'helpers/api';

export const logIn = (data) => {
  return api.post(URL.LOGIN, data);
};

export const register = (data) => {
  return api.post(URL.REGISTER, data);
};

export const verifyAccountEmail = (token) => {
  return api.post(URL.VERIFY_EMAIL, { token });
};

export const forgotPassword = (data) => {
  return api.post(URL.FORGOT_PASSWORD, data);
};

export const checkResetPasswordToken = (token) => {
  return api.post(URL.CHECK_RESET_TOKEN + '/' + token, {});
};

export const resetPassword = (data) => {
  return api.patch(URL.RESET_PASSWORD, data);
};

export const checkToken = () => api.post(URL.CHECK_TOKEN, {});

export default {
  logIn,
  register,
  forgotPassword,
  checkResetPasswordToken,
  resetPassword,
  checkToken,
};
