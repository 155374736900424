import format from 'date-fns/format';

import { DATE_FORMAT } from 'consts';

export const dateFormat = (date) => format(new Date(date), DATE_FORMAT);

export const dateTimeFormat = (date) => {
  const dateValue = new Date(date);
  const dateString = format(dateValue, DATE_FORMAT);
  return dateString + ' ' + dateValue.toLocaleTimeString();
};

export const parseTime = (string) => {
  const date = new Date(string);
  return date.getTime();
};
