import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CCol, CRow, CButton } from '@coreui/react';
import { DATE_RANGE } from 'components/DateRangeFilter/constant';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DateRangeFilter from 'components/DateRangeFilter/DateRangeFilter';
import Filter from '../Filter/Filter';

import { dateRangeValue, filterDateRange } from 'views/charts/utils';
import { isAdmin } from 'features/customers/utils';
import {
  fetchGoogleToken,
  cleanseData,
  isCurrentTimeGreaterThan30Minutes,
  getDaysBetween,
  formatCleanData,
  formatDecimal,
  formatTimeDuration,
} from '../utils';
import { getToursBySearchQuery } from 'services/tours';
import { getGoogleAnalyticsRunReport } from 'services/googleAnalytics';

import '../../../views/charts/Charts.scss';
import { TourReportCharts } from './Components/TourReportCharts';
import { TotalReportCharts } from './Components/TotalReportCharts';
import BarLineChart from '../Charts/BarLineChart';
import GaLineChart from '../Charts/GaLineChart';
import {
  getDateRangeForReport,
  getReportStartDate,
  addDaysToDate,
  total,
  percent,
  divideData,
  totalBounceRate,
  ggAnalyticsGetToken,
} from './report.helper';
import { useGetScenesBytourId } from 'features/scenes/hooks';
import Spinner from 'components/Loader/Spinner';
import { TourSceneTable } from './Components/TourSceneTable';
// import { tourReportData } from './tourReportData';
import { customersReportData } from './customersReportData';
import { sceneReportData } from './sceneReportData';

const tourReportData = [
  {
    accessCode: 'demo',
    active: true,
    createdAt: '2023-10-24T07:33:30.000Z',
    createdBy: null,
    customerId: '624bb6155495071290332972',
    deletedAt: null,
    id: '6537734aa9103619f07e6e7d',
    isDeleted: false,
    isPublic: false,
    name: 'Vizion Demo',
    uid: '4eac076f-454d-41f3-b24b-35342caf5782',
    updatedAt: '2023-10-25T09:41:36.283Z',
    userIdDeleted: null,
    previewImgUrl:
      'https://vizionplatformprod.blob.core.windows.net/thumb/3f8794af-d443-4a65-bc89-f230043dc526',
  },
];

// Mock data
const EventTourReportV2 = () => {
  const { t } = useTranslation(['report', 'common', 'toast']);

  const { userRole, customers, customerId, tours } = useSelector((state) => ({
    userRole: 'Admin',
    customers: customersReportData,
    customerId: '624bb6155495071290332972',
    tours: tourReportData,
  }));

  const [toursToReport, setToursToReport] = useState([
    ...tourReportData.map((tour) => tour.id),
  ]);
  const [isGenReport, setisGenReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [dateRangeData, setDateRangeData] = useState({
    range: 8,
    dateRange: [
      new Date('Sat July 01 2024 18:14:04 GMT+0700'),
      new Date('Sun July 31 2024 18:14:04 GMT+0700'),
    ],
  });

  const dateRange = useMemo(() => {
    let startDate, endDate;
    if (dateRangeData.dateRange) {
      startDate = dateRangeData.dateRange[0].toISOString().slice(0, 10);
      endDate = dateRangeData.dateRange[1].toISOString().slice(0, 10);
    } else {
      startDate = filterDateRange(dateRangeData.range)[0]
        .toISOString()
        .slice(0, 10);
      endDate = filterDateRange(dateRangeData.range)[1]
        .toISOString()
        .slice(0, 10);
    }

    const prevStartDate = getReportStartDate(startDate, endDate);
    const prevEndDate = addDaysToDate(startDate, -1).toISOString().slice(0, 10);
    const result = {
      prevStartDate,
      prevEndDate,
      startDate,
      endDate,
      prevDates: getDaysBetween(prevStartDate, prevEndDate),
      reportDates: getDaysBetween(startDate, endDate),
    };
    return result;
  }, [dateRangeData]);

  const [selectedCustomers, setSelectedCustomers] = useState([customerId]);
  const [selectedTourLists, setSelectedTourLists] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(customerId);
  const [tourData, setTourData] = useState([]);
  const [tourId, setTourId] = useState('6537734aa9103619f07e6e7d');
  const [reportData, setReportData] = useState([]);
  const [reportDataWithOutDate, setReportDataWithOutDate] = useState([]);

  const customerData = useMemo(
    () =>
      customers.map((customer) => ({
        value: customer.id,
        label: customer.companyName,
      })),
    [customers]
  );

  const scenes = sceneReportData;

  useEffect(() => {
    if (!customerSelected) setTourId('');
    if (!isAdmin(userRole)) setCustomerSelected(customerId);
  }, [userRole, customerId, customerSelected]);

  useEffect(() => {
    if (tours.length > 0) {
      setSelectedTourLists([{ [customerId]: tours }]);
    }
  }, [customerId, tours]);

  const fetchData = useCallback(async () => {
    try {
      const res = await getToursBySearchQuery({ customerId: customerSelected });
      if (res) {
        const tours = res.data.data;
        setSelectedTourLists((prevTourLists) => [
          ...prevTourLists,
          { [customerSelected]: tours },
        ]);
      }
    } catch (error) {
      console.error('Error loading tours:', error);
    }
  }, [customerSelected]);

  const handleGenerateReport = async () => {
    const { startDate, endDate } = getDateRangeForReport(
      dateRange.startDate,
      dateRange.endDate
    );
    let googleToken = await ggAnalyticsGetToken();

    // const exprityTime = localStorage.getItem('gTokenExprity');
    if (toursToReport.length > 0) {
      try {
        setIsLoading(true);
        setisGenReport(true);
        // const overTime = isCurrentTimeGreaterThan30Minutes(exprityTime);
        // if (true) {
        //   googleToken = await fetchGoogleToken(true);
        // }
        let dimensions = [
          { name: 'eventName' },
          { name: 'customEvent:tour_id' },
          { name: 'date' },
        ];
        let dimensionsWithoutDate = [
          { name: 'eventName' },
          { name: 'customEvent:tour_id' },
        ];
        const metrics = [
          { name: 'eventCount' },
          { name: 'totalUsers' },
          { name: 'newUsers' },
          { name: 'bounceRate' },
          { name: 'engagementRate' },
        ];

        if (toursToReport.length === 1) {
          dimensions = [
            { name: 'eventName' },
            { name: 'customEvent:scene_id' },
            { name: 'customEvent:tour_id' },
            { name: 'date' },
          ];
          dimensionsWithoutDate = [
            { name: 'eventName' },
            { name: 'customEvent:scene_id' },
            { name: 'customEvent:tour_id' },
          ];
        }
        let response = await getGoogleAnalyticsRunReport(
          googleToken,
          'tour',
          dimensions,
          metrics,
          toursToReport,
          { startDate, endDate }
        );
        let responseWithoutDate = await getGoogleAnalyticsRunReport(
          googleToken,
          'tour',
          dimensionsWithoutDate,
          metrics,
          toursToReport,
          { startDate: dateRange.startDate, endDate: dateRange.endDate }
        );
        const originalData = await response.json();

        const originalDataWithoutDate = await responseWithoutDate.json();

        const cleanData = cleanseData(originalData, tourId, 'tourId');
        const cleanDataWithOutDate = cleanseData(
          originalDataWithoutDate,
          tourId,
          'tourId',
          'sceneId'
        );

        const formatCleanDataWithDate = formatCleanData(cleanData, 'tour_view');
        const formatCleanDataWithOutDate = formatCleanData(
          cleanDataWithOutDate,
          'tour_view'
        );
        setReportDataWithOutDate(formatCleanDataWithOutDate);
        setReportData(formatCleanDataWithDate);
        setIsLoading(false);
      } catch (error) {
        setisGenReport(false);
        console.error('Error run report:', error);
        toast.error(t('error.report.generate', { ns: 'toast' }));
        setIsLoading(false);
      }
    } else {
      setReportDataWithOutDate([]);
      setReportData([]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTourId('6537734aa9103619f07e6e7d');
      handleGenerateReport();
    }, 1000);
  }, []);

  const isHaveTourReport = useMemo(
    () => !!(tourId && reportData.length !== 0),
    [reportData.length, tourId]
  );

  useEffect(() => {
    if (customerSelected && !selectedCustomers.includes(customerSelected)) {
      setSelectedCustomers((prevCustomers) => [
        ...prevCustomers,
        customerSelected,
      ]);
      fetchData();
    }
  }, [customerSelected, fetchData, selectedCustomers]);

  useEffect(() => {
    if (customerSelected) {
      const selectedTourList = selectedTourLists.find(
        (tourList) => tourList[customerSelected]
      );

      if (selectedTourList) {
        const tourListValue = selectedTourList[customerSelected]?.map(
          (tour) => ({ value: tour.id, label: tour.name })
        );
        setTourData(tourListValue);
      }
    }
  }, [customerSelected, selectedTourLists]);

  useEffect(() => {
    setTourId('');
  }, [customerSelected]);

  useEffect(() => {
    if (tourData.length > 0) {
      if (tourId === '') {
        const toursReport = tourData.map((tour) => tour.value);
        setToursToReport(toursReport);
      }

      if (tourId) {
        setToursToReport([tourId]);
      }
    } else setToursToReport([]);
  }, [tourData, tourId]);

  useEffect(() => {
    fetchGoogleToken();
  }, []);

  const tourView = useMemo(() => {
    if (!isHaveTourReport) {
      return reportData.map((item) => {
        return { ...item.tour_view, tourId: item.tourId };
      });
    } else {
      return reportData.map((item) => item.tour_view);
    }
  }, [isHaveTourReport, reportData]);

  const tourLongestDuration = useMemo(
    () => reportData.map((item) => item.tour_timer_15s),
    [reportData]
  );

  const timePVisitorCurrent =
    total(
      divideData(tourLongestDuration, dateRange).currentData,
      'eventCount'
    ) / total(divideData(tourView, dateRange).currentData, 'totalUsers');

  const timePVisitorPrev =
    total(divideData(tourLongestDuration, dateRange).prevData, 'eventCount') /
    total(divideData(tourView, dateRange).prevData, 'totalUsers');

  const timePerSessionCurrent =
    total(
      divideData(tourLongestDuration, dateRange).currentData,
      'eventCount'
    ) / total(divideData(tourView, dateRange).currentData, 'eventCount');
  const timePerSessionPrev =
    total(divideData(tourLongestDuration, dateRange).prevData, 'eventCount') /
    total(divideData(tourView, dateRange).prevData, 'eventCount');

  const charts = useMemo(
    () => (
      <>
        {isLoading ? (
          <div
            className="w-100 d-flex flex-column justify-content-center"
            style={{ height: '65vh' }}
          >
            <Spinner />
            <span className="text-center mt-2">Loading...</span>
          </div>
        ) : (
          <>
            {reportData.length !== 0 && reportDataWithOutDate.length !== 0 ? (
              <>
                <CRow>
                  <CCol sm="4">
                    <TotalReportCharts
                      label={t('views')}
                      total={total(
                        divideData(tourView, dateRange).currentData,
                        'eventCount'
                      )}
                      color={'bg-info'}
                      reportData={reportData}
                      percent={percent(
                        total(
                          divideData(tourView, dateRange).prevData,
                          'eventCount'
                        ),
                        total(
                          divideData(tourView, dateRange).currentData,
                          'eventCount'
                        )
                      )}
                    />
                    <TotalReportCharts
                      label={t('visitors')}
                      total={total(
                        divideData(tourView, dateRange).currentData,
                        'totalUsers'
                      )}
                      reportData={reportData}
                      color={'bg-danger'}
                      percent={percent(
                        total(
                          divideData(tourView, dateRange).prevData,
                          'totalUsers'
                        ),
                        total(
                          divideData(tourView, dateRange).currentData,
                          'totalUsers'
                        )
                      )}
                    />
                    <TotalReportCharts
                      label={t('time_spent')}
                      total={total(
                        divideData(tourLongestDuration, dateRange).currentData,
                        'eventCount'
                      )}
                      reportData={reportData}
                      color={'bg-warning'}
                      percent={percent(
                        total(
                          divideData(tourLongestDuration, dateRange).prevData,
                          'eventCount'
                        ),
                        total(
                          divideData(tourLongestDuration, dateRange)
                            .currentData,
                          'eventCount'
                        )
                      )}
                    />
                    <TotalReportCharts
                      label={t('time_spent_per_visitor')}
                      total={formatDecimal(timePVisitorCurrent)}
                      reportData={reportData}
                      color={'bg-success'}
                      percent={percent(timePVisitorPrev, timePVisitorCurrent)}
                    />
                    <TotalReportCharts
                      label={t('time_spent_per_session')}
                      total={formatDecimal(timePerSessionCurrent)}
                      reportData={reportData}
                      color={'bg-dark'}
                      percent={percent(
                        timePerSessionPrev,
                        timePerSessionCurrent
                      )}
                    />
                    <TotalReportCharts
                      label={t('bounce_rate')}
                      total={
                        !!totalBounceRate(
                          divideData(tourView, dateRange).currentData
                        ) === false
                          ? 0
                          : totalBounceRate(
                              divideData(tourView, dateRange).currentData
                            )
                      }
                      reportData={reportData}
                      color={'bg-danger'}
                      percent={percent(
                        totalBounceRate(
                          divideData(tourView, dateRange).prevData
                        ),
                        totalBounceRate(
                          divideData(tourView, dateRange).currentData
                        )
                      )}
                    />
                  </CCol>
                  <CCol sm="8">
                    <BarLineChart
                      label={dateRange}
                      tourView={tourView}
                      reportData={reportData}
                      title={t('tour_view_engagement')}
                      currentData={divideData(tourView, dateRange).currentData}
                      isHaveTourReport={isHaveTourReport}
                    />
                    <GaLineChart
                      label={dateRange}
                      tourView={tourView}
                      reportData={reportData}
                      title={t('returning_visitor_new_visitor')}
                      currentData={divideData(tourView, dateRange).currentData}
                    />
                  </CCol>
                </CRow>
                <TourReportCharts
                  reportData={reportDataWithOutDate}
                  tours={tourData}
                  isHaveTourReport={isHaveTourReport}
                  scenes={scenes}
                />
                <TourSceneTable
                  reportData={reportDataWithOutDate}
                  tourData={tourData}
                  isHaveTourReport={isHaveTourReport}
                  tourId={tourId}
                  scenes={scenes}
                  formatDecimal={formatDecimal}
                  formatTimeDuration={formatTimeDuration}
                />
              </>
            ) : (
              <div className="text-center">{t('no_data_available')}</div>
            )}
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportData, isLoading, t]
  );

  return (
    <>
      <CRow>
        {isAdmin(userRole) && (
          <CCol sm="3">
            <div className="mb-4">
              <Filter
                data={customerData}
                setSelected={setCustomerSelected}
                selectedValue={customerSelected}
                title={t('customer')}
                firstOption={t('select_customer', { ns: 'common' })}
                disabled={true}
              />
            </div>
          </CCol>
        )}
        {(customerSelected || !isAdmin(userRole)) && (
          <CCol sm="3">
            <div className="mb-4">
              <Filter
                data={tourData}
                setSelected={setTourId}
                selectedValue={tourId}
                title="tour"
                firstOption={t('all', { ns: 'common' })}
                disabled={true}
              />
            </div>
          </CCol>
        )}
        {customerSelected && (
          <>
            <CCol sm={!isAdmin(userRole) ? '7' : '4'}>
              <CRow className="statistic-dateRange">
                <CCol sm="12">
                  <div className="data-range">
                    <DateRangeFilter
                      value={dateRangeValue(dateRangeData)}
                      onChange={(range, dateRange) => {
                        setDateRangeData({
                          range: range,
                          dateRange: dateRange,
                        });
                      }}
                    />
                  </div>
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="2" className="text-end">
              <CButton
                color="warning"
                onClick={handleGenerateReport}
                disabled={isLoading}
              >
                {t('generate_report')}
              </CButton>
            </CCol>
          </>
        )}
      </CRow>

      {isGenReport ? charts : ''}
    </>
  );
};

export default EventTourReportV2;
