import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CCol, CRow } from '@coreui/react';

import BarChar from 'views/Report/Charts/BarChar';
import HorizontalBarChart from 'views/Report/Charts/HorizontalBarChart';

import { getTop5HighestData } from 'views/charts/utils';
import { findCommonElementsWithOrder } from 'views/Report/utils';

export const TourReportCharts = ({
  reportData,
  tours,
  isHaveTourReport,
  scenes,
}) => {
  const { t } = useTranslation(['report']);

  const top5HihestData = useMemo(() => {
    const data = !isHaveTourReport ? reportData : reportData[0].scenes;
    return getTop5HighestData(data, isHaveTourReport);
  }, [reportData, isHaveTourReport]);

  const top5TourViewName = useMemo(() => {
    if (!top5HihestData) return;
    return findCommonElementsWithOrder(
      top5HihestData.top5TourView,
      tours,
      'tourId'
    );
  }, [top5HihestData, tours]);

  const top5SceneViewName = useMemo(() => {
    if (!top5HihestData) return;
    return findCommonElementsWithOrder(
      top5HihestData.top5TourView,
      scenes,
      'sceneId'
    );
  }, [scenes, top5HihestData]);

  const top5TourDurationName = useMemo(() => {
    if (!top5HihestData) return;
    return findCommonElementsWithOrder(
      top5HihestData.top5TourDuration,
      tours,
      'tourId'
    );
  }, [top5HihestData, tours]);

  const top5SceneDaurationName = useMemo(() => {
    if (!top5HihestData) return;
    return findCommonElementsWithOrder(
      top5HihestData.top5TourDuration,
      scenes,
      'sceneId'
    );
  }, [scenes, top5HihestData]);

  const getData = useCallback(
    (firstKey, secondKey, eventName) => {
      if (!top5HihestData) return [];
      if (isHaveTourReport) {
        return top5HihestData.top5TourView.map((item) =>
          item[firstKey]?.[eventName] ? item[firstKey]?.[eventName] : '0'
        );
      } else
        return top5HihestData.top5TourView.map((item) =>
          item[secondKey]?.[eventName] ? item[secondKey]?.[eventName] : '0'
        );
    },
    [isHaveTourReport, top5HihestData]
  );

  const top5TourMostInteractive = useMemo(() => {
    const getUserEngagements = reportData.map((item) => item.user_engagement);
    return getUserEngagements.map((item) => item?.eventCount);
  }, [reportData]);

  const chart = useMemo(
    () => (
      <>
        <CCol sm="6">
          <BarChar
            title={
              isHaveTourReport
                ? t('top_5_most_viewed_scenes')
                : t('top_5_most_viewed_tours')
            }
            labels={
              isHaveTourReport
                ? top5SceneViewName?.map((item) => item.title)
                : top5TourViewName?.map((item) => item.label)
            }
            data={getData('scene_view', 'tour_view', 'eventCount')}
            backgroundColor="#58b6ad"
            xLabel={isHaveTourReport ? 'Scenes' : 'Tours'}
            yLabel={isHaveTourReport ? t('scene_views') : t('tour_views')}
            unit={'Views'}
          />
        </CCol>
        <CCol sm="6">
          <HorizontalBarChart
            title={
              isHaveTourReport
                ? t('top_5_scenes_most_bounce_rate')
                : t('top_5_tours_most_bounce_rate')
            }
            labels={
              isHaveTourReport
                ? top5SceneViewName?.map((item) => item.title)
                : top5TourViewName?.map((item) => item.label)
            }
            data={getData('scene_view', 'tour_view', 'bounceRate')}
            color={'#ffb01a'}
            name={'Bounce Rate'}
          />
        </CCol>
        <CCol sm="6">
          <BarChar
            title={
              isHaveTourReport
                ? t('top_5_longest_duration_3d_scenes')
                : t('top_5_longest_duration_3d_tours')
            }
            labels={
              isHaveTourReport
                ? top5SceneDaurationName?.map((item) => item.title)
                : top5TourDurationName?.map((item) => item.label)
            }
            data={getData('scene_timer_15s', 'tour_timer_15s', 'eventCount')}
            backgroundColor="#7a9ffe"
            xLabel={isHaveTourReport ? 'Scenes' : 'Tours'}
            yLabel={t('total_duration_in_minutes')}
            unit={'Seconds'}
          />
        </CCol>
        <CCol sm="6">
          <HorizontalBarChart
            title={
              isHaveTourReport
                ? t('top_5_scenes_most_interactive')
                : t('top_5_tours_most_interactive')
            }
            labels={
              isHaveTourReport
                ? top5SceneViewName?.map((item) => item.title)
                : top5TourViewName?.map((item) => item.label)
            }
            data={top5TourMostInteractive}
            color={'#3399fe'}
            name={'Interact'}
          />
        </CCol>
      </>
    ),
    [
      getData,
      isHaveTourReport,
      top5SceneDaurationName,
      top5SceneViewName,
      top5TourDurationName,
      top5TourMostInteractive,
      top5TourViewName,
      t,
    ]
  );

  return <CRow>{chart}</CRow>;
};
