import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CCol, CRow } from '@coreui/react';

import PopupTextTruncation from 'components/PopupTextTruncation';
import DataTable from 'react-data-table-component';

function combineArrays(arr1, arr2) {
  // Tạo một bản sao của arr1 để không làm thay đổi ban đầu
  const result = JSON.parse(JSON.stringify(arr1));

  // Lặp qua mảng result
  for (const item of result) {
    const matchingItem = arr2.find((x) => x.tourId === item.value);
    if (matchingItem) {
      // Loại bỏ khóa "tourId" trong matchingItem
      delete matchingItem.tourId;

      // Thêm tất cả các thuộc tính từ matchingItem vào item
      Object.assign(item, matchingItem);
    } else {
      // Nếu không tìm thấy một item phù hợp, thêm các thuộc tính với giá trị mặc định 0
      item.tour_timer_15s = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.scene_timer_15s = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.user_engagement = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.user_engagement_single = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.scene_view = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.tour_load_end = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.tour_view = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
      item.user_begin_engagement = {
        eventCount: '0',
        totalUsers: '0',
        newUsers: '0',
        bounceRate: '0',
      };
    }
  }

  return result.sort((a, b) => {
    const eventCountA = !a.tour_view ? 0 : parseInt(a.tour_view.eventCount);
    const eventCountB = !b.tour_view ? 0 : parseInt(b.tour_view.eventCount);

    if (eventCountA < eventCountB) {
      return 1;
    } else if (eventCountA > eventCountB) {
      return -1;
    } else {
      return 0;
    }
  });
}

function copyTitleFromArr1ToArr2(arr1, arr2) {
  if (!arr2) return [];
  // Tạo một đối tượng Map để lưu trữ các title từ arr1
  const titleMap = new Map();

  // Lặp qua các phần tử trong arr1 và lưu trữ title theo sceneId
  arr1.forEach((item) => {
    titleMap.set(item.id, item.title);
  });

  // Lặp qua các phần tử trong arr2 và sao chép title từ arr1 nếu có sceneId trùng khớp
  arr2.forEach((item2) => {
    const title = titleMap.get(item2.sceneId);
    if (title) {
      item2.label = title;
    }
  });

  return arr2;
}

export const TourSceneTable = ({
  reportData,
  tourData,
  isHaveTourReport,
  tourId,
  scenes,
  formatDecimal,
  formatTimeDuration,
}) => {
  const { t } = useTranslation(['report']);

  const formatData = useMemo(
    () => combineArrays(tourData, reportData),
    [reportData, tourData]
  );

  const sceneData = useMemo(
    () => formatData.find((item) => item.value === tourId),
    [formatData, tourId]
  );

  const formatSceneData = useMemo(() => {
    if (!sceneData) return;
    return copyTitleFromArr1ToArr2(scenes, sceneData.scenes);
  }, [sceneData, scenes]);

  const columns = [
    {
      name: isHaveTourReport ? t('scene_name') : t('tour_name'),
      maxWidth: '220px',
      sortable: true,
      selector: (row) => row.label,
      cell: (row) => {
        return <PopupTextTruncation text={row.label} />;
      },
    },
    {
      name: isHaveTourReport ? t('scene_views') : t('tour_views'),
      maxWidth: '135px',
      sortable: true,
      selector: (row) =>
        !isHaveTourReport
          ? row.tour_view.eventCount
          : row.scene_view.eventCount,
      cell: (row) => {
        return (
          <PopupTextTruncation
            text={
              !isHaveTourReport
                ? row.tour_view.eventCount
                : row.scene_view.eventCount
            }
          />
        );
      },
    },
    {
      name: t('visitors'),
      maxWidth: '135px',
      sortable: true,
      selector: (row) =>
        !isHaveTourReport
          ? row.tour_view.totalUsers
          : row.scene_view.totalUsers,
      cell: (row) => {
        return (
          <PopupTextTruncation
            text={
              !isHaveTourReport
                ? row.tour_view.totalUsers
                : row.scene_view.totalUsers
            }
          />
        );
      },
    },
    {
      id: 'modified',
      name: t('total_time_spent'),
      maxWidth: '135px',
      sortable: true,
      selector: (row) =>
        !isHaveTourReport
          ? !row.tour_timer_15s
            ? 0
            : row.tour_timer_15s.eventCount
          : !row.scene_timer_15s
          ? 0
          : row.scene_timer_15s.eventCount,
      cell: (row) => {
        return (
          <PopupTextTruncation
            text={`${
              !isHaveTourReport
                ? !row.tour_timer_15s
                  ? 0
                  : formatTimeDuration(row.tour_timer_15s.eventCount)
                : !row.scene_timer_15s
                ? 0
                : formatTimeDuration(row.scene_timer_15s.eventCount)
            }s`}
          />
        );
      },
    },
    {
      name: t('views_per_visitor'),
      maxWidth: '135px',
      sortable: false,
      selector: (row) =>
        !isHaveTourReport
          ? parseInt(row.tour_view.totalUsers) === 0
            ? 0
            : formatDecimal(row.tour_view.eventCount / row.tour_view.totalUsers)
          : parseInt(row.scene_view.totalUsers) === 0
          ? 0
          : formatDecimal(
              row.scene_view.eventCount / row.scene_view.totalUsers
            ),
      cell: (row) => (
        <PopupTextTruncation
          text={`${
            !isHaveTourReport
              ? parseInt(row.tour_view.totalUsers) === 0
                ? 0
                : formatDecimal(
                    row.tour_view.eventCount / row.tour_view.totalUsers
                  )
              : parseInt(row.scene_view.totalUsers) === 0
              ? 0
              : formatDecimal(
                  row.scene_view.eventCount / row.scene_view.totalUsers
                )
          }`}
        />
      ),
    },
    {
      name: t('time_spent_per_visitor'),
      maxWidth: '135px',
      sortable: false,
      selector: (row) =>
        !isHaveTourReport
          ? !row.tour_timer_15s || parseInt(row.tour_timer_15s.totalUsers) === 0
            ? 0
            : formatDecimal(
                row.tour_timer_15s.eventCount / row.tour_timer_15s.totalUsers
              )
          : !row.scene_timer_15s ||
            parseInt(row.scene_timer_15s.totalUsers) === 0
          ? 0
          : formatDecimal(
              row.scene_timer_15s.eventCount / row.scene_timer_15s.totalUsers
            ),
      cell: (row) => (
        <PopupTextTruncation
          text={`${
            !isHaveTourReport
              ? !row.tour_timer_15s ||
                parseInt(row.tour_timer_15s.totalUsers) === 0
                ? 0
                : formatTimeDuration(
                    formatDecimal(
                      row.tour_timer_15s.eventCount /
                        row.tour_timer_15s.totalUsers
                    )
                  )
              : !row.scene_timer_15s ||
                parseInt(row.scene_timer_15s.totalUsers) === 0
              ? 0
              : formatTimeDuration(
                  formatDecimal(
                    row.scene_timer_15s.eventCount /
                      row.scene_timer_15s.totalUsers
                  )
                )
          }s`}
        />
      ),
    },
    {
      name: t('engagement_rate'),
      maxWidth: '135px',
      sortable: false,
      selector: (row) =>
        !isHaveTourReport
          ? row.tour_view.engagementRate || 0
          : row.scene_view.engagementRate || 0,
      cell: (row) => (
        <PopupTextTruncation
          text={`${
            !isHaveTourReport
              ? row.tour_view.engagementRate || 0
              : row.scene_view.engagementRate || 0
          }%`}
        />
      ),
    },
    {
      name: t('bounce_rate'),
      maxWidth: '135px',
      sortable: false,
      selector: (row) =>
        !isHaveTourReport
          ? row.tour_view.bounceRate * 100
          : row.scene_view.bounceRate * 100,
      cell: (row) => (
        <PopupTextTruncation
          text={`${
            !isHaveTourReport
              ? row.tour_view.bounceRate * 100
              : row.scene_view.bounceRate * 100
          }%`}
        />
      ),
    },
  ];

  return (
    <CRow>
      <CCol sm="12">
        <DataTable
          data={!isHaveTourReport ? formatData : formatSceneData}
          columns={columns}
          progressPending={false}
          selectableRowsSingle={true}
          pointerOnHover
          noHeader={true}
          pagination={true}
        />
      </CCol>
    </CRow>
  );
};
