import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SCOPE_NAME, SITE_SCOPE } from 'consts';
import { checkAllowToDo } from 'utils';
import { useGetSubscription } from 'hooks';
import {
  setPublicTour,
  setPublicTours,
  setTour,
  setTours,
} from 'store/actions';
import {
  copyTour,
  updateToursStatus,
  getTourDetail,
  getTourJson,
  getToursBySearchQuery,
  softDelete,
  saveTour,
} from 'services/tours';
import {
  saveTourGroup,
  deleteTourGroup,
  getTourGroupBySearchQuery,
} from 'services/tourGroups';
import { toast } from 'react-toastify';
import { getTourBaseOnPathName } from 'features/tours/utils';
import { isPublicTourPath } from 'features/publicTour/utils';

export const useGetTours = () => {
  const tours = useSelector((state) => state.tours);
  const customerId = useSelector((state) => state.customer.id);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const loadTours = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await getToursBySearchQuery({ customerId });
      if (result) {
        dispatch(setTours(result.data.data));
      } else {
        dispatch(setTours([]));
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [customerId, dispatch]);

  return { tours, loadTours, isLoading };
};

export const useGetTour = (tourId) => {
  const [tour, setTour] = useState();

  const loadData = useCallback(async () => {
    try {
      const result = await getTourDetail(tourId);
      if (result) {
        setTour(result.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [tourId]);

  useEffect(() => {
    if (!tourId) return;
    loadData();
  }, [loadData, tourId]);

  return { data: tour, load: loadData };
};

export const useGetToursJson = ({ tours }) => {
  const [toursJson, setToursJson] = useState();

  const loadData = useCallback(async () => {
    try {
      const result = await getTourJson({ tours });
      if (result) {
        setToursJson(result.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [tours]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return { data: toursJson, load: loadData };
};

export const useSoftDeleteTour = () => {
  const { t } = useTranslation(['messages', 'toast']);
  const [isDeleteTourLoading, setIsDeleteTourLoading] = useState(false);
  const tours = useSelector((state) => state.tours);
  const publicTours = useSelector((state) => state.publicTours);

  const tourId = useSelector((state) => state.tour.id);
  const publicTourId = useSelector((state) => state.publicTour.id);

  const currentTours = useMemo(
    () => getTourBaseOnPathName(publicTours, tours),
    [publicTours, tours]
  );

  const currentTourId = useMemo(
    () => getTourBaseOnPathName(publicTourId, tourId),
    [publicTourId, tourId]
  );
  const dispatch = useDispatch();

  const setCurrentTours = useCallback(
    (tourIds) => {
      if (isPublicTourPath()) {
        return dispatch(
          setPublicTours(
            currentTours.filter((tour) => tourIds.indexOf(tour.id) === -1)
          )
        );
      } else {
        return dispatch(
          setTours(
            currentTours.filter((tour) => tourIds.indexOf(tour.id) === -1)
          )
        );
      }
    },
    [currentTours, dispatch]
  );

  const setDeleteNotification = (choosingTourId) => {
    if (isPublicTourPath()) {
      toast.success(t('success.public_tour.delete', { ns: 'toast' }));
      if (choosingTourId) {
        dispatch(
          setPublicTour({
            id: '',
            name: '',
            active: false,
            isPublic: false,
            accessCode: '',
          })
        );
      }
    } else {
      toast.success(t('success.tour.delete', { ns: 'toast' }));
      dispatch(
        setTour({
          id: '',
          name: '',
          active: false,
          accessCode: '',
        })
      );
    }
  };

  const deleteData = useCallback(
    async (tourIds) => {
      try {
        setIsDeleteTourLoading(true);
        const res = await softDelete(tourIds);
        if (res) {
          const choosingTourId = tourIds.find((id) => id === currentTourId);
          setIsDeleteTourLoading(false);
          setCurrentTours(tourIds);
          setDeleteNotification(choosingTourId);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTourId, dispatch, currentTours]
  );
  return { onSoftDeleteTour: deleteData, isDeleteTourLoading };
};

export const useCopyTour = () => {
  const { t } = useTranslation(['messages']);
  const [isCopyTourLoading, setIsCopyTourLoading] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tours);
  const copyTourData = useCallback(
    async ({ tourId, data }) => {
      try {
        const toastId = toast.info(t('info.tour.copying'));
        setIsCopyTourLoading(true);
        const res = await copyTour(tourId, data);
        if (res) {
          setIsCopyTourLoading(false);
          const tourCopy = res.data.data;
          const tourData = {
            id: tourCopy.id,
            name: tourCopy.name,
            friendlyName: tourCopy.friendlyName,
            isModified: tourCopy.isModified,
            active: tourCopy.active,
            accessCode: tourCopy.accessCode,
            createdAt: tourCopy.createdAt,
            updatedAt: tourCopy.updatedAt,
          };
          dispatch(setTours([...tours, tourData]));
          dispatch(setTour(tourData));
          toast.dismiss(toastId);
          toast.success(t('success.tour.copy'));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, tours, t]
  );
  return { copyTour: copyTourData, isCopyTourLoading };
};

export const useCheckTourScope = () => {
  // hooks
  const { usage, scopes } = useGetSubscription();

  // store
  const { role, customerId } = useSelector((state) => ({
    role: state.user.role,
    customerId: state.customer.id,
  }));

  // data
  const { tours } = useGetTours(customerId);

  return useMemo(() => {
    if (!usage || !scopes || !role || !tours) return false;
    return checkAllowToDo({
      scopes,
      usage,
      role,
      totalInTour: tours.length,
      scopeName: SCOPE_NAME.TOUR,
    });
  }, [role, scopes, usage, tours]);
};

export const useCheckTourScopeForCreate = (
  tourId = null,
  tourLength = null
) => {
  const { usage, scopes } = useGetSubscription(tourId, tourLength);

  return useMemo(() => {
    if (!usage || !scopes) return false;

    if (scopes.scopeType === SITE_SCOPE.UNLIMITED) return true;

    return !(
      scopes.tour?.value !== SITE_SCOPE.UNLIMITED &&
      usage.tour?.value >= scopes.tour?.value
    );
  }, [usage, scopes]);
};

export const useCheckScopeForCopyTour = (tourId = null, tourLength = null) => {
  const { usage, scopes } = useGetSubscription();
  const { usage: checkUsage } = useGetSubscription(tourId, tourLength);

  // check media
  return useMemo(() => {
    if (!usage || !scopes || !checkUsage) return false;

    const { limitPerTour: checkUsageMedia } = checkUsage.media;
    const { limitPerTour: checkUsageScene } = checkUsage.scene;
    const { value: usageMedia } = usage.media;
    const { value: usageScene } = usage.scene;
    const { value: limitMedia } = scopes.media;
    const { value: limitScene } = scopes.scene;

    // if user can create unlimited media
    if (
      limitMedia === SITE_SCOPE.UNLIMITED &&
      limitScene === SITE_SCOPE.UNLIMITED
    )
      return true;

    // cause copy tour make resources double
    if (limitMedia === SITE_SCOPE.UNLIMITED)
      return usageScene + checkUsageScene * 2 <= limitScene + 1;
    if (limitScene === SITE_SCOPE.UNLIMITED)
      return usageMedia + checkUsageMedia * 2 <= limitMedia + 1;

    return (
      usageMedia + checkUsageMedia * 2 <= limitMedia + 1 &&
      usageScene + checkUsageScene * 2 <= limitScene + 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUsage, scopes, usage, tourLength]);
};

export const useToggleTourStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const tours = useSelector((state) => state.tours);
  const publicTours = useSelector((state) => state.publicTours);
  const dispatch = useDispatch();

  const currentTours = useMemo(
    () => getTourBaseOnPathName(publicTours, tours),
    [publicTours, tours]
  );

  const setCurrentTours = useCallback(
    (newTours) => {
      if (isPublicTourPath()) {
        return dispatch(setPublicTours(newTours));
      } else {
        return dispatch(setTours(newTours));
      }
    },
    [dispatch]
  );

  const setCurrentTour = useCallback(
    (tourDetail) => {
      const newTour = {
        id: tourDetail[0].id,
        name: tourDetail[0].name,
        isModified: tourDetail[0].isModified,
        active: tourDetail[0].active,
        accessCode: tourDetail[0].accessCode,
        isPublic: tourDetail[0].isPublic,
      };
      if (isPublicTourPath()) {
        return dispatch(setPublicTour(newTour));
      } else {
        return dispatch(setTour(newTour));
      }
    },
    [dispatch]
  );

  const toggleTourStatus = useCallback(
    async ({ tourIds, active }) => {
      try {
        setIsLoading(true);
        const res = await updateToursStatus({ tourIds, active });
        if (res) {
          const tourDetail = res.data.data;
          setIsLoading(false);
          const newTours = currentTours.map((tour) =>
            tour.id === tourIds[0]
              ? (tour = { ...tour, active: !tour.active })
              : tour
          );
          setCurrentTours(newTours);
          setCurrentTour(tourDetail);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentTours, setCurrentTour, setCurrentTours]
  );
  return { toggleTourStatus: toggleTourStatus, isLoading };
};

export const useSaveTour = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tours);
  const publicTours = useSelector((state) => state.publicTours);

  const currentTour = useMemo(
    () => getTourBaseOnPathName(publicTours, tours),
    [publicTours, tours]
  );

  const updateData = useCallback(
    async ({ data, onSuccess, onErrorFn }) => {
      try {
        setIsLoading(true);
        const res = await saveTour(data);
        if (res) {
          setIsLoading(false);
          const tourDetail = res.data.data;
          if (tourDetail.isPublic) {
            dispatch(setPublicTour(tourDetail));
          } else {
            dispatch(setTour(tourDetail));
          }
          const tourData = {
            id: tourDetail.id,
            name: tourDetail.name,
            friendlyName: tourDetail.friendlyName,
            isModified: tourDetail.isModified,
            active: tourDetail.active,
            accessCode: tourDetail.accessCode,
            createdAt: tourDetail.createdAt,
            updatedAt: tourDetail.updatedAt,
          };
          const newTourList = currentTour.map((item) => {
            if (item.id === tourData.id) {
              return tourData;
            }
            return item;
          });
          if (tourDetail.isPublic) {
            dispatch(setPublicTours(newTourList));
          } else {
            dispatch(setTours(newTourList));
          }
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(error);
        onErrorFn && onErrorFn(error);
      }
    },
    [currentTour, dispatch]
  );

  return { isLoading, onUpdateTour: updateData };
};

export const useAddNewTour = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tours);
  const publicTours = useSelector((state) => state.publicTours);

  const postData = useCallback(
    async ({ data, onSuccess }) => {
      try {
        setIsLoading(true);
        const res = await saveTour(data);
        if (res) {
          setIsLoading(false);
          const tourDetail = res.data.data;
          const tourData = {
            id: tourDetail.id,
            name: tourDetail.name,
            friendlyName: tourDetail.friendlyName,
            isModified: tourDetail.isModified,
            active: tourDetail.active,
            accessCode: tourDetail.accessCode,
            createdAt: tourDetail.createdAt,
            updatedAt: tourDetail.updatedAt,
            isPublic: tourDetail.isPublic,
          };
          if (tourData.isPublic) {
            dispatch(setPublicTour(tourData));
            dispatch(setPublicTours([...publicTours, tourData]));
          } else {
            dispatch(setTour(tourData));
            dispatch(setTours([...tours, tourData]));
          }
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, publicTours, tours]
  );

  return { isLoading, onAddNewTour: postData };
};

export const useGetTourGroupBySearchQuery = () => {
  const [data, setData] = useState();
  const tourId = useSelector(({ tour }) => tour.id);
  const publicTourId = useSelector(({ publicTour }) => publicTour.id);
  const currentTourID = useMemo(
    () => getTourBaseOnPathName(publicTourId, tourId),
    [publicTourId, tourId]
  );
  const loadTourGroup = useCallback(async () => {
    try {
      const res = await getTourGroupBySearchQuery({ tourId: currentTourID });
      if (res) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
      setData([]);
    }
  }, [currentTourID]);

  useEffect(() => {
    loadTourGroup();
  }, [loadTourGroup]);

  return { data: data, load: loadTourGroup };
};

export const useAddNewTourGroup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addNewGroup = useCallback(async ({ data, onSuccess, onErrorFn }) => {
    try {
      setIsLoading(true);
      const res = await saveTourGroup(data);
      if (res) {
        setIsLoading(false);
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
      onErrorFn && onErrorFn();
    }
  }, []);
  return { addTourGroup: addNewGroup, isLoading };
};

export const useSaveTourGroup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveTour = useCallback(async ({ data, onSuccess, onErrorFn }) => {
    try {
      setIsLoading(true);
      const res = await saveTourGroup(data);
      if (res) {
        setIsLoading(false);
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
      onErrorFn && onErrorFn();
    }
  }, []);
  return { saveTourGroup: saveTour, isLoading };
};

export const useDeleteTourGroup = () => {
  const deleteGroup = useCallback(async ({ id, onSuccess, onErrorFn }) => {
    try {
      const res = await deleteTourGroup(id);
      if (res) {
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
      onErrorFn && onErrorFn();
    }
  }, []);
  return { deleteGroup: deleteGroup };
};
