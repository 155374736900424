import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';

import store from './store';
import i18n from './utils/i18next';
import { combineComponents } from './utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000,
    },
  },
});

const providers = [
  { component: Provider, property: { store } },
  { component: QueryClientProvider, property: { client: queryClient } },
  { component: I18nextProvider, property: { i18n } },
];

const CombinedProviders = combineComponents(...providers);

export default CombinedProviders;
