/* eslint-disable default-case */
import produce from 'immer';

import * as ACTION_TYPE from './actionTypes';
import initialState from './state';

const appReducer = produce((state, action) => {
  switch (action.type) {
    case ACTION_TYPE.INITIATED:
      state.ready = true;
      break;
    case ACTION_TYPE.SET:
      const { type, ...rest } = action;
      Object.keys(rest).forEach((key) => {
        state[key] = rest[key];
      });
      break;
    case ACTION_TYPE.SET_CUSTOMER:
      state.customer = action.customer;
      localStorage.setItem('customer', JSON.stringify(action.customer));
      state.hasProjectsDataFromApi = false;
      break;
    case ACTION_TYPE.LOAD_PROJECTS:
      state.projects = [...state.projects];
      break;
    case ACTION_TYPE.SET_PROJECT:
      state.project = action.project;
      localStorage.setItem('project', JSON.stringify(action.project));
      break;
    case ACTION_TYPE.SET_PROJECTS:
      state.projects = [...action.projects];
      state.hasProjectsDataFromApi = true;
      break;
    case ACTION_TYPE.LOAD_TOURS:
      state.tours = [...state.tours];
      break;
    case ACTION_TYPE.SET_TOUR:
      state.tour = action.tour;
      localStorage.setItem('tour', JSON.stringify(action.tour));
      break;
    case ACTION_TYPE.SET_TOURS:
      state.tours = [...action.tours];
      state.hasToursDataFromApi = true;
      break;
    case ACTION_TYPE.LOAD_PUBLIC_TOURS:
      state.publicTours = [...state.publicTours];
      break;
    case ACTION_TYPE.SET_PUBLIC_TOUR:
      state.publicTour = action.publicTour;
      localStorage.setItem('publicTour', JSON.stringify(action.publicTour));
      break;
    case ACTION_TYPE.SET_PUBLIC_TOURS:
      state.publicTours = [...action.publicTours];
      break;
    case ACTION_TYPE.SET_360_DATA:
      state.threed = {
        ...state.threed,
        ...action.data,
      };
      break;
    case ACTION_TYPE.SET_360_IMAGES:
      state.threed.images = [...action.images];
      state.threed.imageType = action.imageType;
      break;
    case ACTION_TYPE.SET_LENSFLARE:
      state.threed.lensFlareLocation = action.lensFlareLocation;
      break;
    case ACTION_TYPE.SET_USER:
      if (action.user.id !== undefined) {
        state.user.id = action.user.id;
      }
      if (action.user.role !== undefined) {
        state.user.role = action.user.role;
      }
      break;
    case ACTION_TYPE.SET_USER_ROLE:
      state.user.role = action.role;
      break;
    case ACTION_TYPE.SIGN_OUT:
      state.user.id = '';
      state.user.role = '';
      localStorage.setItem('userId', '');
      localStorage.removeItem('token');
      state.isShowClientWelcomePopup = true;
      break;
    case ACTION_TYPE.SET_USER_NAME:
      state.user.name = action.userName;
      break;
    case ACTION_TYPE.SET_USER_CUSTOMERS:
      state.customers = action.customers;
      break;
    case ACTION_TYPE.SET_TOUR_NAME_MODAL:
      state.tourNameModal = action.modal;
      break;
    case ACTION_TYPE.SET_SCENE_NAME_MODAL:
      state.sceneNameModal = action.modal;
      break;
    case ACTION_TYPE.SET_ADD_SCENE_MODAL:
      state.addSceneModal = action.modal;
      break;
    case ACTION_TYPE.SET_IS_SHOW_CLIENT_WELCOME_POPUP:
      state.isShowClientWelcomePopup = false;
      break;
    case ACTION_TYPE.TOGGLE_HEADER_MODAL:
      switch (action.typeModal) {
        case ACTION_TYPE.TOGGLE_CUSTOMER_MODAL:
          state.headerModalOpen.customer = action.isOpen;
          break;
        case ACTION_TYPE.TOGGLE_PUBLIC_TOUR_MODAL:
          state.headerModalOpen.publicTour = action.isOpen;
          break;
        case ACTION_TYPE.TOGGLE_PROJECT_MODAL:
          state.headerModalOpen.project = action.isOpen;
          break;
        case ACTION_TYPE.TOGGLE_TOUR_MODAL:
          state.headerModalOpen.tour = action.isOpen;
          break;
      }
      break;
    case ACTION_TYPE.SET_WEBSITES:
      state.websites = [...action.websites];
      break;
    case ACTION_TYPE.SET_MEDIA_ID:
      state.mediaId = action.mediaId;
      break;

    case ACTION_TYPE.SET_IS_SHOW_MF_HP:
      state.isShowMFHp = action.isShowMFHp;
      break;

    case ACTION_TYPE.SET_INLINE_VIDEO:
      state.isShowInline = action.isShowInline;
      break;

    case ACTION_TYPE.ROTATE_AXIS:
      state.rotateAxis = action.rotateAxis;
      break;

    case ACTION_TYPE.SET_ORIGINAL_INLINE_ROTATE:
      state.originalRotate = action.originalRotate;
      break;
  }
}, initialState);

export default appReducer;
