import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import axiosClient from 'api';
import { useGetSubscription } from 'hooks';
import { useGetTourGroupBySearchQuery } from 'features/tours/hooks';
import { ENDPOINT, QUERY_KEYS, REFETCH_SCENE_TIME } from './constants';
import { getTourBaseOnPathName } from 'features/tours/utils';
import { refreshTourDetail } from 'store/actions';
import { SITE_SCOPE } from 'consts';
import {
  getScenesPerTourGroups,
  updateFirstScene,
  updateGroupScenePosition,
  updateSceneToOtherGroup,
  addAutoPilot,
  getAutoPilot,
} from 'services/scenes';

export const useGetSceneList = ({
  tourGroupId = '',
  refetchInterval,
  tourId,
}) => {
  const intervalMs = useMemo(
    () => refetchInterval && REFETCH_SCENE_TIME,
    [refetchInterval]
  );

  return useQuery(
    [QUERY_KEYS.SCENE_LIST, tourGroupId, tourId],
    () => {
      if (!tourId) {
        return Promise.resolve([]);
      }
      return axiosClient.patch(ENDPOINT.SCENE, { tourId, tourGroupId });
    },
    {
      refetchInterval: intervalMs,
    }
  );
};

export const useGetScenesBytourId = (
  tourId,
  tourGroupId = null,
  refetchInterval = false
) => {
  const { data } = useGetSceneList({ tourId, tourGroupId, refetchInterval });
  return data || [];
};

export const useShowOnMap = () =>
  useMutation((data) => axiosClient.patch(ENDPOINT.SHOW_ON_MAP, data));

export const useCheckSceneScope = (tourId = null, sceneLength = null) => {
  const { usage, scopes } = useGetSubscription(tourId, sceneLength);

  return useMemo(() => {
    if (!usage || !scopes) return false;
    if (scopes.scopeType === SITE_SCOPE.UNLIMITED) return true;
    if (
      scopes.scene?.value === SITE_SCOPE.UNLIMITED &&
      scopes.scene?.limitPerTour === SITE_SCOPE.UNLIMITED
    )
      return true;
    if (
      scopes.scene?.value !== SITE_SCOPE.UNLIMITED &&
      usage.scene?.value >= scopes.scene?.value
    )
      return false;
    return !(
      scopes.scene?.limitPerTour !== SITE_SCOPE.UNLIMITED &&
      usage.scene?.limitPerTour >= scopes.scene?.limitPerTour
    );
  }, [usage, scopes]);
};

export const useSoftDeleteScenes = ({ successFn, errorFn }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tourId, items }) =>
      axiosClient.post(ENDPOINT.SOFT_DELETE, { tourId, items }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.SCENE_LIST);
        successFn && successFn();
        dispatch(refreshTourDetail());
      },
      onError: (error) => {
        errorFn && errorFn(error);
      },
    }
  );
};

export const useGetSourceImgDetail = (srcImgId) => {
  return useQuery([QUERY_KEYS.SOURCE_IMAGES, srcImgId], () => {
    if (!srcImgId) return;
    return axiosClient.get(ENDPOINT.IMAGES + '/' + srcImgId);
  });
};

export const getSceneStatus = async (srcImgId, setStatusData) => {
  try {
    const res = await axiosClient.get(`${ENDPOINT.STATUS}/${srcImgId}`);
    setStatusData(res);
  } catch (error) {
    console.log(error);
  }
};

export const retrySceneProcessing = async (severName, srcImgId, dimension) => {
  try {
    await axiosClient.post(ENDPOINT.RETRY + '/' + severName, {
      imageName: srcImgId,
      dimension: dimension,
    });
  } catch (error) {
    console.log(error);
  }
};

export const useGetGroupOpts = () => {
  const { data: groups } = useGetTourGroupBySearchQuery();

  return useMemo(() => {
    if (!groups) return [];
    return groups.map(({ id, title }) => ({
      value: id,
      label: title,
    }));
  }, [groups]);
};

export const useChangeGroup = ({ successFn, errorFn }) => {
  const query = useQueryClient();
  return useMutation(
    ({ data }) => axiosClient.patch(ENDPOINT.CHANGE_GROUP, data),
    {
      onSuccess: () => {
        successFn && successFn();
        query.invalidateQueries(QUERY_KEYS.SCENE_LIST);
      },
      onError: () => {
        errorFn && errorFn();
      },
    }
  );
};

export const useAddNewScene = () => {
  const query = useQueryClient();
  return useMutation(
    ({ sceneData }) => axiosClient.post(ENDPOINT.SCENE, sceneData),
    {
      onSuccess: () => query.invalidateQueries(QUERY_KEYS.SCENE_LIST),
      onError: (error) => console.log('create Error', error),
    }
  );
};

export const useEditScene = () => {
  return useMutation(({ id, sceneData }) =>
    axiosClient.put(ENDPOINT.SCENE + '/' + id, sceneData)
  );
};

export const useGetScenePerTourGroups = () => {
  const tourId = useSelector(({ tour }) => tour.id);
  const publicTourId = useSelector(({ publicTour }) => publicTour.id);
  const currentTourID = useMemo(
    () => getTourBaseOnPathName(publicTourId, tourId),
    [publicTourId, tourId]
  );
  const [tourGroupsScene, setTourGroupsScene] = useState([]);

  const load = useCallback(async () => {
    try {
      const res = await getScenesPerTourGroups(currentTourID);
      if (res) {
        setTourGroupsScene(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentTourID]);

  useEffect(() => {
    if (!tourGroupsScene.length) {
      load();
    }
  }, [load, tourGroupsScene]);

  return { loadData: load, tourGroupsScene: tourGroupsScene };
};

export const useUpdateGroupPosition = () => {
  const changeGroupPosition = useCallback(async (data) => {
    try {
      const res = await updateGroupScenePosition(data);
      if (res) {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { change: changeGroupPosition };
};

export const useUpdateSceneToOtherGroup = () => {
  const updateScenePosition = useCallback(async (tourId, groupId, sceneId) => {
    try {
      const res = await updateSceneToOtherGroup({
        tourId: tourId,
        groupId: groupId,
        scenes: [sceneId],
      });
      if (res) {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { updateScenePosition: updateScenePosition };
};

export const useUpdateFirstScene = () => {
  const updateDefaultScene = useCallback(async (groupId, sceneId) => {
    try {
      const res = await updateFirstScene(groupId, sceneId);
      if (res) {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { updateDefaultScene: updateDefaultScene };
};

export const useGetAutoPilot = () => {
  const tourId = useSelector(({ tour }) => tour.id);
  const publicTourId = useSelector(({ publicTour }) => publicTour.id);
  const currentTourID = useMemo(
    () => getTourBaseOnPathName(publicTourId, tourId),
    [publicTourId, tourId]
  );

  const [autoPilotScenes, setAutoPilotScenes] = useState();

  const load = useCallback(async () => {
    try {
      const res = await getAutoPilot(currentTourID);
      if (res) {
        setAutoPilotScenes(res.data.data.scenes);
      }
    } catch (error) {
      setAutoPilotScenes({});
      console.log(error);
    }
  }, [currentTourID]);

  useEffect(() => {
    if (!autoPilotScenes) {
      load();
    }
  }, [autoPilotScenes, load]);

  return {
    data: autoPilotScenes,
    loadAutoPilotScenes: load,
  };
};

export const useAddAutoPilot = () => {
  const addAutoPilotScene = useCallback(async (tourId, scenes) => {
    try {
      const res = await addAutoPilot(tourId, scenes);
      if (res) {
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { addAutoPilotScene: addAutoPilotScene };
};
