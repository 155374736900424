import urls from 'consts/urls';
import api from 'helpers/api';

export const getCustomers = () => {
  return api.get(urls.CUSTOMERS);
};

export const getCompanyAdminUsers = (customerId) => {
  return api.get(`${urls.CUSTOMERS}/Users/${customerId}`);
};

export const getCustomerDetail = (id) => {
  return api.get(urls.CUSTOMERS + '/' + id);
};

export const updateCustomer = (id, data) => {
  if (id) {
    return api.put(urls.CUSTOMERS + '/' + id, data);
  }
  return api.post(urls.CUSTOMERS, data);
};

export const getCustomersByUser = (id) => {
  return api.get(urls.USER_CUSTOMERS + '/' + id);
};

export const deleteCustomer = (id) => api.delete(`${urls.CUSTOMERS}/${id}`);

export const getCustomersUsers = (id) => {
  return api.get(`${urls.CUSTOMERS_USERS}/${id}`);
};

export default {
  getCustomers,
  getCustomerDetail,
  updateCustomer,
  getCustomersByUser,
  getCompanyAdminUsers,
  deleteCustomer,
  getCustomersUsers,
};
