export const QUERY_KEY = {
  UPDATE_FILE: 'updateFile',
};

export const FILE_ENDPOINT = {
  UPLOAD_FILE: 'Files/Upload',
  UPLOAD_IMAGE: 'Images/Upload',
};

export const FORM_DATA_TYPE = {
  FILE_TYPE: 'fileType',
  IMAGE_TYPE: 'imageType',
};

export const URL_TYPE = {
  FILE_URL: 'fileUlr',
  IMAGE_URL: 'imageUrl',
};

export const FILE = 'file';
