export const SET = 'SET';
export const INITIATE = 'INITIATE';
export const INITIATED = 'INITIATED';
export const TOGGLE_CUSTOMER_MODAL = 'TOGGLE_CUSTOMER_MODAL';
export const TOGGLE_PUBLIC_TOUR_MODAL = 'TOGGLE_PUBLIC_TOUR_MODAL';
export const TOGGLE_HEADER_MODAL = 'TOGGLE_HEADER_MODAL';
export const TOGGLE_PROJECT_MODAL = 'TOGGLE_PROJECT_MODAL';
export const TOGGLE_TOUR_MODAL = 'TOGGLE_TOUR_MODAL';
export const REFRESH_TOUR_DETAIL = 'REFRESH_TOUR_DETAIL';
export const REFRESH_PROJECT_DETAIL = 'REFRESH_PROJECT_DETAIL';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_PUBLIC_TOUR = 'SET_PUBLIC_TOUR';
export const SET_PUBLIC_TOURS = 'SET_PUBLIC_TOURS';
export const LOAD_PUBLIC_TOURS = 'LOAD_PUBLIC_TOURS';
export const SET_TOUR = 'SET_TOUR';
export const SET_TOURS = 'SET_TOURS';
export const LOAD_TOURS = 'LOAD_TOURS';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const SET_360_DATA = 'SET_360_DATA';
export const SET_360_IMAGES = 'SET_360_IMAGES';
export const SET_LENSFLARE = 'SET_LENSFLARE';
export const SET_USER = 'SET_USER';
export const CHECK_USER_ROLE = 'CHECK_USER_ROLE';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_CUSTOMERS = 'SET_USER_CUSTOMERS';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_USER_NAME = 'SET_USER_NAME';
export const REFRESH_CUSTOMERS = 'REFRESH_CUSTOMERS';
export const SET_TOUR_NAME_MODAL = 'SET_TOUR_NAME_MODAL';
export const SET_SCENE_NAME_MODAL = 'SET_SCENE_NAME_MODAL';
export const SET_ADD_SCENE_MODAL = 'SET_ADD_SCENE_MODAL';
export const SET_IS_SHOW_CLIENT_WELCOME_POPUP =
  'SET_IS_SHOW_CLIENT_WELCOME_POPUP';
export const SET_WEBSITES = 'SET_WEBSITES';
export const SET_MEDIA_ID = 'SET_MEDIA_ID';
export const SET_IS_SHOW_MF_HP = 'SET_IS_SHOW_MF_HP';
export const SET_INLINE_VIDEO = 'SET_INLINE_VIDEO';
export const ROTATE_AXIS = 'ROTATE_AXIS';
export const SET_ORIGINAL_INLINE_ROTATE = 'SET_ORIGINAL_INLINE_ROTATE';
