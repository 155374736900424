import axiosClient from 'api';
import urls from 'consts/urls';
import api from 'helpers/api';

export const getDetail = (sceneId) => {
  return api.get(urls.SCENES + '/' + sceneId);
};

export const update = (id, data) => {
  if (id) {
    return api.put(urls.SCENES + '/' + id, data);
  }
  return api.post(urls.SCENES, data);
};

export const getScenesBySearchQuery = (data) => {
  return api.patch(urls.SCENES, data);
};

export const getScenesByTourId = (tourId) => {
  return getScenesBySearchQuery({ tourId });
};

export const setSceneAsDefault = (id) => {
  return api.post(urls.SCENES + '/' + id + '/default');
};

export const softDelete = (tourId, items = []) => {
  return api.post(`${urls.SCENES}/SoftDelete`, { tourId, items });
};

export const showOnMap = (data) => {
  return api.patch(`${urls.SCENES}/ShowOnMap`, data);
};

export const changeGroup = (data) => {
  return api.patch(`${urls.SCENES}/ChangeGroup`, data);
};

export const saveHotspotStep = async (data) => {
  try {
    const res = await axiosClient.post(`Steps`, data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateHotspotStep = async (data) => {
  try {
    const res = await axiosClient.put(`Steps`, data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getHotspotStep = async (sceneId) => {
  try {
    const res = await axiosClient.patch(`Steps`, { sceneId });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteHotspotStep = async (sceneId, targetSceneId) => {
  try {
    const res = await axiosClient.post(`Steps/delete`, {
      sceneId,
      targetSceneId,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const checkLinkScene = (tourId, sceneList) => {
  return api.patch(`${urls.HOTSPOTS}/CheckLinkScene`, {
    tourId,
    sceneIds: sceneList,
  });
};

export const getScenesPerTourGroups = (tourId) => {
  return api.get(`${urls.GET_SCENES_PER_TOUR_GROUPS}/${tourId}`);
};

export const updateGroupScenePosition = (data) => {
  return api.post(`${urls.UPDATE_SCENE_GROUP_POSITION}`, { data });
};

export const updateSceneToOtherGroup = (data) => {
  return api.patch(`${urls.UPDATE_SCENE_TO_OTHER_GROUP}`, data);
};

export const updateFirstScene = (groupId, sceneId) => {
  return api.put(`${urls.UPDATE_DEFAULT_SCENE}`, {
    id: groupId,
    sceneId: sceneId,
  });
};

export const addAutoPilot = (tourId, scenes) => {
  return api.post(`${urls.AUTO_PILOT}`, { tourId: tourId, scenes: scenes });
};

export const getAutoPilot = (tourId) => {
  return api.get(`${urls.AUTO_PILOT}/${tourId}`);
};

export default {
  getDetail,
  update,
  getScenesBySearchQuery,
  getScenesByTourId,
  setSceneAsDefault,
  softDelete,
  showOnMap,
  changeGroup,
  saveHotspotStep,
  getHotspotStep,
  deleteHotspotStep,
  updateHotspotStep,
  checkLinkScene,
  getScenesPerTourGroups,
  updateSceneToOtherGroup,
  updateFirstScene,
  addAutoPilot,
  getAutoPilot,
};
