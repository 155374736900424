import * as Yup from 'yup';
import { CUSTOMER_STATUS, CUSTOMER_STATUS_CODE } from './constansts';

export const ensureCustomerData = (customerData) => {
  const customer = {};
  Object.keys(customerData).forEach((key) => {
    customer[key] = customerData[key] !== null ? customerData[key] : '';
  });
  return customer;
};

export const ensureErrorMsg = (error) => {
  if (error.length > 0) return error[0].error;
  return error.error;
};

export const isCustomerStatusCodeActive = (customer) =>
  customer.status === CUSTOMER_STATUS_CODE.ACTIVE;
export const isCustomerStatusCodePending = (customer) =>
  customer.status === CUSTOMER_STATUS_CODE.PENDING;
export const isCustomerStatusCodeInactive = (customer) =>
  customer.status === CUSTOMER_STATUS_CODE.INACTIVE;

export const isCustomerSubscriptionStatusActive = (customer) =>
  customer.subscriptionStatus === CUSTOMER_STATUS_CODE.ACTIVE;

export const isAdmin = (userRole) => userRole === 'Admin';

export const isCustomerStatusPending = (item) =>
  item.status === CUSTOMER_STATUS.PENDING;

export const isCustomerStatusActive = (item) =>
  item.status === CUSTOMER_STATUS.ACTIVE;

export const isCustomerStatusInactive = (item) =>
  item.status === CUSTOMER_STATUS.INACTIVE;

export const validateSchema = () =>
  Yup.object().shape({
    companyName: Yup.string()
      .required('error.common.field_required')
      .min(5, 'error.customers.company_name_min_length')
      .max(100, 'error.customers.company_name_max_length'),
    email: Yup.string()
      .required('error.common.field_required')
      .email('error.common.email_invalid'),
    websiteUrl: Yup.string().url('error.common.url').notRequired(),
    facebookUrl: Yup.string().url('error.common.url').notRequired(),
    instagramUrl: Yup.string().url('error.common.url').notRequired(),
    twitterUrl: Yup.string().url('error.common.url').notRequired(),
    snapchatUrl: Yup.string().url('error.common.url').notRequired(),
    youtubeUrl: Yup.string().url('error.common.url').notRequired(),
    pinterestUrl: Yup.string().url('error.common.url').notRequired(),
  });
