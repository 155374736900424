import { all, takeLatest } from 'redux-saga/effects';

import * as ACTION_TYPE from './actionTypes';
import middlewares from './middlewares';

function* handleInit() {
  yield takeLatest(ACTION_TYPE.INITIATE, middlewares.initiate);
}

function* handleCheckUserRole() {
  yield takeLatest(ACTION_TYPE.CHECK_USER_ROLE, middlewares.checkUserRole);
}

function* handleLoadProjects() {
  yield takeLatest(
    ACTION_TYPE.LOAD_PROJECTS,
    middlewares.loadProjectsFromCurrentCustomer
  );
}

function* handleRefreshProjectDetail() {
  yield takeLatest(
    ACTION_TYPE.REFRESH_PROJECT_DETAIL,
    middlewares.refreshProjectDetail
  );
}

function* handleLoadTours() {
  yield takeLatest(
    ACTION_TYPE.LOAD_TOURS,
    middlewares.loadToursFromCurrentCustomer
  );
}

function* handleRefreshTourDetail() {
  yield takeLatest(
    ACTION_TYPE.REFRESH_TOUR_DETAIL,
    middlewares.refreshTourDetail
  );
}

export default function* rootSaga() {
  yield all([
    handleInit(),
    handleCheckUserRole(),
    handleLoadProjects(),
    handleRefreshProjectDetail(),
    handleLoadTours(),
    handleRefreshTourDetail(),
  ]);
}
