export const customersReportData = [
  {
    id: '624bb6155495071290332972',
    updatedAt: '2023-03-14T07:12:41.708Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: '',
    uid: null,
    companyName: 'VIZION DEMO',
    email: 'info@vizion.space',
    companyTagline: null,
    companyDescription: null,
    notes: null,
    friendlyName: 'VIZION-DEMO',
    logoUrl: null,
    brandColor1: null,
    brandColor2: null,
    websiteUrl: null,
    facebookUrl: null,
    instagramUrl: null,
    twitterUrl: null,
    snapchatUrl: null,
    youtubeUrl: null,
    pinterestUrl: null,
    countryCode: null,
    phoneNumber: null,
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    createdAt: '2022-04-05T03:23:01.000Z',
  },
  {
    id: '629723979a771408c4c99c54',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    uid: 'eedad2e7-f0f4-44d7-bb30-b024bde3fd8d',
    companyName: 'Success Software Service',
    email: 'solutions@successsoftware.global',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Success-Software-Service',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: 'https://success-ss.com.vn/',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-06-01T08:30:15.000Z',
  },
  {
    id: '629731cccd3f2015d44f3e1a',
    updatedAt: '2022-06-01T09:35:06.352Z',
    isDeleted: false,
    deletedAt: null,
    uid: 'b34f8389-50a0-4a75-a628-2dc3ac57fd29',
    companyName: 'Rang Rang Coffee',
    email: 'support@rangrangcoffee.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Rang-Rang-Coffee',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-06-01T09:30:52.000Z',
  },
  {
    id: '62aaaaabde5c0a069cb72af0',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    uid: '4c641e89-5485-4b55-9b50-a59354c84503',
    companyName: 'NOVALAND-SANG',
    email: 'daominhsang@novaland.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'NOVALAND-SANG',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-06-16T03:59:39.000Z',
  },
  {
    id: '62b53861242b9c1ac8316684',
    updatedAt: '2022-06-24T04:11:07.138Z',
    isDeleted: false,
    deletedAt: null,
    uid: '059851b3-27a0-4634-9e32-d37d2c7d1312',
    companyName: 'McCallum Sather',
    email: 'mccallumsather@app.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'McCallum-Sather',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-06-24T04:06:57.000Z',
  },
  {
    id: '630de68da8679f1718d6320d',
    updatedAt: '2023-02-24T06:55:43.109Z',
    isDeleted: false,
    deletedAt: null,
    uid: '',
    companyName: 'VIZION DEV - TESTING',
    email: 'info@vizion.space',
    companyTagline: 'FE',
    companyDescription: 'Front-End',
    notes: null,
    friendlyName: 'VIZION-DEV-TESTING',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-08-30T10:29:33.000Z',
  },
  {
    id: '62dfb9684490921e3083960a',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    uid: '19f263fa-3609-42d7-9de1-29898e7ff97a',
    companyName: 'ZENA ARCHITECTURE',
    email: 'phankha@zena.com.vn',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'ZENA-ARCHITECTURE',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: 'https://zena.com.vn/',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-07-26T09:52:40.000Z',
  },
  {
    id: '63492d64413ac104ccb998f1',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    uid: null,
    companyName: 'DUC ARCHITECT',
    email: 'info@vizion.space',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'DUC-ARCHITECT',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 0,
    status: 0,
    userIdDeleted: '',
    createdAt: '2022-10-14T09:35:32.000Z',
  },
  {
    id: '64214f2bbce21f1454a20a88',
    updatedAt: '2023-09-28T00:59:59.410Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: '',
    companyName: 'SPACE 3D VN',
    email: 'nga.ho@space3d.vn',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'SPACE-3D-VN',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '84946660732',
    companySize: '',
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2023-03-27T08:09:15.000Z',
  },
  {
    id: '6425670cc67bb31ac052ef75',
    updatedAt: '2023-10-01T00:59:59.410Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'Brothers Furniture',
    email: 'dk.digital1202@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Brothers Furniture',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84908186026',
    companySize: null,
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2023-03-30T10:40:12.000Z',
  },
  {
    id: '642b805ab96560134864bb69',
    updatedAt: '2023-09-05T01:31:52.406Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: '',
    companyName: 'Tech VR 360',
    email: 'buitindat@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Tech-VR-360',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '84966817839',
    companySize: '',
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2023-04-04T01:41:46.000Z',
  },
  {
    id: '642b93185c5b8617f4732e2a',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'VR 360 PLUS',
    email: 'khanhtoan.ict@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'VR-360-PLUS',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84328461888',
    companySize: null,
    subscriptionStatus: 0,
    status: 0,
    createdAt: '2023-04-04T03:01:44.000Z',
  },
  {
    id: '642bcab54a145e1818cda70f',
    updatedAt: '2023-09-05T01:31:52.407Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'Flying Cam Viet Nam',
    email: 'Khonggianmedia@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Flying-Cam-Viet-Nam',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84901489871',
    companySize: null,
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2023-04-04T06:59:01.000Z',
  },
  {
    id: '642e2e8c2dc3ef19b8c2fabd',
    updatedAt: '2023-10-08T00:00:00.047Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'Leedo Media',
    email: 'lehuyphong86@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Leedo-Media',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84988969854',
    companySize: null,
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2023-04-06T02:29:32.000Z',
  },
  {
    id: '6465a80677b03c1b4cc57f89',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'VIZION-TEST PIPELINE',
    email: 'pipeline@vizion.space',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'VIZION-TEST-PIPELINE',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    createdAt: '2023-05-18T04:22:30.000Z',
  },
  {
    id: '64993b92be81eb06f849699c',
    updatedAt: '2023-07-10T06:19:46.277Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: '',
    companyName: 'QC-Testing',
    email: 'qctest2@yopmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'QC-Testing',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 1,
    status: 0,
    createdAt: '2023-06-26T07:17:38.000Z',
  },
  {
    id: '64d0a1bcb1bfcb172c9c6579',
    updatedAt: '2023-11-08T00:59:59.398Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: '',
    companyName: 'HAHA TRAVEL',
    email: 'info.hahatravel@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'HAHA-TRAVEL',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: 'https://hahatravel.vn/',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: '',
    phoneNumber: '',
    companySize: '',
    subscriptionStatus: 2,
    status: 1,
    createdAt: '2023-08-07T07:48:12.000Z',
  },
  {
    id: '64e2d19f74113e1bd824877d',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'New Customer',
    email: 'thanhtttran.vizion@gmail.com',
    companyTagline: 'New Customer 21082023',
    companyDescription: 'New Customer 21082023',
    notes: null,
    friendlyName: 'New-Customer',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: 'https://vizion.space/home',
    facebookUrl: 'https://www.facebook.com/Vizion/',
    instagramUrl: 'https://www.instagram.com/vizion/',
    twitterUrl: 'https://twitter.com/vizion',
    snapchatUrl: '',
    youtubeUrl: 'https://www.youtube.com/watch?v=9rxqfNFBTS8',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84987654789',
    companySize: null,
    subscriptionStatus: 0,
    status: 0,
    createdAt: '2023-08-21T02:53:19.000Z',
  },
  {
    id: '651e6a6c59dc0c17102ac79b',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'SUCCESS - DX TEAM',
    email: 'hats@successsoftware.global',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'SUCCESS-DX-TEAM',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 1,
    createdAt: '2023-10-05T07:49:00.000Z',
  },
  {
    id: '652e704f2590871ac8722c59',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'NKC Global',
    email: 'contact@nkc.global',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'NKC-Global',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 1,
    createdAt: '2023-10-17T11:30:23.000Z',
  },
  {
    id: '65782e81c2496d19ccf31282',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'UEH University',
    email: 'info@ueh.edu.vn',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'UEH-University',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: 'https://ueh.edu.vn',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 1,
    createdAt: '2023-12-12T09:57:21.000Z',
  },
  {
    id: '66066dd67f5dae0f78eb8b9d',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'VINCE ACCOUNT',
    email: 'info@vizion.space',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'vince-account',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 1,
    createdAt: '2024-03-29T07:29:26.000Z',
  },
  {
    id: '66501699b218101b10607e52',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'Startup Wheel',
    email: 'startupwheelvn@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'startup-wheel',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    createdAt: '2024-05-24T04:24:57.000Z',
  },
  {
    id: '6660049b6206bd1868290c12',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: '2F VISUAL',
    email: 'box@2fvisual.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: '2f-visual',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 1,
    status: 1,
    createdAt: '2024-06-05T06:24:27.000Z',
  },
  {
    id: '6679267a650235058c067b37',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'TRẦN GIA MEDIA',
    email: 'trangiamedia.vn@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'tran-gia-media',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84934177423',
    companySize: null,
    subscriptionStatus: 1,
    status: 0,
    createdAt: '2024-06-24T07:55:38.000Z',
  },
  {
    id: '6689c72007075e08d48c50f6',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'test account',
    email: 'test01@yopmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'test-account',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 0,
    status: 0,
    createdAt: '2024-07-06T22:37:20.000Z',
  },
  {
    id: '6689c76507075e08d48c50fb',
    updatedAt: null,
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'test account 2',
    email: 'test01@yopmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'test-account-2',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '',
    companySize: null,
    subscriptionStatus: 0,
    status: 0,
    createdAt: '2024-07-06T22:38:29.000Z',
  },
  {
    id: '672d95f6249f29e1d0680c60',
    updatedAt: '2023-10-01T00:59:59.410Z',
    isDeleted: false,
    deletedAt: null,
    userIdDeleted: null,
    uid: null,
    companyName: 'Customer User',
    email: 'customer_user@gmail.com',
    companyTagline: '',
    companyDescription: '',
    notes: null,
    friendlyName: 'Customer-User',
    logoUrl: '',
    brandColor1: '',
    brandColor2: '',
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    snapchatUrl: '',
    youtubeUrl: '',
    pinterestUrl: '',
    countryCode: null,
    phoneNumber: '84908186026',
    companySize: null,
    subscriptionStatus: 2,
    status: 0,
    createdAt: '2024-11-08T04:39:18.000Z',
  },
];
