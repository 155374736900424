import React, { useMemo } from 'react';
import { CCardHeader } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { formatDatesToCustomFormat, sumOfReportData } from 'views/charts/utils';

export default function BarLineChart({
  label,
  reportData,
  title,
  currentData,
  isHaveTourReport,
}) {
  const { t } = useTranslation(['report']);

  const userEngagements = useMemo(
    () =>
      !isHaveTourReport
        ? reportData.map((item) => item.user_engagement)
        : reportData[0].scenes?.map((item) => item.user_engagement),
    [isHaveTourReport, reportData]
  );

  const getUserEngagementsByDate = useMemo(
    () => sumOfReportData(userEngagements, label.reportDates),
    [label.reportDates, userEngagements]
  );

  const options = useMemo(() => {
    return {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: formatDatesToCustomFormat(currentData?.map((item) => item.date)),
      yaxis: [
        {
          title: {
            text: t('user_engagement'),
          },
        },
        {
          opposite: true,
          title: {
            text: t('total_tour_view'),
          },
        },
      ],
    };
  }, [currentData, t]);

  const series = useMemo(
    () => [
      {
        name: t('user_engagement'),
        type: 'column',
        data: getUserEngagementsByDate
          ? getUserEngagementsByDate.map((item) => item.eventCount)
          : [], //your data goes here
      },
      {
        name: t('total_tour_view'),
        type: 'line',
        data: currentData ? currentData.map((item) => item.eventCount) : [], //your data goes here
      },
    ],
    [currentData, getUserEngagementsByDate, t]
  );

  return (
    <>
      <>
        <CCardHeader className="w-100">
          <h5 className="text-center">{title}</h5>
        </CCardHeader>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={490}
          className="bar_line_chart"
        />
      </>
    </>
  );
}
