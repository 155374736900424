export const ENDPOINT = {
  USERS: '/Users',
  SUBSCRIPTION_DETAIL: '/Users/SubscriptionDetail',
};

export const QUERY_KEY = {
  GET_USERS: 'getUsers',
  GET_USER: 'getUser',
  GET_USER_CUSTOMER: 'getUserCustomer',
  GET_SUBSCRIPTION: 'getSubscription',
};

export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ALL: 'All',
};
