import { DATE_RANGE } from 'components/DateRangeFilter/constant';
import { format } from 'date-fns';

export const formatDateRange = (data) => {
  if (data.length) {
    return [
      format(data[0], "MM/dd/yyy' 'HH:mm:ssaa"),
      format(data[1], "MM/dd/yyy' 'HH:mm:ssaa"),
    ];
  } else return ['', ''];
};

export const dateRangeValue = (dateRangeData) => {
  if (Object.values(dateRangeData)[0] === DATE_RANGE.CUSTOM_RANGE.BACKEND_ENUM)
    return [dateRangeData.dateRange[0], dateRangeData.dateRange[1]];

  for (const key in DATE_RANGE) {
    if (Object.hasOwnProperty.call(DATE_RANGE, key)) {
      const element = DATE_RANGE[key];
      if (Object.values(dateRangeData)[0] === element.BACKEND_ENUM)
        return element.RANGE;
    }
  }
};

export const formatDate = (data) => {
  return data.slice(0, -1).map((item) => {
    const year = item.title.slice(0, 4);
    const month = item.title.slice(4, 6);
    const date = item.title.slice(6, 8);
    return format(new Date(year, month - 1, date), 'dd-MMM-yyyy');
  });
};

export const overallData = (data) => {
  return [
    data[data.length - 1]?.screenPageViews,
    data[data.length - 1]?.totalUsers,
    data[data.length - 1]?.totalClick,
  ];
};

export const filterDateRange = (range) => {
  const dateRanges = {
    [DATE_RANGE.TODAY.BACKEND_ENUM]: DATE_RANGE.TODAY.RANGE,
    [DATE_RANGE.YESTERDAY.BACKEND_ENUM]: DATE_RANGE.YESTERDAY.RANGE,
    [DATE_RANGE.LAST_7_DAYS.BACKEND_ENUM]: DATE_RANGE.LAST_7_DAYS.RANGE,
    [DATE_RANGE.LAST_30_DAYS.BACKEND_ENUM]: DATE_RANGE.LAST_30_DAYS.RANGE,
    [DATE_RANGE.THIS_WEEK.BACKEND_ENUM]: DATE_RANGE.THIS_WEEK.RANGE,
    [DATE_RANGE.LAST_WEEK.BACKEND_ENUM]: DATE_RANGE.LAST_WEEK.RANGE,
    [DATE_RANGE.THIS_MONTH.BACKEND_ENUM]: DATE_RANGE.THIS_MONTH.RANGE,
    [DATE_RANGE.LAST_MONTH.BACKEND_ENUM]: DATE_RANGE.LAST_MONTH.RANGE,
  };

  return dateRanges[range];
};

export const sumOfReportData = (data, betweenData) => {
  if (!data) return;
  const eventCountsByDate = {};

  data.forEach((item) => {
    if (!eventCountsByDate[item?.date]) {
      eventCountsByDate[item?.date] = {
        date: item?.date
          ? `${item?.date.slice(0, 4)}-${item?.date.slice(
              4,
              6
            )}-${item?.date.slice(6)}`
          : '',
        eventCount: 0,
        totalUsers: parseInt(item?.totalUsers),
        newUsers: parseInt(item?.newUsers),
        bounceRate: parseInt(item?.bounceRate),
      };
    }
    eventCountsByDate[item?.date].eventCount += parseInt(item?.eventCount);
  });
  const simplifiedData = Object.values(eventCountsByDate);
  const newData = betweenData.map((date) => {
    const matchingData = simplifiedData.find((item) => item?.date === date);

    return (
      matchingData || {
        date,
        eventCount: 0,
        totalUsers: 0,
        newUsers: 0,
      }
    );
  });

  return newData;
};

function getHighestDataAllTour(data) {
  let tourView = data;
  let tourDuration = data;
  tourView.sort((a, b) => {
    return (
      parseInt(b.tour_view?.eventCount) - parseInt(a.tour_view?.eventCount)
    );
  });
  tourDuration.sort(
    (a, b) =>
      parseInt(b.tour_timer_15s?.eventCount) -
      parseInt(a.tour_timer_15s?.eventCount)
  );

  const top5TourView = tourView.slice(0, 5);
  const top5TourDuration = tourDuration.slice(0, 5);

  return { top5TourView: top5TourView, top5TourDuration: top5TourDuration };
}

function getHighestDataTour(data) {
  let tourView = data.filter((item) => 'scene_view' in item);
  let tourDuration = data.filter((item) => 'scene_timer_15s' in item);
  tourView.sort((a, b) => {
    return (
      parseInt(b.scene_view.eventCount) - parseInt(a.scene_view.eventCount)
    );
  });
  tourDuration.sort(
    (a, b) =>
      parseInt(b.scene_timer_15s?.eventCount) -
      parseInt(a.scene_timer_15s?.eventCount)
  );

  const top5TourView = tourView.slice(0, 5);
  const top5TourDuration = tourDuration.slice(0, 5);

  return { top5TourView: top5TourView, top5TourDuration: top5TourDuration };
}

export const getTop5HighestData = (data, tourId) => {
  if (data.length === 0) return;

  return tourId ? getHighestDataTour(data) : getHighestDataAllTour(data);
};

export function formatDatesToCustomFormat(dates) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Agu',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return dates.map((date) => {
    const parts = date.split('-');
    const day = parseInt(parts[2], 10);
    const month = months[parseInt(parts[1], 10) - 1];
    return `${day} ${month}`;
  });
}
